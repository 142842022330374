import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BasicListingInfo } from "../../../helpers";
import { RootState } from "../../../redux/store";
import { NavItem } from "./Routes";
import * as MuiIcons from "@mui/icons-material";
import NavLinkButton from "./NavLinkButton";

interface Props {
  link: NavItem;
  current?: BasicListingInfo;
}

const NavLink = ({ link, current }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { listingId = "" } = useParams();
  let selected =
    pathname.indexOf(
      link.url?.replace(":listingId", listingId) ?? "pathnotexist"
    ) !== -1;
  let IconComponent: any;
  if (link.icon) IconComponent = MuiIcons[link.icon as keyof typeof MuiIcons];
  return (
    <ListItem disablePadding>
      <NavLinkButton
        selected={selected}
        disabled={selected}
        onClick={() => {
          if (link.url) {
            if (listingId === "" && current?._id) {
              navigate(link.url.replace(":listingId", current?._id));
            } else {
              navigate(link.url.replace(":listingId", listingId));
            }
          }
        }}
      >
        <ListItemIcon sx={{ minWidth: "32px", mr: 1 }}>
          {<IconComponent fontSize="small" />}
        </ListItemIcon>
        <ListItemText
          primary={link.title}
          primaryTypographyProps={{ fontWeight: "600" }}
        />
      </NavLinkButton>
    </ListItem>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(NavLink);
