import Resizer from "react-image-file-resizer";

export function updateProperty(
  obj: any,
  propertyPath: string,
  newValue: any
): any {
  const properties = propertyPath.split(".");
  let current = obj;

  for (let i = 0; i < properties.length - 1; i++) {
    if (!current[properties[i]]) {
      current[properties[i]] = {};
    }
    current = current[properties[i]];
  }

  current[properties[properties.length - 1]] = newValue;
}

export const resizeFile = (file: any): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      750,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri as string);
      },
      "base64"
    );
  });
