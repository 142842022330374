import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Facility } from "../../../defaults/Metadata";
import { RootState } from "../../../redux/store";
import { Dispatch } from "redux";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";

interface Props {
  services: string[];
  updateSelected(value: string[]): void;
}
const FacilityServices = ({ services, updateSelected }: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Facility Services"
        subtitle="Select all that applies"
      />

      <Autocomplete
        fullWidth
        options={Facility.mosques.services.sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        })}
        isOptionEqualToValue={(option) => services.indexOf(option._id) !== -1}
        value={Facility.mosques.services.filter(
          (s) => services.indexOf(s._id) !== -1
        )}
        getOptionLabel={(option) => option.name}
        disableCloseOnSelect
        multiple
        onChange={(event, value) => updateSelected(value.map((v) => v._id))}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  services: data?.services || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelected: (value: string[]) =>
    dispatch(updateSelected({ key: "services", value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityServices);
