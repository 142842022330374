import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Notification } from "../../../helpers";
import { useEffect, useState } from "react";
import { Add, Close } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import ReactPhoneInput from "react-phone-input-material-ui";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";

interface NotificationProps {
  notification: Notification;
  onChange(type: "type" | "value" | "offline", value: string | boolean): void;
  onDelete(): void;
}

const NotificationRow = ({
  notification,
  onChange,
  onDelete,
}: NotificationProps) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={1}
      divider={<Divider />}
      alignItems={"center"}
      justifyItems={"center"}
    >
      <Select
        sx={{ width: { xs: "100%", sm: 72 }, minWidth: { xs: "100%", sm: 72 } }}
        labelId="type-select-label"
        id="type-select"
        label="Type"
        value={notification.type}
        onChange={(event) => {
          onChange("type", event.target.value as string);
        }}
      >
        <MenuItem value="sms">SMS</MenuItem>
        <MenuItem value="call">Call</MenuItem>
        <MenuItem value="email">Email</MenuItem>
      </Select>
      {notification.type !== "email" && (
        <ReactPhoneInput
          value={notification.value}
          onChange={(value) => onChange("value", value)}
          label=""
          country={"us"}
          countryCodeEditable={false}
          placeholder="1 (222) 333-4444"
          containerStyle={{
            width: "100%",
          }}
          isValid={/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
            notification.value
          )}
          component={TextField}
        />
      )}
      {notification.type === "email" && (
        <TextField
          fullWidth
          placeholder={
            notification.type === "email" ? "Email Address" : "Phone Number"
          }
          value={notification.value}
          onChange={(event) => onChange("value", event.target.value as string)}
          error={!/^\S+@\S+\.\S+$/.test(notification.value)}
        />
      )}
      <Stack direction="row" spacing={1}>
        <Select
          sx={{ minWidth: 125 }}
          labelId="trigger-input-label-id"
          id="demo-simple-select"
          value={notification.offline ? "offline" : "always"}
          placeholder="Trigger"
          onChange={(event) => {
            if (event.target.value === "always") {
              onChange("offline", false);
            } else {
              onChange("offline", true);
            }
          }}
        >
          <MenuItem value={"always"}>Always</MenuItem>
          <MenuItem value={"offline"}>Offline Only</MenuItem>
        </Select>
        {/* <FormControlLabel
          control={
            <Switch
              checked={notification.offline}
              onChange={(event, checked) => onChange("offline", checked)}
            />
          }
          label={
            <Typography variant="body2" color="text.secondary">
              {notification.offline ? "Offline" : "Always"}
            </Typography>
          }
        /> */}
        <IconButton size="small" onClick={onDelete}>
          <Close fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

interface Props {
  open: boolean;
  notifications: Notification[];
  handleClose(notifications?: Notification[]): void;
}

const NotificationsDialog = ({
  open,
  notifications: defaultNotifications,
  handleClose,
}: Props) => {
  const [notifications, setNotifications] = useState<Notification[]>(
    cloneDeep(defaultNotifications)
  );

  useEffect(() => {
    if (open) setNotifications(cloneDeep(defaultNotifications));
  }, [open]);

  const isSubmitDisabled = () => {
    for (let notification of notifications) {
      if (
        notification.type === "email" &&
        /^\S+@\S+\.\S+$/.test(notification.value) === false
      )
        return true;

      if (
        (notification.type === "sms" || notification.type === "call") &&
        !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
          notification.value
        )
      )
        return true;
    }
    return false;
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth>
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle>Order Notification</DialogTitle>
        {/* <DialogActions>
          <IconButton
            onClick={() => {
              setNotifications([
                ...notifications,
                { offline: false, type: "sms", value: "" },
              ]);
            }}
          >
            <Add />
          </IconButton>
        </DialogActions> */}
      </Stack>
      <DialogContent sx={{ pt: 0 }}>
        <Stack spacing={1}>
          <Stack spacing={0.5}>
            <DialogContentText>
              Your tablet will receive automatic notifications. This section
              pertains to additional actions beyond tablet notifications.
              'Offline' indicates notifications triggered solely when the tablet
              is offline and 'Always' will trigger each time an order is
              received.
            </DialogContentText>
          </Stack>
          {notifications.map((notification, index) => (
            <NotificationRow
              key={`notification-row-${index}`}
              notification={notification}
              onChange={(type, value) => {
                const _notifications = [...notifications];
                if (type === "offline") {
                  _notifications[index].offline = value as boolean;
                } else {
                  _notifications[index][type] = value as string;
                }
                setNotifications(_notifications);
              }}
              onDelete={() => {
                const _notifications = notifications.filter(
                  (n, i) => i !== index
                );
                setNotifications(_notifications);
              }}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Stack direction="row">
          <Button
            onClick={() => {
              setNotifications([
                ...notifications,
                { offline: false, type: "sms", value: "" },
              ]);
            }}
          >
            Add Notification
          </Button>
        </Stack>
        <Stack direction="row">
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button
            onClick={() => handleClose(notifications)}
            disabled={isSubmitDisabled()}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationsDialog;
