import { List, Stack } from "@mui/material";
import NavGroup from "./NavGroup";
import NavLink from "./NavLink";
import { NavItem } from "./Routes";
import OrganizationSwitcher from "./ListingSwitcher";

interface Props {
  route: NavItem;
}

const NavItemComponent = ({ route }: Props) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <NavGroup title={route.title} subtitle={route.subtitle} />
      <List sx={{ width: "100%" }} dense>
        {route.children?.map((child) => (
          <NavLink link={child} key={child.id} />
        ))}
      </List>
    </Stack>
  );
};

export default NavItemComponent;
