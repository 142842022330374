import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { OrderStatus, Tracking } from "../../../helpers/Orders";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import OrderPrepTimeDialog from "./OrderPrepTimeDialog";

interface Props {
  trackings?: Tracking[];
  nextTracking: Tracking;
  currentTracking?: Tracking;
  loading: boolean;
  onStatusUpdate(status: OrderStatus): void;
}

const OrderUpdateTracking = ({
  trackings,
  nextTracking,
  loading,
  onStatusUpdate,
}: Props) => {
  const [isPrepTimeDialogOpen, setIsPrepTimeDialogOpen] = useState(false);

  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Update Tracking"
        subtitle="Order Status Management"
      />
      {trackings && trackings[0].status !== 1 && (
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => onStatusUpdate({ statusId: nextTracking?.id })}
        >
          Move to {nextTracking?.text}
        </Button>
      )}
      {trackings && trackings[0].status === 1 && (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (nextTracking)
                onStatusUpdate({ statusId: nextTracking?.id, prep: 15 });
            }}
          >
            15 mins
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (nextTracking)
                onStatusUpdate({ statusId: nextTracking?.id, prep: 20 });
            }}
          >
            20 mins
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (nextTracking)
                onStatusUpdate({ statusId: nextTracking?.id, prep: 30 });
            }}
          >
            30 mins
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => setIsPrepTimeDialogOpen(true)}
          >
            Custom
          </Button>
        </Stack>
      )}
      <OrderPrepTimeDialog
        open={isPrepTimeDialogOpen}
        onClose={(value) => {
          if (value) {
            onStatusUpdate({ statusId: nextTracking.id, prep: +value });
          }
          setIsPrepTimeDialogOpen(false);
        }}
      />
    </UselessDefaultMotion>
  );
};

export default OrderUpdateTracking;
