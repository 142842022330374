import { createSlice } from "@reduxjs/toolkit";
import { Session } from "../../helpers";

interface StatePropsTypes {
  token?: string;
  data?: Session;
}

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    token: undefined,
    data: undefined,
  } as StatePropsTypes,
  reducers: {
    setSessionToken: (state, action) => {
      state.token = action.payload;
    },
    setSessionData: (state, action) => {
      state.data = action.payload;
    },
    removeSession: (state) => {
      state.data = undefined;
      state.token = undefined;
    },
  },
});

export const getSessionToken = (state: StatePropsTypes) => state.token;
export const getSessionData = (state: StatePropsTypes) => state.data;

export const { setSessionData, setSessionToken, removeSession } =
  sessionSlice.actions;

export default sessionSlice.reducer;
