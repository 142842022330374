import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  subtitle: string;
  link: string;
  linkText: string;
}

const AuthHeader = (props: Props) => {
  const navigate = useNavigate();
  const { title, subtitle, link, linkText } = props;

  return (
    <>
      <Box
        component="img"
        src={require("../../assets/images/logo.png")}
        sx={{ height: 48, width: 48, borderRadius: .5 }}
      />
      <Typography sx={{ mt: 3, fontSize: 28, fontWeight: "800" }}>
        {title}
      </Typography>
      <Typography>
        {subtitle}{" "}
        <Box
          component="a"
          onClick={() => {
            navigate(link);
          }}
          sx={{ color: "primary.main", cursor: "pointer" }}
        >
          {linkText}
        </Box>
      </Typography>
    </>
  );
};

export default AuthHeader;
