import { Kinds, Roles } from "../../../helpers";

export type NavType = "group" | "item";

export interface NavItem {
  id: string;
  title: string;
  type: NavType;
  superadmin?: boolean;
  kind?: Kinds[];
  subtitle?: string;
  icon?: string;
  url?: string;
  children?: NavItem[];
}

const Routes: NavItem[] = [
  {
    id: "local.management",
    title: "Listing Management",
    subtitle: "Information about your listing",
    type: "group",
    kind: ["Businesses", "Foods", "Markets", "Mosques", "Schools"],
    children: [
      {
        id: "local.management.information",
        title: "Information",
        icon: "Details",
        type: "item",
        url: "/admin/local/:listingId/info",
      },
    ],
  },
  {
    id: "local.orders",
    title: "Orders",
    subtitle: "Restaurant Management",
    kind: ["Foods"],
    type: "group",
    children: [
      {
        id: "local.orders.orders",
        title: "Orders",
        icon: "Reorder",
        type: "item",
        url: "/admin/local/:listingId/orders",
      },
      {
        id: "local.orders.restaurant",
        title: "Restaurant Manager",
        icon: "RestaurantMenu",
        type: "item",
        url: "/admin/local/:listingId/restaurant-manager",
      },
      {
        id: "local.orders.settings",
        title: "Settings",
        icon: "Settings",
        type: "item",
        url: "/admin/local/:listingId/order-settings",
      },
    ],
  },
  {
    id: "local.settings",
    title: "Settings",
    kind: ["Foods"],
    subtitle: "Administration Settings",
    superadmin: true,
    type: "group",
    children: [
      {
        id: "local.settings.configurations",
        title: "Configurations",
        icon: "Settings",
        type: "item",
        url: "/admin/local/:listingId/configurations",
      },
    ],
  },
  {
    id: "merchant",
    title: "Merchant",
    subtitle: "Merchant Information & Settings",
    kind: ["Foods"],
    type: "group",
    children: [
      {
        id: "merchant.dashboard",
        title: "Dashboard",
        icon: "Dashboard",
        type: "item",
        url: "/admin/local/:listingId/merchant",
      },
    ],
  },
  {
    id: "global",
    title: "Global Management",
    subtitle: "Users & Listings",
    superadmin: true,
    type: "group",
    children: [
      {
        id: "global.listings",
        title: "Listings",
        icon: "ViewModule",
        type: "item",
        url: "/admin/global/listings",
      },
      {
        id: "global.users",
        title: "Users",
        icon: "People",
        type: "item",
        url: "/admin/global/users",
      },
    ],
  },
  {
    id: "global.request",
    title: "Request Management",
    subtitle: "Claims & Reports",
    superadmin: true,
    type: "group",
    children: [
      {
        id: "global.request.claims",
        title: "Claims",
        icon: "CollectionsBookmark",
        type: "item",
        url: "/admin/global/claims",
      },
      {
        id: "global.request.reports",
        title: "Reports",
        icon: "Report",
        type: "item",
        url: "/admin/global/reports",
      },
    ],
  },
];

interface FilterRoutesProps {
  superadmin?: boolean;
  kind: Kinds;
}

export const filteredRoutes = ({
  superadmin = false,
  kind,
}: FilterRoutesProps) => {
  const routes: NavItem[] = [];

  for (let route of Routes) {
    if (route.superadmin && !superadmin) continue;
    if (route.type === "group" && route.kind?.indexOf(kind) !== -1) {
      let internalRoutes: NavItem[] = [];
      for (let internalRoute of route.children!) {
        internalRoutes.push(internalRoute);
      }
      if (internalRoutes.length > 0) {
        routes.push({ ...route, children: internalRoutes });
      }
    } else if (
      route.type === "group" &&
      route.superadmin === superadmin &&
      route.kind?.indexOf(kind) !== -1
    ) {
      routes.push(route);
    } else {
      if (route.kind?.indexOf(kind) !== -1) routes.push(route);
    }
  }

  return routes;
};
