import {
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { debounce } from "@mui/material/utils";
import { ChangeEvent, useState } from "react";
import { Contact, ContactProperties } from "../../../helpers";
import ReactPhoneInput from "react-phone-input-material-ui";
import ListingsApi from "../../../services/api/ListingsApi";
import LocationApi from "../../../services/api/LocationApi";
import { Dispatch } from "redux";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";

interface Props {
  contact?: Contact;
  address?: string;
  updateSelected(key: string, value: string): void;
}

const LocationContact = ({ contact, address, updateSelected }: Props) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateSelected(`contact.${event.target.id}`, event.target.value);
  };
  const onPhoneChange = (value: string) => {
    updateSelected(`contact.phone`, `+${value}`);
  };

  const [state, setState] = useState<{ locations: string[] }>({
    locations: [],
  });

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length === 0) {
      setState({
        ...state,
        locations: [],
      });

      return;
    }
    const locations = await LocationApi.getPossibleLocations(
      event.target.value
    );
    setState({
      ...state,
      locations,
    });
  };

  const handleSearchDelayed = debounce(handleSearch, 500);

  if (!contact) return <></>;

  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Location & Contact"
        subtitle="Enable Seamless Business Discovery and Communication"
      />
      <Autocomplete
        fullWidth
        options={state.locations}
        value={address}
        disableClearable
        freeSolo
        onInputChange={(event, value) =>
          updateSelected(`location.formatted_address`, value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Address"
            fullWidth
            required
            onChange={handleSearchDelayed}
          />
        )}
      />
      <Grid container>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <ReactPhoneInput
            value={contact.phone || ""}
            onChange={(value) => onPhoneChange(value)}
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="website"
            label="Website"
            fullWidth
            onChange={onChange}
            value={contact.website || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="whatsapp"
            label="WhatsApp"
            fullWidth
            onChange={onChange}
            value={contact.whatsapp || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="email"
            label="Email"
            fullWidth
            onChange={onChange}
            value={contact.email || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="twitter"
            label="Twitter"
            fullWidth
            onChange={onChange}
            value={contact.twitter || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="facebook"
            label="Facebook"
            fullWidth
            onChange={onChange}
            value={contact.facebook || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="instagram"
            label="Instagram"
            fullWidth
            onChange={onChange}
            value={contact.instagram || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} pr={1} pb={1}>
          <TextField
            id="youtube"
            label="YouTube"
            fullWidth
            onChange={onChange}
            value={contact.youtube || ""}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  contact: data?.contact,
  address: data?.location.formatted_address,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelected: (key: string, value: string) =>
    dispatch(updateSelected({ key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationContact);
