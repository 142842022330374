import { Divider, List, Stack, TablePagination } from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import { useEffect, useState } from "react";
import { BasicListingInfo, ClaimsResponse } from "../../../helpers";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import GlobalApi from "../../../services/api/GlobalApi";
import UselessEmptyHolder from "../../../components/useless/UselessEmptyHolder";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import ClaimsListItem from "../../../components/admin/global/ClaimsListItem";
import { enqueueSnackbar } from "notistack";
import { cloneDeep } from "lodash";

interface Props {
  current?: BasicListingInfo;
}

const GlobalClaimsPage = ({ current }: Props) => {
  const [sp, setSP] = useSearchParams();

  const [page, setPage] = useState(sp.get("page") ? Number(sp.get("page")) : 0);
  const [limit, setLimit] = useState(
    sp.get("limit") ? Number(sp.get("limit")) : 50
  );
  const [claims, setClaims] = useState<ClaimsResponse>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSP(
      {
        page: newPage.toString(),
        limit: limit.toString(),
      },
      { replace: true }
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
    setSP(
      {
        page: "0",
        limit: event.target.value,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    async function fetch() {
      if (!current) return;
      const _claims = await GlobalApi.getClaims({
        limit,
        page: page + 1,
      });
      setClaims(_claims);
    }

    fetch();
  }, [limit, page]);

  return (
    <UselessPageCarded
      header={{
        title: "Claims Management",
        subtitle:
          "All transactions associated with this merchant Stripe account",
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <List disablePadding>
          {claims?.data.map((claim, index) => (
            <ClaimsListItem
              claim={claim}
              key={claim._id}
              onClaimChange={async (value) => {
                try {
                  await GlobalApi.changeClaimStatus(claim._id, value);
                  const c = cloneDeep(claims);

                  c.data[index].status = value;
                  setClaims(c);
                  enqueueSnackbar({
                    message: `Claim by ${claim.user.name} for ${claim.listing.name} has been ${value}`,
                    variant: "success",
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          ))}
        </List>
        {claims?.data.length === 0 && (
          <UselessEmptyHolder
            parentContainerStyles={{ p: 4 }}
            icon="List"
            text="No claims found"
            description="As of now, no one has taken ownership of any listings."
          />
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={claims?.metadata.count || 0}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(GlobalClaimsPage);
