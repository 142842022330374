import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import StripeLogo from "../../../assets/images/stripe.png";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import NoMerchantLineItem from "./NoMerchantLineItem";
import { Environment } from "../../../configs";
import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import { BasicListingInfo, Session } from "../../../helpers";
import MerchantApi from "../../../services/api/MerchantApi";

interface Props {
  session?: Session;
  current?: BasicListingInfo;
}

const NoMerchant = ({ session, current }: Props) => {
  return (
    <UselessDefaultMotion type="item">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <Box
            component={"img"}
            src={StripeLogo}
            sx={{ width: 150, objectFit: "cover" }}
          />
          <Stack spacing={2}>
            <NoMerchantLineItem
              text="Merchant sign up"
              caption="Grow your business with your Neighborhood Muslim Page"
            />
            <NoMerchantLineItem
              text="Partner with us"
              caption="Step into the Modern Age for Your Business. We Guarantee Your Listing Receives the Recognition It Deserves"
            />
            <NoMerchantLineItem
              text="Payments redefined"
              caption="We utilize Stripe to ensure timely payments and to safeguard the confidentiality of your personal banking information"
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Card>
            <CardHeader
              title="Sign up today"
              titleTypographyProps={{
                sx: {
                  fontWeight: "800",
                  fontSize: 20,
                },
              }}
              subheader="Enroll via Stripe to receive your disbursements and authorize us to execute transactions on your behalf"
              subheaderTypographyProps={{
                sx: {
                  fontWeight: "500",
                  fontSize: 14,
                },
                color: "text.secondary",
              }}
            />
            <CardContent>
              <Stack justifyContent="center" alignItems="center" spacing={3}>
                <Button
                  sx={{
                    backgroundColor: "#635bff",
                    color: "#fff",
                    ":hover": { backgroundColor: "#453FB2" },
                  }}
                  variant="contained"
                  onClick={async () => {
                    if (!session || !current) return;

                    const init = await MerchantApi.initializeMerchantSignup(
                      current._id,
                      session._id
                    );

                    window.open(
                      `${Environment.stripe.connect.url}?client_id=${Environment.stripe.connect.clientId}&stripe_user[email]=${session.email}&state=${init.state}&redirect_uri=${Environment.stripe.connect.redirectUri}`,
                      "_self"
                    );
                  }}
                >
                  Connect With Stripe
                </Button>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  textAlign="center"
                  fontStyle="italic"
                >
                  Upon finalizing this process, you will be brought back to this
                  page.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </UselessDefaultMotion>
  );
};

const mapStateToProps = ({
  session: { data },
  listings: { current },
}: RootState) => ({
  session: data,
  current,
});

export default connect(mapStateToProps)(NoMerchant);
