import { Environment } from "../../configs";
import { HourPeriods, Hours, Period } from "../../helpers";
import { OrderResponse, OrderStatus, SingleOrder } from "../../helpers/Orders";
import {
  DefaultUpdatePayload,
  DefaultUpdateTypes,
  Notification,
  OrderSettings,
} from "../../helpers/Settings";
import HttpClient from "../HttpService";

interface GetOrders {
  listingId: string;
  page: number;
  limit: number;
}

class OrderApi extends HttpClient {
  constructor() {
    super();
    this.axios.defaults.baseURL = Environment.merchants.baseUrl;
  }

  getOrdersData({ listingId, page, limit }: GetOrders): Promise<OrderResponse> {
    const params: any = {
      page,
      limit,
    };

    return this.axios.get(
      `/v1/manage/listings/${listingId}/restaurant/orders`,
      {
        params,
      }
    );
  }

  getOrdersById(listingId: string, orderId: string): Promise<SingleOrder> {
    return this.axios.get(
      `/v1/manage/listings/${listingId}/restaurant/orders/${orderId}`
    );
  }

  updateOrderStatus(
    listingId: string,
    orderId: string,
    payload: OrderStatus
  ): Promise<SingleOrder> {
    return this.axios.patch(
      `/v2/manage/listings/${listingId}/restaurant/orders/${orderId}/status`,
      payload
    );
  }

  cancelOrder(listingId: string, orderId: string): Promise<SingleOrder> {
    return this.axios.post(
      `/v2/manage/listings/${listingId}/restaurant/orders/${orderId}/cancel`
    );
  }
}

export default new OrderApi();
