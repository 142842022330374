import { styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { DRAWER_WIDTH } from "../../../configs";

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  height: "100%",
  m: 0,
  flexShrink: 0,
  justifyContent: "center",
  display: "flex",
  "& .MuiDrawer-paper": {
    width: DRAWER_WIDTH,
    boxSizing: "border-box",
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
}));

export default Drawer;
