import { Action, Dispatch } from "redux";
import AuthHeader from "../../components/auth/AuthHeader";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Session } from "../../helpers";
import { ChangeEvent, useState } from "react";
import { setSessionData } from "../../redux/reducers/SessionReducer";
import { connect } from "react-redux";
import AuthApi from "../../services/api/AuthApi";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

interface Props {
  setSession: (session: Session) => Action;
}

const RegisterPage = ({ setSession }: Props) => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
  });

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    try {
      const data = await AuthApi.register(form);
      setSession(data);
      navigate("/admin");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Unable to create an account at this time.", {
        variant: "error",
      });
    }
  };

  return (
    <Stack
      sx={{
        height: "100%",
        ml: "auto",
        justifyContent: "center",
        width: {
          xs: "100%",
          lg: "60%",
        },
      }}
    >
      <AuthHeader
        title="Sign Up"
        subtitle="Already have an account?"
        link="/auth/login"
        linkText="Sign in"
      />
      <Stack direction="column" spacing={2} mt={2}>
        <TextField
          fullWidth
          name="name"
          label="Name"
          variant="outlined"
          onChange={onChange}
          value={form.name}
        />
        <TextField
          fullWidth
          name="email"
          label="Email"
          variant="outlined"
          value={form.email}
          onChange={onChange}
          sx={{ mt: 3, width: "100%" }}
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          variant="outlined"
          type="password"
          onChange={onChange}
          value={form.password}
          autoComplete="new-password"
        />
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label={
            <Typography variant="body2" color="textSecondary">
              I agree to the{" "}
              <Link
                href="/terms"
                target="_blank"
                color="primary"
                underline="hover"
              >
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link
                href="/privacy"
                target="_blank"
                color="primary"
                underline="hover"
              >
                privacy policy
              </Link>
            </Typography>
          }
        />
        <Button
          variant="contained"
          size="large"
          color="secondary"
          fullWidth
          onClick={onSubmit}
          disabled={
            !form.name ||
            !form.email ||
            !form.email.match(
              /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
            ) ||
            !form.password ||
            form.password.length < 8
          }
          sx={{ borderRadius: 4 }}
        >
          Sign Up
        </Button>
      </Stack>
    </Stack>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSession: (session: Session) => dispatch(setSessionData(session)),
});

export default connect(null, mapDispatchToProps)(RegisterPage);
