import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Period } from "../../../helpers";
interface Props {
  possibleHours: string[];
  period: Period;
  onChange(period: Period): void;
  onRemove(): void;
}

const PeriodRow = ({ possibleHours, period, onChange, onRemove }: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        flex={1}
        alignItems="center"
      >
        <Autocomplete
          id="open"
          fullWidth
          options={possibleHours}
          value={period.open.time}
          sx={{ width: { xs: "100%", sm: 148 } }}
          freeSolo
          disableClearable
          onChange={(event, value) => {
            if (value === "24 Hours") {
              period.open.time = "24 Hours";
              period.close.time = "";
            } else {
              period.open.time = value || "";
            }
            onChange(period);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        {period.open.time !== "24 Hours" && (
          <Typography sx={{ ml: 1, mr: 1 }}>-</Typography>
        )}
        {period.open.time !== "24 Hours" && (
          <Autocomplete
            id="close"
            fullWidth
            options={possibleHours}
            value={period.close.time}
            sx={{ width: { xs: "100%", sm: 148 } }}
            disableClearable
            freeSolo
            onInputChange={(event, value) => {
              if (value === "24 Hours") {
                period.open.time = "24 Hours";
                period.close.time = "";
              } else {
                period.close.time = value || "";
              }
              onChange(period);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </Stack>
      <IconButton size="small" onClick={() => onRemove()}>
        <Close fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default PeriodRow;
