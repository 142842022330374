import { Box, Stack, Typography } from "@mui/material";

const PrivacyPage = () => {
  return (
    <Stack spacing={2} p={3}>
      <Typography variant="h3">Welcome to our Privacy Policy</Typography>
      <Typography variant="h5">
        Your privacy is critically important to us.
      </Typography>
      Neighborhood Muslim LLC is located at:
      <Box component="br" />
      <Box component="address">
        Neighborhood Muslim LLC
        <Box component="br" />
        <Box component="br" />
        4079007313{" "}
      </Box>
      <Typography>
        It is Neighborhood Muslim LLC's policy to respect your privacy regarding
        any information we may collect while operating our website. This Privacy
        Policy applies to{" "}
        <a href="https://www.neighborhoodmuslim.com">
          https://www.neighborhoodmuslim.com
        </a>{" "}
        (hereinafter, "us", "we", or "https://www.neighborhoodmuslim.com"). We
        respect your privacy and are committed to protecting personally
        identifiable information you may provide us through the Website. We have
        adopted this privacy policy ("Privacy Policy") to explain what
        information may be collected on our Website, how we use this
        information, and under what circumstances we may disclose the
        information to third parties. This Privacy Policy applies only to
        information we collect through the Website and does not apply to our
        collection of information from other sources.
      </Typography>
      <Typography>
        This Privacy Policy, together with the Terms and conditions posted on
        our Website, set forth the general rules and policies governing your use
        of our Website. Depending on your activities when visiting our Website,
        you may be required to agree to additional terms and conditions.
      </Typography>
      <Typography variant="h6">Website Visitors</Typography>
      <Typography>
        Like most website operators, Neighborhood Muslim LLC collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. Neighborhood Muslim LLC's purpose in collecting non-personally
        identifying information is to better understand how Neighborhood Muslim
        LLC's visitors use its website. From time to time, Neighborhood Muslim
        LLC may release non-personally-identifying information in the aggregate,
        e.g., by publishing a report on trends in the usage of its website.
      </Typography>
      <Typography>
        Neighborhood Muslim LLC also collects potentially personally-identifying
        information like Internet Protocol (IP) addresses for logged in users
        and for users leaving comments on https://www.neighborhoodmuslim.com
        blog posts. Neighborhood Muslim LLC only discloses logged in user and
        commenter IP addresses under the same circumstances that it uses and
        discloses personally-identifying information as described below.
      </Typography>
      <Typography variant="h6">
        Gathering of Personally-Identifying Information
      </Typography>
      <Typography>
        Certain visitors to Neighborhood Muslim LLC's websites choose to
        interact with Neighborhood Muslim LLC in ways that require Neighborhood
        Muslim LLC to gather personally-identifying information. The amount and
        type of information that Neighborhood Muslim LLC gathers depends on the
        nature of the interaction. For example, we ask visitors who sign up for
        a blog at https://www.neighborhoodmuslim.com to provide a username and
        email address.
      </Typography>
      <Typography variant="h6">Security</Typography>
      <Typography>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </Typography>
      <Typography variant="h6">Advertisements</Typography>
      <Typography>
        Ads appearing on our website may be delivered to users by advertising
        partners, who may set cookies. These cookies allow the ad server to
        recognize your computer each time they send you an online advertisement
        to compile information about you or others who use your computer. This
        information allows ad networks to, among other things, deliver targeted
        advertisements that they believe will be of most interest to you. This
        Privacy Policy covers the use of cookies by Neighborhood Muslim LLC and
        does not cover the use of cookies by any advertisers.
      </Typography>
      <Typography variant="h6">Links To External Sites</Typography>
      <Typography>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        and terms and conditions of every site you visit.
      </Typography>
      <Typography>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites, products or
        services.
      </Typography>
      <Typography variant="h6">
        Https://www.neighborhoodmuslim.com uses Google AdWords for remarketing
      </Typography>
      <Typography>
        Https://www.neighborhoodmuslim.com uses the remarketing services to
        advertise on third party websites (including Google) to previous
        visitors to our site. It could mean that we advertise to previous
        visitors who haven't completed a task on our site, for example using the
        contact form to make an enquiry. This could be in the form of an
        advertisement on the Google search results page, or a site in the Google
        Display Network. Third-party vendors, including Google, use cookies to
        serve ads based on someone's past visits. Of course, any data collected
        will be used in accordance with our own privacy policy and Google's
        privacy policy.
      </Typography>
      <Typography>
        You can set preferences for how Google advertises to you using the
        Google Ad Preferences page, and if you want to you can opt out of
        interest-based advertising entirely by cookie settings or permanently
        using a browser plugin.
      </Typography>
      <Typography variant="h6">
        Protection of Certain Personally-Identifying Information
      </Typography>
      <Typography>
        Neighborhood Muslim LLC discloses potentially personally-identifying and
        personally-identifying information only to those of its employees,
        contractors and affiliated organizations that (i) need to know that
        information in order to process it on Neighborhood Muslim LLC's behalf
        or to provide services available at Neighborhood Muslim LLC's website,
        and (ii) that have agreed not to disclose it to others. Some of those
        employees, contractors and affiliated organizations may be located
        outside of your home country; by using Neighborhood Muslim LLC's
        website, you consent to the transfer of such information to them.
        Neighborhood Muslim LLC will not rent or sell potentially
        personally-identifying and personally-identifying information to anyone.
        Other than to its employees, contractors and affiliated organizations,
        as described above, Neighborhood Muslim LLC discloses potentially
        personally-identifying and personally-identifying information only in
        response to a subpoena, court order or other governmental request, or
        when Neighborhood Muslim LLC believes in good faith that disclosure is
        reasonably necessary to protect the property or rights of Neighborhood
        Muslim LLC, third parties or the public at large.
      </Typography>
      <Typography>
        If you are a registered user of https://www.neighborhoodmuslim.com and
        have supplied your email address, Neighborhood Muslim LLC may
        occasionally send you an email to tell you about new features, solicit
        your feedback, or just keep you up to date with what's going on with
        Neighborhood Muslim LLC and our products. We primarily use our blog to
        communicate this type of information, so we expect to keep this type of
        email to a minimum. If you send us a request (for example via a support
        email or via one of our feedback mechanisms), we reserve the right to
        publish it in order to help us clarify or respond to your request or to
        help us support other users. Neighborhood Muslim LLC takes all measures
        reasonably necessary to protect against the unauthorized access, use,
        alteration or destruction of potentially personally-identifying and
        personally-identifying information.
      </Typography>
      <Typography variant="h6">Aggregated Statistics</Typography>
      <Typography>
        Neighborhood Muslim LLC may collect statistics about the behavior of
        visitors to its website. Neighborhood Muslim LLC may display this
        information publicly or provide it to others. However, Neighborhood
        Muslim LLC does not disclose your personally-identifying information.
      </Typography>
      <Typography variant="h6">Affiliate Disclosure</Typography>
      <Typography>
        This site uses affiliate links and does earn a commission from certain
        links. This does not affect your purchases or the price you may pay.
      </Typography>
      <Typography variant="h6">Cookies</Typography>
      <Typography>
        To enrich and perfect your online experience, Neighborhood Muslim LLC
        uses "Cookies", similar technologies and services provided by others to
        display personalized content, appropriate advertising and store your
        preferences on your computer.
      </Typography>
      <Typography>
        A cookie is a string of information that a website stores on a visitor's
        computer, and that the visitor's browser provides to the website each
        time the visitor returns. Neighborhood Muslim LLC uses cookies to help
        Neighborhood Muslim LLC identify and track visitors, their usage of
        https://www.neighborhoodmuslim.com, and their website access
        preferences. Neighborhood Muslim LLC visitors who do not wish to have
        cookies placed on their computers should set their browsers to refuse
        cookies before using Neighborhood Muslim LLC's websites, with the
        drawback that certain features of Neighborhood Muslim LLC's websites may
        not function properly without the aid of cookies.
      </Typography>
      <Typography>
        By continuing to navigate our website without changing your cookie
        settings, you hereby acknowledge and agree to Neighborhood Muslim LLC's
        use of cookies.
      </Typography>
      <Typography variant="h6">E-commerce</Typography>
      <Typography>
        Those who engage in transactions with Neighborhood Muslim LLC – by
        purchasing Neighborhood Muslim LLC's services or products, are asked to
        provide additional information, including as necessary the personal and
        financial information required to process those transactions. In each
        case, Neighborhood Muslim LLC collects such information only insofar as
        is necessary or appropriate to fulfill the purpose of the visitor's
        interaction with Neighborhood Muslim LLC. Neighborhood Muslim LLC does
        not disclose personally-identifying information other than as described
        below. And visitors can always refuse to supply personally-identifying
        information, with the caveat that it may prevent them from engaging in
        certain website-related activities.
      </Typography>
      <Typography variant="h6">Business Transfers</Typography>
      <Typography>
        If Neighborhood Muslim LLC, or substantially all of its assets, were
        acquired, or in the unlikely event that Neighborhood Muslim LLC goes out
        of business or enters bankruptcy, user information would be one of the
        assets that is transferred or acquired by a third party. You acknowledge
        that such transfers may occur, and that any acquirer of Neighborhood
        Muslim LLC may continue to use your personal information as set forth in
        this policy.
      </Typography>
      <Typography variant="h6">Privacy Policy Changes</Typography>
      <Typography>
        Although most changes are likely to be minor, Neighborhood Muslim LLC
        may change its Privacy Policy from time to time, and in Neighborhood
        Muslim LLC's sole discretion. Neighborhood Muslim LLC encourages
        visitors to frequently check this page for any changes to its Privacy
        Policy. Your continued use of this site after any change in this Privacy
        Policy will constitute your acceptance of such change.
      </Typography>
      <Typography variant="h6"></Typography>
      <Typography></Typography>
      <Typography variant="h6">Credit & Contact Information</Typography>
      <Typography>
        This privacy policy was created at{" "}
        <a
          href="https://termsandconditionstemplate.com/privacy-policy-generator/"
          title="Privacy policy template generator"
          target="_blank"
        >
          termsandconditionstemplate.com
        </a>
        . If you have any questions about this Privacy Policy, please contact us
        via <a href="mailto:admin@neighborhoodmuslim.com">email</a> or{" "}
        <a href="tel:4079007313">phone</a>.
      </Typography>
    </Stack>
  );
};

export default PrivacyPage;
