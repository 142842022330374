import { Divider, Stack } from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import MerchantSettings from "../../../components/admin/configurations/MerchantSettings";
import MerchantFees from "../../../components/admin/configurations/MerchantFees";
import { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { BasicListingInfo } from "../../../helpers";
import MerchantApi from "../../../services/api/MerchantApi";
import { connect } from "react-redux";
import { ConfigurationTypes, Configurations, Fee } from "../../../helpers";
import { enqueueSnackbar } from "notistack";

interface Props {
  current?: BasicListingInfo;
}

const ConfigurationsPage = ({ current }: Props) => {
  const [configurations, setConfigurations] = useState<Configurations>();

  useEffect(() => {
    async function fetch() {
      if (!current) return;

      const configurations = await MerchantApi.getOrderConfigurations(
        current._id
      );

      setConfigurations(configurations);
    }

    fetch();
  }, []);

  const onChangeFeeCovered = async (covered: boolean) => {
    if (!current || !configurations) return;

    setConfigurations({ ...configurations, ccfeescovered: covered });
    try {
      await MerchantApi.updateConfigurations(current._id, "ccfeescovered", {
        covered,
      });
      enqueueSnackbar(
        `Starting now, the ${
          covered ? "platform" : "business"
        } will cover the credit card associated fees.`,
        {
          variant: "success",
        }
      );
    } catch (error) {
      setConfigurations({ ...configurations, ccfeescovered: !covered });
      enqueueSnackbar(
        "Unable to update the Fees Covered at this time. Try again later.",
        {
          variant: "error",
        }
      );
    }
  };

  const onUpdateFees = async (fees: Fee[]) => {
    if (!current || !configurations) return;
    try {
      await MerchantApi.updateConfigurations(current._id, "fees", {
        fees,
      });
      enqueueSnackbar("Fees have been updated.", {
        variant: "success",
      });
      setConfigurations({ ...configurations, fees });
    } catch (error) {
      enqueueSnackbar(
        "Unable to update the Fees at this time. Try again later.",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <UselessPageCarded
      header={{
        title: "Orders Configurations",
        subtitle: "Online Ordering Admin Configuration Settings",
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        {configurations && (
          <MerchantSettings
            onChangeFeeCovered={onChangeFeeCovered}
            ccfeescovered={configurations.ccfeescovered}
          />
        )}
        {configurations && (
          <MerchantFees
            fees={configurations.fees}
            onUpdate={(fees) => onUpdateFees(fees)}
          />
        )}
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(ConfigurationsPage);
