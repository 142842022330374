import { ChevronLeft } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BasicInformation from "../../../../../components/admin/restaurants/BasicInformation";
import UselessPageCarded from "../../../../../components/useless/UselessPageCarded";
import {
  BasicListingInfo,
  SingleModifier,
  SingleModifierOption,
} from "../../../../../helpers";
import { RootState } from "../../../../../redux/store";
import RestaurantApi from "../../../../../services/api/RestaurantApi";
import ModifierRules from "../../../../../components/admin/restaurants/ModifierRules";
import ModifierOptions from "../../../../../components/admin/restaurants/ModifierOptions";

interface StateProps {
  name: string;
  description: string;
  options: SingleModifierOption[];
  single: boolean;
  min: number;
  max: number;
  minSelected: boolean;
  maxSelected: boolean;
}

interface Props {
  current?: BasicListingInfo;
}

const ManageModifierPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const { modifierId } = useParams();

  const [state, setState] = useState<StateProps>({
    name: "",
    description: "",
    options: [],
    single: false,
    min: NaN,
    max: NaN,
    minSelected: false,
    maxSelected: false,
  });

  useEffect(() => {
    if (!current || !modifierId) return;
    const fetch = async () => {
      const modifier = (await RestaurantApi.getDataById(
        current._id,
        "modifiers",
        modifierId
      )) as SingleModifier;

      setState({
        name: modifier.name,
        description: modifier.description,
        options: modifier.options.map((o) => ({
          _id: o._id,
          name: o.name,
          price: o.price / 100,
        })),
        single: modifier.single,
        min: modifier.min ?? NaN,
        max: modifier.max ?? NaN,
        minSelected: modifier.min >= 0 && !modifier.single,
        maxSelected: modifier.max >= 0 && !modifier.single,
      });
    };
    fetch();
  }, []);

  const isSaveDisabled = (): boolean => {
    if (state.name.trim().length === 0) return true;
    if (state.options.length === 0) return true;

    if (
      state.options.findIndex(
        (o) => o.name.trim().length === 0 || isNaN(o.price)
      ) != -1
    )
      return true;
    if (!state.single) {
      if (state.minSelected) {
        if (state.min < 0) return true;
        if (isNaN(state.min)) return true;
        if (state.min.toString().trim().length === 0) return true;
        if (state.min > state.options.length) return true;
      }

      if (state.maxSelected) {
        if (state.max < 0) return true;
        if (isNaN(state.max)) return true;
        if (state.max.toString().trim().length === 0) return true;
        if (state.max > state.options.length) return true;
      }

      if (state.minSelected && state.maxSelected) {
        if (state.max < state.min || state.min > state.max) return true;
      }
    }

    return false;
  };

  const onSave = async () => {
    try {
      if (!current) return;

      const payload: any = {
        name: state.name,
        description: state.description,
        options: state.options.map((o) => ({
          name: o.name,
          price: o.price * 100,
        })),
        single: state.single,
      };

      if (!state.single) {
        if (state.minSelected) payload.min = state.min;
        if (state.maxSelected) payload.max = state.max;
      }

      if (modifierId) {
        await RestaurantApi.updateDataById(
          current._id,
          "modifiers",
          modifierId,
          payload
        );
      } else {
        const modifier = await RestaurantApi.createDataByType(
          current._id,
          "modifiers",
          payload
        );
        navigate(modifier._id, { replace: true });
      }
      enqueueSnackbar("Modifier has been updated", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        "Unable to save the modifier at this time. Try again later",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <UselessPageCarded
      header={{
        title: modifierId ? "Manage Modifier" : "Create Modifier",
        subtitle: "Compose your restaurant's modifier",
        left: (
          <Stack justifyContent="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
          </Stack>
        ),
        right: (
          <Stack justifyContent="center">
            <Button
              variant="contained"
              onClick={() => onSave()}
              disabled={isSaveDisabled()}
            >
              Save
            </Button>
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <BasicInformation
          name={state.name}
          description={state.description}
          onChange={(event) =>
            setState({ ...state, [event.target.id]: event.target.value })
          }
        />
        <ModifierOptions
          options={state.options}
          onSorted={(_options) => {
            setState({ ...state, options: _options });
          }}
          onAddOption={() =>
            setState({
              ...state,
              options: [
                ...state.options,
                { _id: new Date().getTime().toString(), name: "", price: NaN },
              ],
            })
          }
          onOptionValueChange={(index, property, value) => {
            const options = [...state.options];
            if (property === "name") {
              options[index].name = value;
            } else if (property === "price") {
              options[index].price = parseFloat(value);
            }
            setState({ ...state, options });
          }}
          onDelete={(option) =>
            setState({
              ...state,
              options: state.options.filter((o) => o._id !== option._id),
            })
          }
        />
        <ModifierRules
          single={state.single}
          max={state.max}
          min={state.min}
          minSelected={state.minSelected}
          maxSelected={state.maxSelected}
          optionsLength={state.options.length}
          onChange={(type, value) => {
            setState({ ...state, [type]: value });
          }}
        />
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(ManageModifierPage);
