import {
  ClaimsResponse,
  Login,
  ReportResponse,
  SessionData,
} from "../../helpers";
import { Environment } from "../../configs";
import HttpClient from "../HttpService";

interface SearchInterface {
  page: number;
  limit: number;
}

class GlobalApi extends HttpClient {
  getClaims({ page, limit }: SearchInterface): Promise<ClaimsResponse> {
    const params: any = {
      page,
      limit,
    };

    return this.axios.get("/v1/admin/listings/claims", {
      params,
    });
  }

  getReports({ page, limit }: SearchInterface): Promise<ReportResponse> {
    const params: any = {
      page,
      limit,
    };

    return this.axios.get("/v1/admin/listings/reports", {
      params,
    });
  }

  changeClaimStatus(claimId: string, status: "approved" | "revoked") {
    return this.axios.patch(`/v1/admin/listings/claims/${claimId}`, { status });
  }
}

export default new GlobalApi();
