import { Stack, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import PercentageFormatTextField from "../core/PercentageFormatTextField";
import PriceFormatTextField from "../core/PriceFormatTextField";

interface Props {
  price: number;
  tax: number;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const PricingComponent = ({ price, tax, onChange }: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Pricing"
        subtitle="Accurate Pricing and Tax Information"
      />
      <UselessDefaultMotion type="item">
        <Stack spacing={2}>
          <TextField
            label="Price"
            value={price}
            onChange={onChange}
            id="value"
            InputProps={{
              inputComponent: PriceFormatTextField as any,
            }}
            variant="standard"
          />
          <TextField
            label="Tax"
            value={tax}
            onChange={onChange}
            id="tax"
            InputProps={{
              inputComponent: PercentageFormatTextField as any,
            }}
            variant="standard"
          />
        </Stack>
      </UselessDefaultMotion>
    </Stack>
  );
};

export default PricingComponent;
