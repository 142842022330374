import { Box, Button, Stack, Typography } from "@mui/material";
import Error404Lottie from "../../../assets/lottie/404.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { BasicListingInfo } from "../../../helpers";

interface Props {
  current?: BasicListingInfo;
}

const Error404Page = ({ current }: Props) => {
  const navigate = useNavigate();
  return (
    <Stack
      alignItems="center"
      direction="column"
      spacing={2}
      sx={{
        p: 2,
        height: {
          xs: "calc(100vh - 64px)",
        },
      }}
    >
      <Box
        sx={{
          height: {
            xs: "400px",
            sm: "500px",
          },
        }}
      >
        <Lottie
          animationData={Error404Lottie}
          loop={true}
          autoplay={true}
          style={{ objectFit: "contain", height: "100%" }}
        />
      </Box>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          textAlign="center"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
          })}
        >
          The page you requested could not be found.
        </Typography>
      </Stack>
      <Button
        variant="outlined"
        size="large"
        onClick={() => {
          if (!current) return;
          navigate(`/admin/local/${current._id}/info`);
        }}
      >
        Back to Information
      </Button>
    </Stack>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(Error404Page);
