import { createSlice } from "@reduxjs/toolkit";
import {
  BasicListingInfo,
  ContactProperties,
  GroupedListings,
  Listing,
  ReduxSelectedListing,
} from "../../helpers";
import { updateProperty } from "../../utils/Core";

interface StatePropsTypes {
  all: GroupedListings[];
  current?: BasicListingInfo;
  selected: ReduxSelectedListing;
}

export const listingsSlice = createSlice({
  name: "organizations",
  initialState: {
    all: [],
    current: undefined,
    selected: {
      modified: false,
      unmodified: undefined,
      data: undefined,
    },
  } as StatePropsTypes,
  reducers: {
    setCurrentListing: (state, action) => {
      state.current = action.payload;
    },
    setListings: (state, action) => {
      state.all = action.payload;
    },
    setSelectedListing: (state, action) => {
      state.selected.data = action.payload;
      state.selected.unmodified = action.payload;
      state.selected.modified = false;
    },
    updateSelected: (state, action) => {
      if (state.selected.data) {
        updateProperty(
          state.selected.data,
          action.payload.key,
          action.payload.value
        );
        state.selected.modified = true;
      }
    },
    resetSelected: (state) => {
      state.selected.data = state.selected.unmodified;
      state.selected.modified = false;
    },
  },
});

export const {
  setListings,
  setCurrentListing,
  setSelectedListing,
  updateSelected,
  resetSelected,
} = listingsSlice.actions;

export default listingsSlice.reducer;
