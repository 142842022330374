import {
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { Session } from "../../../helpers";
import { removeSession } from "../../../redux/reducers/SessionReducer";
import { RootState } from "../../../redux/store";
import ArrowMenu from "../core/Menu";
import { ExitToApp } from "@mui/icons-material";

interface Props {
  session?: Session;
  removeSession(): Action;
}

const AdminAppBarUser = ({ session, removeSession }: Props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    navigate("/signout", { replace: true });
    removeSession();
    handleClose();
  };

  return (
    <>
      <Button
        id="user-button"
        sx={{ p: 3, height: 40, borderRadius: 2 }}
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Stack direction="column">
            <Typography
              variant="body2"
              textTransform="capitalize"
              textAlign="right"
              fontWeight="800"
              lineHeight={1.25}
            >
              {session?.name}
            </Typography>
            <Typography
              variant="caption"
              textTransform="lowercase"
              textAlign="right"
              color="text.secondary"
              lineHeight={1.25}
            >
              {session?.email}
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: "grey.300",
              color: "text.secondary",
              height: 40,
              width: 40,
            }}
            src={session?.avatar}
            alt={session?.name}
          >
            {session?.name.charAt(0)}
          </Avatar>
        </Stack>
      </Button>
      <Menu
        id="user-menu"
        aria-labelledby="user-button"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem sx={{ width: 150 }} onClick={logout}>
          <ListItemIcon>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = ({ session: { data } }: RootState) => ({
  session: data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeSession: () => dispatch(removeSession),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppBarUser);
