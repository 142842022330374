import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import Logo from "../assets/images/logo.png";
import Lottie from "lottie-react";
import Loading from "../assets/lottie/loading.json";
import Success from "../assets/lottie/success.json";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MerchantApi from "../services/api/MerchantApi";
import { BasicListingInfo, MerchantCompleteRequest } from "../helpers";
import { connect } from "react-redux";
import { RootState } from "../redux/store";

interface Props {
  current?: BasicListingInfo;
}

const MerchantCompletePage = ({ current }: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    async function connect() {
      if (!current) return;
      try {
        const state = params.get("state");
        const code = params.get("code");
        if (!state || !code) return;
        const obj: MerchantCompleteRequest = {
          code,
          state,
        };

        const payload: any = await MerchantApi.finalizeMerchantSignup(
          current._id,
          obj
        );
        if (payload.success) {
          setSuccess(true);
          setTimeout(() => {
            navigate(`/admin/local/${current._id}/merchant/dashboard`, {
              replace: true,
            });
          }, 1500);
        }
      } catch (e) {
        console.log(e);
        setSuccess(false);
      }
    }

    connect();
  }, []);

  return (
    <Stack
      sx={{ alignItems: "center", justifyContent: "center", mt: 3 }}
      spacing={1}
    >
      <Box
        component="img"
        src={Logo}
        sx={{ height: 72, width: 72, borderRadius: 1 }}
      />
      <Stack alignItems="center" justifyItems="center">
        <Typography variant="h4">Neighborhood Muslim</Typography>
        <Typography variant="h6" color="text.secondary">
          Finalizing Account
        </Typography>
      </Stack>
      <Lottie
        animationData={success ? Success : Loading}
        loop={success ? false : true}
        autoplay={true}
        style={{
          objectFit: "contain",
          height: 150,
        }}
      />
      <Stack alignItems="center" justifyContent="center" spacing={0.5}>
        <Typography>
          Kindly remain patient as we conclude the finalization of your account
        </Typography>
        <Typography variant="caption" color="text.secondary" fontStyle="italic">
          Upon the completion of the account finalization process, you will be
          automatically redirected.
        </Typography>
        <Typography variant="caption" color="text.secondary" fontStyle="italic">
          Please refrain from navigating away from this page.
        </Typography>
      </Stack>
    </Stack>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(MerchantCompletePage);
