import {
  Chip,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Fee } from "../../../helpers";
import { MoreHoriz } from "@mui/icons-material";
import InformationLine from "../core/InformationLine";
import { useState } from "react";

interface Props {
  fee: Fee;
  index: number;
  onEdit(): void;
  onDelete(): void;
}

const MerchantFeeSingle = ({ fee, index, onEdit, onDelete }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem key={`fee-line-${fee.order}-${index}`} disableGutters>
        <ListItemText>
          <Typography
            sx={{ textTransform: "capitalize", fontWeight: "700" }}
            variant="inherit"
          >
            {fee.fixed ? "Fixed Fee" : "Variable Fee"}
          </Typography>
          <Typography variant="inherit" color="text.secondary">
            {fee.fixed ? `$${fee.value / 100}` : `${fee.value}%`}{" "}
            {fee.destination ? `to ${fee.destination}` : ""}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          </Stack>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={showMenu}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default MerchantFeeSingle;
