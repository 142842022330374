import {
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Claim } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import { MoreHoriz } from "@mui/icons-material";
import { useState } from "react";

interface Props {
  claim: Claim;
  onClaimChange(value: "approved" | "revoked"): void;
}

const ClaimsListItem = ({ claim, onClaimChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <UselessDefaultMotion type="item">
      <ListItem>
        <ListItemText>
          <Typography variant="inherit" sx={{ fontWeight: "700" }}>
            {claim.listing.name}
          </Typography>
          <Stack>
            <Typography variant="caption">
              Request By: {claim.user.name}
            </Typography>
            <Typography variant="caption">Email: {claim.user.email}</Typography>
            <Typography variant="caption">
              Phone: {claim.listing.phone}
            </Typography>
          </Stack>
        </ListItemText>
        <ListItemSecondaryAction>
          <Chip
            sx={{ textTransform: "capitalize" }}
            label={claim.status}
            color={
              claim.status === "pending"
                ? "warning"
                : claim.status === "revoked"
                ? "error"
                : "success"
            }
          />
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        id="claims-option-menu"
        aria-labelledby="claims-option-button"
        anchorEl={anchorEl}
        open={showMenu}
        onClose={handleClose}
      >
        {claim.status !== "approved" && (
          <MenuItem
            dense
            onClick={() => {
              onClaimChange("approved");
              handleClose();
            }}
          >
            Approve Claim
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            onClaimChange("revoked");
            handleClose();
          }}
        >
          {claim.status === "pending" ? "Reject Claim" : "Revoke Claim"}
        </MenuItem>
        <Divider />
        <MenuItem
          dense
          onClick={() => {
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </UselessDefaultMotion>
  );
};

export default ClaimsListItem;
