import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  open: boolean;
  handleClose(value?: string | number): void;
  title: string;
  description?: string;
  defaultValue: string | number;
  textField: TextFieldProps;
  action: {
    acceptLabel: string;
    rejectLabel: string;
  };
}

const FormDialog = ({
  open,
  title,
  description,
  textField,
  defaultValue,
  action,
  handleClose,
}: Props) => {
  const [value, setValue] = useState<string | number>(defaultValue);

  useEffect(() => {
    if (open) setValue(defaultValue);
  }, [open]);

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {description && <DialogContentText>{description}</DialogContentText>}
          <TextField
            autoFocus
            fullWidth
            variant="standard"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            {...textField}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>{action.rejectLabel}</Button>
        <Button onClick={() => handleClose(!value ? 0 : value)}>
          {action.acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
