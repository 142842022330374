import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemButton,
  Stack,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RequestAccess from "../../assets/lottie/requestaccess.json";
import { setShowNewOrganizationDialog } from "../../redux/reducers/AppReducer";
import ListingsApi from "../../services/api/ListingsApi";
import { BasicListingInfo, Session } from "../../helpers";
import { RootState } from "../../redux/store";
import { enqueueSnackbar } from "notistack";

interface Props {
  session?: Session;
  setShowNewOrganizationDialog(show: boolean): void;
}

const NoOrganizationPage = (props: Props) => {
  const handleSearch = async (value: string) => {
    setText(value);
  };

  const handleSearchDelayed = debounce((value) => handleSearch(value), 500);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
        duration: 0.5,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const [options, setOptions] = useState<BasicListingInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [selected, setSelected] = useState<BasicListingInfo | null>();

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      if (text.trim().length === 0) {
        setOptions([]);
        setLoading(false);
        return;
      }

      const grouped = await ListingsApi.searchListingByText(text);
      const _combined = [];
      for (let group of grouped) {
        _combined.push(...group.listings);
      }
      setOptions(_combined);
      setLoading(false);
    }

    fetch();
  }, [text]);

  return (
    <motion.div variants={container} initial="hidden" animate="show">
      <Stack
        sx={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
        spacing={3}
      >
        <motion.div variants={item}>
          <Stack>
            <Typography variant="h3" textAlign="center" fontWeight="700">
              Welcome to
            </Typography>
            <Typography variant="h3" textAlign="center" fontWeight="700">
              Neighborhood Muslim
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="center"
              mt={2}
            >
              Request access to your listing
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="center"
            >
              This will enable you to make modifications to your listing
              information and update details regarding your organization.
            </Typography>
          </Stack>
        </motion.div>
        <motion.div variants={item}>
          <Lottie
            animationData={RequestAccess}
            loop={true}
            autoplay={true}
            style={{ objectFit: "contain", height: 200 }}
          />
        </motion.div>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <motion.div variants={item}>
            <Stack spacing={2}>
              <Autocomplete
                id="listing-search"
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                getOptionLabel={(option) => option.name}
                groupBy={(option) => option.kind}
                options={options}
                loading={loading}
                noOptionsText="No Listings found"
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option._id}>
                    <Stack>
                      <Typography variant="inherit">{option.name}</Typography>
                      <Typography variant="caption">
                        {option.location}
                      </Typography>
                    </Stack>
                  </Box>
                )}
                onChange={(event, value) => setSelected(value)}
                onInputChange={(event, value) => handleSearchDelayed(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Listings"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                variant="contained"
                onClick={async () => {
                  try {
                    if (!props.session || !selected) return;
                    const claim = await ListingsApi.initiateClaim(
                      props.session._id,
                      selected._id
                    );
                    enqueueSnackbar({
                      message:
                        "Your ownership claim has been successfully submitted. We will reach out to you as soon as possible to verify the provided details. Thank you for your submission.",
                      variant: "success",
                    });
                    setSelected(null);
                    setText("");
                  } catch (error: any) {
                    enqueueSnackbar({
                      message:
                        error?.response?.data?.message ??
                        "Unable to submit this claim",
                      variant: "error",
                    });
                  }
                }}
                disabled={!selected}
              >
                Request Access
              </Button>
            </Stack>
          </motion.div>
        </Grid>
      </Stack>
    </motion.div>
  );
};

const mapStateToProps = ({ session: { data } }: RootState) => ({
  session: data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setShowNewOrganizationDialog: (open: boolean) =>
    dispatch(setShowNewOrganizationDialog(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoOrganizationPage);
