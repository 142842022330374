import axios, { Axios, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import {
  removeSession,
  setSessionToken,
} from "../redux/reducers/SessionReducer";
import store from "../redux/store";
import { Environment } from "../configs";
import { setTopbarLoading } from "../redux/reducers/GlobalReducer";

class HttpClient {
  axios: Axios;

  constructor() {
    this.axios = axios.create({
      baseURL: Environment.api.baseUrl,
    });

    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.axios.interceptors.request.use(this._handleRequest, this._handleError);
  };

  _handleRequest = async (config: InternalAxiosRequestConfig) => {
    const {
      session: { token },
    } = store.getState();
    if (config?.headers && token) {
      config.headers.authorization = token;
    }
    store.dispatch(setTopbarLoading(true));
    return config;
  };

  _initializeResponseInterceptor = () => {
    this.axios.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  _handleResponse = async ({ headers, data }: AxiosResponse) => {
    if (headers.authorization) {
      store.dispatch(setSessionToken(headers.authorization));
    }
    store.dispatch(setTopbarLoading(false));
    return data;
  };

  _handleError = (error: any) => {
    console.log(error);
    store.dispatch(setTopbarLoading(false));
    if (error.response?.data?.statusCode === 401) {
      store.dispatch(removeSession());
    }
    return Promise.reject(error);
  };
}

export default HttpClient;
