import {
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { Tracking } from "../../../helpers/Orders";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Check, PendingOutlined } from "@mui/icons-material";

interface Props {
  trackings?: Tracking[];
}

const OrderTracking = ({ trackings }: Props) => {
  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader title="Tracking" subtitle="Present Order Status" />
      {trackings?.map((track) => (
        <ListItem key={track.id}>
          <Stack sx={{ alignItems: "center", width: 32, mr: 2 }}>
            {track.status === 0 && <PendingOutlined />}
            {track.status === 1 && <CircularProgress size={24} />}
            {track.status === 2 && <Check />}
          </Stack>
          <ListItemText primary={track.text} />
        </ListItem>
      ))}

      {(!trackings || trackings.length === 0) && (
        <UselessEmptyHolder
          icon="LocationDisabled"
          text="No tracking data"
          description="Tracking Information Unavailable at the Moment"
        />
      )}
    </UselessDefaultMotion>
  );
};

export default OrderTracking;
