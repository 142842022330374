import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useEffect, useState } from "react";
import UselessLoading from "../../useless/UselessLoading";

interface Props {
  open: boolean;
  handleClose(): void;
  handleSubmit(): void;
  title: string;
  description?: string;
  loading?: boolean;
  action?: {
    acceptLabel?: string;
    rejectLabel?: string;
  };
}

const ConfirmationDialog = ({
  open,
  title,
  description,
  action,
  handleClose,
  handleSubmit,
  loading,
}: Props) => {
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {description && <DialogContentText>{description}</DialogContentText>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>
          {action?.rejectLabel ?? "Cancel"}
        </Button>
        <Button onClick={() => handleSubmit()} disabled={loading}>
          {action?.acceptLabel ?? "Submit"}
        </Button>
      </DialogActions>
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
        />
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
