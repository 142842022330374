import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Cart, Charges } from "../../../helpers/Orders";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import OrderLineItem from "./OrderLineItem";
import { Receipt } from "@mui/icons-material";

interface Props {
  receiptUrl?: string;
  cart?: Cart;
  charges?: Charges;
}

const OrderDetails = ({ cart, charges, receiptUrl }: Props) => {
  return (
    <UselessDefaultMotion type="item">
      <Card>
        <CardHeader
          title="Receipt"
          titleTypographyProps={{ variant: "h6", fontWeight: "700" }}
          action={
            <IconButton
              onClick={() => window.open(receiptUrl, "_blank", "noreferrer")}
            >
              <Receipt />
            </IconButton>
          }
        />
        <CardContent>
          <Stack spacing={1}>
            {cart?.items.map((item, index) => (
              <OrderLineItem
                key={`${item._id}-index`}
                name={`${item.quantity} x ${item.name}`}
                value={`$ ${(item.charges.subtotal / 100).toFixed(2)}`}
                nameStyle={{ fontWeight: "700" }}
                valueStyle={{ fontWeight: "700" }}
                subtext={item.subtext}
                notes={item.notes}
                containerStyle={{
                  mt: 1,
                  mb: 1,
                }}
              />
            ))}
            <Divider />
            <Stack>
              <OrderLineItem
                name="Subtotal"
                value={`$ ${((charges?.subtotal ?? 0) / 100).toFixed(2)}`}
              />
              <OrderLineItem
                name="Tax"
                value={`$ ${((charges?.tax ?? 0) / 100).toFixed(2)}`}
              />
              <OrderLineItem
                name="Fees"
                value={`$ ${((charges?.fees ?? 0) / 100).toFixed(2)}`}
              />
            </Stack>
            <Divider />
            <Stack>
              <OrderLineItem
                name="Total"
                value={`$ ${((charges?.total ?? 0) / 100).toFixed(2)}`}
                nameStyle={{ fontWeight: "700" }}
                valueStyle={{ fontWeight: "700" }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </UselessDefaultMotion>
  );
};

export default OrderDetails;
