import { Button, Stack, Typography } from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import HoursDialog from "../hours/HoursDialog";
import { useState } from "react";
import { AccessTime } from "@mui/icons-material";
import { Hours, Period } from "../../../helpers";
import { splitHoursTextToArray } from "../../../defaults";
import { Dispatch } from "redux";
import { RootState } from "../../../redux/store";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";

interface Props {
  hours: Hours;
  updateHours(hours: Hours): void;
}

const ListingHours = ({ hours, updateHours }: Props) => {
  const [state, setState] = useState({
    open: false,
  });

  const onHoursDialogClose = () => setState({ ...state, open: false });

  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Hours"
        subtitle="Business Hours: Share Your Availability with the World"
        action={
          <Button
            variant="contained"
            component="label"
            startIcon={<AccessTime />}
            onClick={() => setState({ ...state, open: true })}
          >
            Edit
          </Button>
        }
      />
      {splitHoursTextToArray(hours.text).map((row) => (
        <Stack direction="row" spacing={1} key={row.day}>
          <Typography sx={{ fontWeight: "700", width: 128 }}>
            {row.day}
          </Typography>
          <Stack>
            {row.hours.map((h) => (
              <Typography key={h}>{h}</Typography>
            ))}
          </Stack>
        </Stack>
      ))}
      <HoursDialog
        open={state.open}
        hours={hours}
        onUpdate={updateHours}
        onClose={onHoursDialogClose}
      />
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  hours: data?.hours || {
    periods: [],
    text: [],
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateHours: (value: Hours) =>
    dispatch(updateSelected({ key: "hours", value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingHours);
