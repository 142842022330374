import { ListSubheader } from "@mui/material";
import React from "react";
import { Fee, FeeType } from "../../../helpers";
import MerchantFeeSingle from "./MerchantFeeSingle";

interface Props {
  type: FeeType;
  fees: Fee[];
  onEdit(fee: Fee): void;
  onDelete(fee: Fee): void;
}

const MerchantFeeGrouped = ({ fees, type, onEdit, onDelete }: Props) => {
  return (
    <React.Fragment>
      <ListSubheader
        disableGutters
        sx={{ background: "inherit", textTransform: "uppercase" }}
        color="primary"
      >
        {type}
      </ListSubheader>
      {fees.map((f, index) => (
        <MerchantFeeSingle
          key={`fee-${f.order}-${index}`}
          onEdit={() => onEdit(f)}
          onDelete={() => onDelete(f)}
          fee={f}
          index={index}
        />
      ))}
    </React.Fragment>
  );
};

export default React.memo(MerchantFeeGrouped);
