import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import { useEffect, useState } from "react";
import {
  MerchantDashboardInfo,
  MerchantDetails,
} from "../../../helpers/Merchants";
import MerchantApi from "../../../services/api/MerchantApi";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { BasicListingInfo } from "../../../helpers";
import NoMerchant from "../../../components/admin/merchant/NoMerchant";
import UselessInfoBox from "../../../components/useless/UselessInfoBox";
import UselessInfoWidget from "../../../components/useless/UselessInfoWidget";
import UselessSectionHeader from "../../../components/useless/UselessSectionHeader";
import { ChevronRight, CreditScore, Link } from "@mui/icons-material";
import OrderApi from "../../../services/api/OrderApi";
import { OrderResponse } from "../../../helpers/Orders";
import OrderRow from "../../../components/admin/orders/OrderRow";
import { useNavigate } from "react-router";
import ConfirmationDialog from "../../../components/admin/dialogs/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";

interface Props {
  current?: BasicListingInfo;
}

const MerchantsDashboardPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState<MerchantDashboardInfo>();
  const [orders, setOrders] = useState<OrderResponse>();
  const [ipOpen, setIpOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      if (!current) return;
      setLoading(true);
      try {
        const _info = await MerchantApi.getMerchantInfoByListingId(current._id);
        setInfo(_info);

        const _orders = await OrderApi.getOrdersData({
          listingId: current._id,
          limit: 10,
          page: 1,
        });
        setOrders(_orders);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, []);

  return (
    <UselessPageCarded
      header={{
        title: "Merchant Dashboard",
        subtitle:
          "All transactions associated with this merchant Stripe account",
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <UselessInfoBox
              topText="Transactions"
              loading={loading}
              centerTopText={`$${info?.transactions.sum.value ?? 0}`}
              centerBottomText="This Week"
              centerTextColor="#ffc107"
              bottomText="Total Count:"
              bottomTextBold={info?.transactions.count.toString() ?? "0"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <UselessInfoBox
              topText="Payout"
              loading={loading}
              centerTopText={`$${(info?.balance.available ?? 0) / 100}`}
              centerBottomText="Available Balance"
              centerTextColor="#2196f3"
              bottomText="Pending:"
              bottomTextBold={`$${(info?.balance.pending ?? 0) / 100}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <UselessInfoBox
              topText="Payment Status"
              loading={loading}
              centerTopText={info?.info.capabilities.card ?? ""}
              centerBottomText="Capabilities"
              centerTextColor="#4caf50"
              bottomText="Cards: "
              bottomTextBold={
                info?.info.capabilities.charges ? "Enabled" : "Disabled"
              }
            />
          </Grid>
        </Grid>
        <Stack>
          <UselessSectionHeader
            title="Recent Transactions"
            subtitle={`View your top 10 recent transactions or click "View all" for a complete transaction history.`}
            action={
              <Button
                onClick={() => {
                  if (current) navigate(`/admin/local/${current._id}/orders`);
                }}
                endIcon={<ChevronRight />}
              >
                View All
              </Button>
            }
          />
          {loading && (
            <Stack spacing={0.5}>
              <Skeleton variant="rectangular" width={"100%"} height={10} />
              <Skeleton variant="rectangular" width={"100%"} height={10} />
              <Skeleton variant="rectangular" width={"100%"} height={10} />
              <Skeleton variant="rectangular" width={"100%"} height={10} />
            </Stack>
          )}
          <List disablePadding>
            {orders?.data.map((order) => (
              <OrderRow
                listItemButtonProps={{
                  disableGutters: true,
                }}
                order={order}
                key={order._id}
                onClick={() => {
                  if (current)
                    navigate(`/admin/local/${current._id}/orders/${order._id}`);
                }}
              />
            ))}
          </List>
        </Stack>
        <Stack>
          <UselessSectionHeader
            title="Management"
            subtitle="Administer your payouts and Stripe account through the following actions"
          />
          <List>
            <ListItem>
              <ListItemText
                primary="Initiate Payout"
                secondary="You can initiate a payout when there is an available balance in your account. This process is also automatic for your account."
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => setIpOpen(true)}>
                  <CreditScore />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {info?.type === "express" && (
              <ListItem>
                <ListItemText
                  primary="View Stripe Account"
                  secondary="Access comprehensive information about Stripe, including details about your payouts, account specifics, transfers, and any other relevant data."
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => window.open(info?.login.url, "_blank")}
                  >
                    <Link />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Stack>
      </Stack>
      <ConfirmationDialog
        title="Initiate Payout"
        description="Initiating this action will initiate the payout process. Please note that it may take a couple of days for the payouts to reflect in your account."
        open={ipOpen}
        handleClose={() => setIpOpen(false)}
        handleSubmit={async () => {
          try {
            if (!current) return;
            setIpOpen(false);
            await MerchantApi.createPayout(current._id);
            enqueueSnackbar({
              message: "Payout successfully initiated",
              variant: "success",
            });
          } catch (e: any) {
            console.log(e);
            enqueueSnackbar({
              message:
                e?.response?.data?.message ?? "Unable to create a payout",
              variant: "error",
            });
          }
        }}
        action={{
          acceptLabel: "Initiate",
          rejectLabel: "Cancel",
        }}
      />
    </UselessPageCarded>
  );
};

const mapsStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapsStateToProps)(MerchantsDashboardPage);
