const Environment = {
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  },
  merchants: {
    baseUrl: process.env.REACT_APP_API_MERCHANT_URL,
  },
  stripe: {
    connect: {
      key: process.env.REACT_APP_STRIPE_CONNECT_KEY,
      url: process.env.REACT_APP_STRIPE_CONNECT_URL,
      clientId: process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID,
      redirectUri: process.env.REACT_APP_STRIPE_CONNECT_REDIRECT_URI,
    },
  },
};

export default Environment;
