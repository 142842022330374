import { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router";
import { Dispatch } from "redux";
import { RootState } from "../../../redux/store";
import { BasicListingInfo, Listing } from "../../../helpers";
import { setSelectedListing } from "../../../redux/reducers/ListingsReducer";
import ListingsApi from "../../../services/api/ListingsApi";

interface Props {
  current?: BasicListingInfo;
  setSelectedListing(listing: Listing): void;
}

const LocalPage = ({ current, setSelectedListing }: Props) => {
  useEffect(() => {
    const fetch = async () => {
      if (!current) return;
      const listing = await ListingsApi.getListingById(current?._id);
      setSelectedListing(listing);
    };

    fetch();
  }, [current]);

  return <Outlet />;
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSelectedListing: (listing: Listing) =>
    dispatch(setSelectedListing(listing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalPage);
