import { Stack, SxProps, Typography } from "@mui/material";

interface Props {
  day: string;
  dayStyle?: SxProps;
  hours: string[];
  hoursStyle?: SxProps;
}

const OnlineOrderingHoursListItem = ({
  day,
  dayStyle,
  hours,
  hoursStyle,
}: Props) => {
  return (
    <Stack direction="row" sx={{ mb: 1 }}>
      <Typography
        variant="body1"
        fontWeight="700"
        sx={{ minWidth: 128, ...dayStyle }}
      >
        {day}
      </Typography>
      <Stack>
        {hours.map((hour, index) => (
          <Typography
            variant="body1"
            sx={{ ...hoursStyle }}
            key={`${hour}-${index}`}
          >
            {hour}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default OnlineOrderingHoursListItem;
