import { createSlice } from "@reduxjs/toolkit";

interface StatePropsTypes {
  topbarLoading: boolean;
}

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    topbarLoading: false,
  } as StatePropsTypes,
  reducers: {
    setTopbarLoading: (state, action) => {
      state.topbarLoading = action.payload;
    },
  },
});

export const { setTopbarLoading } = globalSlice.actions;

export default globalSlice.reducer;
