import { motion } from "framer-motion";

interface UselessDefaultMotionProps {
  children?: React.ReactNode;
  type: "container" | "item";
  style?: React.CSSProperties;
}

const UselessDefaultMotion = (props: UselessDefaultMotionProps) => {
  const { children, type, style } = props;

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 10 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      style={style}
      variants={type === "container" ? container : item}
      initial={type === "container" ? "hidden" : undefined}
      animate={type === "container" ? "show" : undefined}
    >
      {children}
    </motion.div>
  );
};

export default UselessDefaultMotion;
