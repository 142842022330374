import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import DialogTransition from "../core/DialogTransition";
import NumberFormatTextField from "../core/NumberFormatTextField";
import { useState } from "react";

interface Props {
  open: boolean;
  onClose(value?: string): void;
}

const OrderPrepTimeDialog = ({ open, onClose }: Props) => {
  const [value, setValue] = useState("");

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
      onClose={() => onclose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Set Custom Prep Time"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          How long will it take to prep this order?
        </DialogContentText>
        <TextField
          sx={{ mt: 1 }}
          fullWidth
          label="Time (minutes)"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          InputProps={{
            inputComponent: NumberFormatTextField as any,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button onClick={() => onClose(value)}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderPrepTimeDialog;
