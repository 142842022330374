import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { id: string; value: string } }) => void;
  id: string;
}

const PercentageFormatTextField = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function PercentageFormatTextField(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        if (floatValue === undefined) return true;
        return formattedValue === "" || (floatValue >= 0 && floatValue <= 100);
      }}
      thousandSeparator
      decimalScale={2}
      valueIsNumericString
      suffix="%"
    />
  );
});
export default PercentageFormatTextField;
