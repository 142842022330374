import { Add, ArrowDropDown } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  Stack,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { BasicListingInfo, GroupedListings, Session } from "../../../helpers";
import { useThemeMediaQuery } from "../../../hooks";
import {
  setShowNewOrganizationDialog,
  toggleDrawerIsOpen,
} from "../../../redux/reducers/AppReducer";
import { setCurrentListing } from "../../../redux/reducers/ListingsReducer";
import { RootState } from "../../../redux/store";
import { enqueueSnackbar } from "notistack";
import ListingsApi from "../../../services/api/ListingsApi";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 64,
  marginBottom: 1,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme.palette.divider,
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: theme.palette.divider,
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 280,
  },
}));

interface Props {
  current?: BasicListingInfo;
  all: GroupedListings[];
  setCurrentListing(organization: BasicListingInfo): void;
  toggleDrawerIsOpen(): void;
  user?: Session;
}

const ListingSwitcher = ({
  current,
  all,
  setCurrentListing,
  toggleDrawerIsOpen,
  user,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { listingId } = useParams();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchListing = async (
    listing: BasicListingInfo,
    isRouted: boolean = false
  ) => {
    if (!user) return;
    try {
      handleClose();
      if (isMobile && !isRouted) {
        toggleDrawerIsOpen();
      }
      await ListingsApi.switch(user._id, listing._id);
      setCurrentListing(listing);
      setTimeout(() => navigate(`/admin/local/${listing._id}/info`), 100);
      enqueueSnackbar('Switched to "' + listing.name + '".', {
        variant: "info",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Failed to switch to "' + listing.name + '".', {
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    if (current && current?._id !== listingId) {
      const listings = all
        .map((group) => group.listings.map((listing) => listing))
        .flat(1);
      const selected = listings.find((listing) => listing._id === listingId);
      if (selected) {
        switchListing(selected, true);
      }
    }
  }, [listingId]);

  return (
    <Box sx={{ width: "100%" }}>
      <StyledListItemButton
        sx={{ minHeight: 64, marginBottom: 1, width: "100%" }}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: "100%" }}
          spacing={2}
        >
          <Avatar sx={{ height: 32, width: 32 }}>
            {current?.name[0] ?? "SL"}
          </Avatar>
          <ListItemText
            sx={{
              flex: 1,
            }}
            primaryTypographyProps={{
              fontWeight: "700",
              noWrap: true,
            }}
            primary={current?.name ?? "Select Listing"}
          />
          <ArrowDropDown />
        </Stack>
      </StyledListItemButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {all.length === 0 && (
          <Stack sx={{ p: 1 }}>
            <UselessEmptyHolder
              text="No listings found"
              description="You can request access to your listings by contacting us."
              descriptionStyles={{
                textAlign: "center",
              }}
              icon="ViewModule"
            />
          </Stack>
        )}
        {all.map((category, index) => (
          <Stack key={category.name}>
            <ListSubheader>{category.name}</ListSubheader>
            {category.listings?.map((listing) => (
              <MenuItem
                key={listing._id}
                sx={{ height: 48 }}
                dense
                onClick={() => switchListing(listing)}
                divider
                disabled={current?._id === listing._id}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Avatar sx={{ width: 24, height: 24 }}>
                    {listing.name[0] ?? "!"}
                  </Avatar>
                  <ListItemText
                    sx={{ flex: 1 }}
                    primaryTypographyProps={{
                      noWrap: true,
                      fontWeight: "700",
                    }}
                    primary={listing.name}
                  />
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        ))}
      </StyledMenu>
    </Box>
  );
};

const mapStateToProps = ({
  listings: { current, all },
  session: { data },
}: RootState) => ({
  current,
  all,
  user: data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleDrawerIsOpen: () => dispatch(toggleDrawerIsOpen()),
  setCurrentListing: (organization: BasicListingInfo) =>
    dispatch(setCurrentListing(organization)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingSwitcher);
