import { ListItemButton, styled } from "@mui/material";

const NavLinkButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  "&.Mui-disabled": {
    opacity: 1,
  },
}));

export default NavLinkButton;
