import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";

import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import logo from "../../../assets/images/logo.png";
import { DEFAULT_DRAWER_OVERLAY_TRIGGER } from "../../../configs";
import { BasicListingInfo, Session } from "../../../helpers";
import {
  setDrawerRoutes,
  toggleDrawer,
} from "../../../redux/reducers/AppReducer";
import { RootState } from "../../../redux/store";
import useWindowSize from "../../../utils/WindowResize";
import Drawer from "./Drawer";
import NavItemComponent from "./NavItemComponent";
import OrganizationSwitcher from "./ListingSwitcher";
import { NavItem, filteredRoutes } from "./Routes";
import PerfectScrollBar from "../core/PerfectScrollBar";

interface Props {
  open: boolean;
  session?: Session;
  current?: BasicListingInfo;
  routes: NavItem[];
  toggleDrawer(isOpen: boolean): Action;
  setDrawerRoutes(routes: NavItem[]): Action;
}

const AdminDrawer = ({
  open,
  session,
  current,
  routes,
  toggleDrawer,
  setDrawerRoutes,
}: Props) => {
  const size = useWindowSize();

  useEffect(() => {
    if (current && session) {
      const routes = filteredRoutes({
        kind: current.kind,
        superadmin: session.superadmin,
      });
      setDrawerRoutes(routes);
    }
    if (current === undefined) {
      toggleDrawer(false);
    }
  }, [current, session]);

  return (
    <Drawer
      open={open}
      variant={
        size.width >= DEFAULT_DRAWER_OVERLAY_TRIGGER
          ? "persistent"
          : "temporary"
      }
      elevation={0}
      anchor="left"
      onClose={() => toggleDrawer(false)}
    >
      <PerfectScrollBar>
        <Stack
          sx={{ pt: 2, pb: 2, width: "100%", alignItems: "center" }}
          spacing={2}
        >
          <Stack direction="row" sx={{ width: "100%", pl: 2, pr: 2 }}>
            <Box
              component="img"
              sx={{ width: "36px", borderRadius: 1 }}
              src={logo}
            />
            <Stack direction="row" ml={2} spacing={0.5}>
              <Typography fontWeight="700" fontSize={24} letterSpacing={-1}>
                Admin
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="center" spacing={3} sx={{ width: "100%" }}>
            {/* <UserInfo /> */}
            <OrganizationSwitcher />
            <Stack
              spacing={2}
              sx={{
                alignItems: "flex-start",
                width: "100%",
                pl: 1.5,
                pr: 1.5,
              }}
            >
              {routes.map((route) => (
                <NavItemComponent route={route} key={route.id} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </PerfectScrollBar>
    </Drawer>
  );
};

const mapStateToProps = ({
  app: { drawerIsOpen, drawerRoutes },
  listings: { current },
  session: { data },
}: RootState) => ({
  open: drawerIsOpen,
  current,
  session: data,
  routes: drawerRoutes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
  setDrawerRoutes: (routes: NavItem[]) => dispatch(setDrawerRoutes(routes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDrawer);
