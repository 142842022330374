import MenuIcon from "@mui/icons-material/MenuRounded";
import { AppBar, IconButton, Stack, Toolbar } from "@mui/material";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { toggleDrawerIsOpen } from "../../../redux/reducers/AppReducer";
import { RootState } from "../../../redux/store";
import AdminAppBarNotifications from "./AdminAppBarNotifications";
import AdminAppBarUser from "./AdminAppBarUser";
import ListingSwitcher from "./ListingSwitcher";

interface Props {
  open: boolean;
  toggleDrawer(): Action;
}

const AdminAppBar = ({ toggleDrawer }: Props) => {
  return (
    <AppBar sx={{ backgroundColor: "#fff" }} elevation={1} position="sticky">
      <Toolbar sx={{ pr: "8px !important" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
          direction="row"
        >
          <Stack direction="row" sx={{ flex: 1 }}>
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </Stack>
          {/* <ListingSwitcher /> */}
          {/* <AdminAppBarNotifications /> */}
          <AdminAppBarUser />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ app: { drawerIsOpen } }: RootState) => ({
  open: drawerIsOpen,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleDrawer: () => dispatch(toggleDrawerIsOpen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppBar);
