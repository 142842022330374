import { ChevronLeft } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicInformation from "../../../../../components/admin/restaurants/BasicInformation";
import SortableAutocomplete from "../../../../../components/admin/restaurants/SortableAutoComplete";
import UselessPageCarded from "../../../../../components/useless/UselessPageCarded";
import {
  BasicListingInfo,
  Category,
  CategoryItem,
  Item,
  Menu,
  SingleCategory,
} from "../../../../../helpers";
import RestaurantApi from "../../../../../services/api/RestaurantApi";
import { connect } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { enqueueSnackbar } from "notistack";
import SingleAutoComplete from "../../../../../components/admin/restaurants/SingleAutoComplete";

interface StateProps {
  name: string;
  description: string;
  items: Item[];
  menus: Menu[];
  visible: boolean;
}

interface Props {
  current?: BasicListingInfo;
}

const ManageCategoryPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const [menus, setMenus] = useState<Menu[]>([]);
  const [state, setState] = useState<StateProps>({
    name: "",
    description: "",
    items: [],
    menus: [],
    visible: true,
  });

  useEffect(() => {
    if (!current) return;

    const fetchMenus = async () => {
      const menus = await RestaurantApi.getData({
        listingId: current._id,
        type: "menus",
        limit: 999,
        page: 1,
      });

      setMenus(menus.data as Menu[]);
    };
    fetchMenus();

    if (!categoryId) return;
    const fetch = async () => {
      const category = (await RestaurantApi.getDataById(
        current._id,
        "categories",
        categoryId
      )) as SingleCategory;

      setState({
        name: category.name,
        description: category.description,
        items: category.items,
        menus: category.menus,
        visible: category.visible,
      });
    };
    fetch();
  }, []);

  const onSave = async () => {
    try {
      if (!current) return;

      const payload = {
        name: state.name,
        description: state.description,
        visible: state.visible,
        items: state.items.map((c) => c._id),
        menus: state.menus.map((m) => m._id),
      };

      if (categoryId) {
        await RestaurantApi.updateDataById(
          current._id,
          "categories",
          categoryId,
          payload
        );
      } else {
        const category = await RestaurantApi.createDataByType(
          current._id,
          "categories",
          payload
        );

        navigate(category._id, { replace: true });
      }
      enqueueSnackbar("Category has been updated", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        "Unable to save the category at this time. Try again later",
        {
          variant: "error",
        }
      );
    }
  };
  return (
    <UselessPageCarded
      header={{
        title: categoryId ? "Manage Category" : "Create Category",
        subtitle: "Compose your restaurant's category",
        left: (
          <Stack justifyContent="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
          </Stack>
        ),
        right: (
          <Stack justifyContent="center">
            <Button
              variant="contained"
              onClick={() => onSave()}
              disabled={state.name.trim().length === 0}
            >
              Save
            </Button>
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <BasicInformation
          name={state.name}
          description={state.description}
          onChange={(event) =>
            setState({ ...state, [event.target.id]: event.target.value })
          }
        >
          <FormControlLabel
            control={<Switch checked={state.visible} />}
            label="Visible"
            onChange={(event, checked) =>
              setState({ ...state, visible: checked })
            }
          />
        </BasicInformation>
        <SingleAutoComplete
          title="Menu"
          subtitle="Select all the menus you'd like this item to be included in. If you can't find the menu you desire, you have the option to create it on the Menus page. You can also set the sorting preferences on the Menus page."
          options={menus}
          selected={state.menus}
          label="Menus"
          onSelectedUpdate={(value) => {
            setState({ ...state, menus: value as Menu[] });
          }}
        />
        <SortableAutocomplete
          title="Items"
          subtitle="Pick and arrange your items"
          type="items"
          selected={state.items}
          onSelectedUpdate={(value) =>
            setState({ ...state, items: value as Item[] })
          }
        />
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(ManageCategoryPage);
