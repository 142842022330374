export const DefaultRestaurantTabOptions = [
  {
    id: 0,
    label: "Menus",
    href: "menus?page=0&limit=25",
    actionLabel: "Add Menu",
    actionHref: "../manage/menu",
  },
  {
    id: 1,
    label: "Categories",
    href: "categories?page=0&limit=25",
    actionLabel: "Add Category",
    actionHref: "../manage/category",
  },
  {
    id: 2,
    label: "Items",
    href: "items?page=0&limit=25",
    actionLabel: "Add Item",
    actionHref: "../manage/item",
  },
  {
    id: 0,
    label: "Modifiers",
    href: "modifiers?page=0&limit=25",
    actionLabel: "Add Modifier",
    actionHref: "../manage/modifier",
  },
];
