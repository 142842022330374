import { Add, AddAPhoto, Delete } from "@mui/icons-material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import React, { Component, ReactNode, useState } from "react";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Image } from "../../../helpers";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";
import { RootState } from "../../../redux/store";
import { Dispatch } from "redux";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import { resizeFile } from "../../../utils/Core";

const Photos = ({
  images,
  updateImages,
}: {
  images: Image[];
  updateImages(array: Image[]): void;
}) => {
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const arr = [...images];
    const updated = arrayMove(arr, oldIndex, newIndex);
    updateImages(updated);
  };

  const ImageHolder = ({
    item,
    onDelete,
  }: {
    item: Image;
    onDelete(): void;
  }) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
      <Stack
        sx={{ position: "relative", height: 100, width: 100 }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isHovering && (
          <IconButton
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
            disableRipple
            size="small"
            onClick={(event) => {
              onDelete();
              event.stopPropagation();
            }}
          >
            <Delete sx={{ pointerEvents: "none" }} />
          </IconButton>
        )}
        <Box
          component="img"
          sx={{
            height: 100,
            width: 100,
            p: 1,
            borderRadius: 1,
            objectFit: "cover",
          }}
          src={item.source}
        />
      </Stack>
    );
  };

  const ImagePreview = SortableElement<{ item: Image; onDelete(): void }>(
    ({ item, onDelete }: { item: Image; onDelete(): void }) => (
      <ImageHolder item={item} onDelete={onDelete} />
    )
  );

  const SortableList = SortableContainer<{ items: Image[] }>(
    ({ items }: { items: Image[] }) => (
      <Box display="flex">
        {items.map((image, index) => {
          if (image.deleted) return;
          return (
            <ImagePreview
              index={index}
              key={`img-${index}`}
              item={image}
              onDelete={() => {
                const arr = [...images];
                if (arr[index].new || !arr[index]._id) {
                  console.log(index);
                  arr.splice(index, 1);
                } else {
                  arr[index] = {
                    ...arr[index],
                    deleted: true,
                  };
                }
                updateImages(arr);
              }}
            />
          );
        })}
      </Box>
    )
  );

  const handleFileUpload = async (event: any) => {
    const arr = [...images];
    const files = event.target.files;
    if (files.length > 0) {
      for (let file of files) {
        const f = await resizeFile(file);
        const i: Image = {
          source: f,
          height: 0,
          width: 0,
          size: 4 * Math.ceil((f.length / 3) * 0.5624896334383812),
          new: true,
          deleted: false,
        };
        arr.push(i);
      }
      updateImages(arr);
    }
  };

  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Photos"
        subtitle="Showcase Your Business: Upload Photos"
        action={
          <Button
            variant="contained"
            component="label"
            startIcon={<AddAPhoto />}
          >
            Upload Image
            <input
              hidden
              accept="image/jpeg"
              multiple
              type="file"
              onChange={handleFileUpload}
            />
          </Button>
        }
      />
      <UselessDefaultMotion type="item">
        <Stack
          sx={{
            background: "#eaeaea",
            p: 3,
            borderRadius: 1,
          }}
        >
          {images.length > 0 && (
            <SortableList items={images} axis={"xy"} onSortEnd={onSortEnd} />
          )}
          {images.filter((f) => !f.deleted).length === 0 && (
            <UselessEmptyHolder
              text="No Images found"
              icon="HideImage"
              parentContainerStyles={{ alignSelf: "center" }}
              description="Enhance Your Profile with Visuals: Incorporate Images into Your Listing"
            />
          )}
        </Stack>
      </UselessDefaultMotion>
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  images: data?.images || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateImages: (value: Image[]) =>
    dispatch(updateSelected({ key: "images", value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
