import { Divider, Stack } from "@mui/material";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import DefaultSettings from "../../../components/admin/order-settings/DefaultSettings";
import OrderingHours from "../../../components/admin/order-settings/OrderingHours";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { BasicListingInfo, Hours } from "../../../helpers";
import OrderApi from "../../../services/api/OrderApi";
import {
  DefaultUpdateTypes,
  Notification,
  OrderSettings,
} from "../../../helpers/Settings";
import OrderNotifications from "../../../components/admin/order-settings/OrderNotifications";
import MerchantApi from "../../../services/api/MerchantApi";

interface Props {
  current?: BasicListingInfo;
}

const OrderSettingsPage = ({ current }: Props) => {
  const [settings, setSettings] = useState<OrderSettings>();

  useEffect(() => {
    async function fetch() {
      if (!current) return;

      const _settings = await MerchantApi.getOrderSettings(current._id);
      setSettings(_settings);
    }

    fetch();
  }, []);

  const onUpdateHours = async (_hours: Hours) => {
    if (!current || !settings) return;
    const { hours } = await MerchantApi.updateOrderSettingsHours(
      current._id,
      _hours.periods
    );
    const _settings = {
      ...settings,
      hours: hours,
    };

    setSettings(_settings);
  };

  const onUpdateNotifications = async (_notifications: Notification[]) => {
    if (!current || !settings) return;
    const notifications = await MerchantApi.updateOrderSettingsNotifications(
      current._id,
      _notifications.map((notification) => ({
        type: notification.type,
        value: notification.value,
        offline: notification.offline,
      }))
    );

    console.log(notifications);
    const _settings = {
      ...settings,
      notifications,
    };

    setSettings(_settings);
  };

  const onUpdateDefault = async (
    type: DefaultUpdateTypes,
    value: string | number
  ) => {
    if (!current || !settings) return;
    await MerchantApi.updateOrderSettingsDefaults(current._id, type, {
      [type]: value,
    });
    const _settings = {
      ...settings,
      defaults: {
        ...settings.defaults,
        [type]: value,
      },
    };

    setSettings(_settings);
  };

  return (
    <UselessPageCarded
      header={{
        title: "Orders Settings",
        subtitle: "Comprehensive Order Settings Management",
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        {settings && (
          <DefaultSettings
            defaults={settings.defaults}
            onUpdate={onUpdateDefault}
          />
        )}
        {settings && (
          <OrderingHours hours={settings.hours} onUpdate={onUpdateHours} />
        )}
        {settings && (
          <OrderNotifications
            notifications={settings.notifications}
            onUpdate={onUpdateNotifications}
          />
        )}
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(OrderSettingsPage);
