import { Environment } from "../../configs";
import {
  Image,
  RestaurantDataTypes,
  RestaurantDataTypesSingle,
  RestaurantOptions,
  RestaurantResponse,
} from "../../helpers";
import HttpClient from "../HttpService";

interface GetRestaurantData {
  listingId: string;
  type: RestaurantOptions;
  text?: string;
  page: number;
  limit: number;
}

class RestaurantApi extends HttpClient {
  constructor() {
    super();
    this.axios.defaults.baseURL = Environment.merchants.baseUrl;
  }
  getData({
    listingId,
    type,
    page,
    limit,
    text,
  }: GetRestaurantData): Promise<RestaurantResponse> {
    const params: any = {
      page,
      limit,
    };

    if (text) {
      params.text = text;
    }
    return this.axios.get(
      `/v1/manage/listings/${listingId}/restaurant/${type}`,
      {
        params,
      }
    );
  }

  getDataById(
    listingId: string,
    type: RestaurantOptions,
    id: string
  ): Promise<RestaurantDataTypesSingle> {
    return this.axios.get(
      `/v1/manage/listings/${listingId}/restaurant/${type}/${id}`
    );
  }

  deleteDataById(
    listingId: string,
    type: RestaurantOptions,
    id: string
  ): Promise<RestaurantDataTypesSingle> {
    return this.axios.delete(
      `/v1/manage/listings/${listingId}/restaurant/${type}/${id}`
    );
  }

  createDataByType(
    listingId: string,
    type: RestaurantOptions,
    payload: any
  ): Promise<RestaurantDataTypesSingle> {
    return this.axios.post(
      `/v1/manage/listings/${listingId}/restaurant/${type}`,
      payload
    );
  }

  updateDataById(
    listingId: string,
    type: RestaurantOptions,
    id: string,
    payload: any
  ) {
    return this.axios.put(
      `/v1/manage/listings/${listingId}/restaurant/${type}/${id}`,
      payload
    );
  }

  createMerchantImage(
    listingId: string,
    image: Image
  ): Promise<{ success: boolean; image: Image }> {
    return this.axios.post(`/v1/manage/listings/${listingId}/merchant/images`, {
      size: image.size,
      src: image.source.substring("data:image/jpeg;base64,".length),
    });
  }

  deleteMerchantImage(listingId: string, imageId: string) {
    return this.axios.delete(
      `/v1/manage/listings/${listingId}/merchant/images/${imageId}`
    );
  }
}

export default new RestaurantApi();
