import { Stack, SxProps, Typography } from "@mui/material";

interface Props {
  title: string;
  titleStyle?: SxProps;
  value: string;
  valueProps?: SxProps;
}

const InformationLine = ({ title, titleStyle, value, valueProps }: Props) => {
  return (
    <Stack spacing={1} direction="row">
      <Typography variant="body1" fontWeight="700" sx={{ ...titleStyle }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ ...valueProps }}>
        {value}
      </Typography>
    </Stack>
  );
};

export default InformationLine;
