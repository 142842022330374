import { ChevronLeft } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BasicInformation from "../../../../../components/admin/restaurants/BasicInformation";
import ImageSelector from "../../../../../components/admin/restaurants/ImageSelector";
import ModifierSelector from "../../../../../components/admin/restaurants/ModifierSelector";
import PricingComponent from "../../../../../components/admin/restaurants/PricingComponent";
import UselessPageCarded from "../../../../../components/useless/UselessPageCarded";
import {
  BasicListingInfo,
  Category,
  Image,
  Modifier,
  OrderSettings,
  Price,
  SingleItem,
} from "../../../../../helpers";
import { RootState } from "../../../../../redux/store";
import RestaurantApi from "../../../../../services/api/RestaurantApi";
import MerchantApi from "../../../../../services/api/MerchantApi";
import SingleAutoComplete from "../../../../../components/admin/restaurants/SingleAutoComplete";

interface StateProps {
  name: string;
  description: string;
  tags: string[];
  modifiers: Modifier[];
  categories: Category[];
  visible: boolean;
  price: Price;
  images: Image[];
}

interface Props {
  current?: BasicListingInfo;
}

const ManageItemPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const { itemId } = useParams();

  const [categories, setCategories] = useState<Category[]>([]);
  const [state, setState] = useState<StateProps>({
    name: "",
    description: "",
    modifiers: [],
    visible: true,
    tags: [],
    categories: [],
    price: {
      tax: 0,
      value: 0,
    },
    images: [],
  });

  useEffect(() => {
    if (!current) return;

    const fetchCategories = async () => {
      const categories = await RestaurantApi.getData({
        listingId: current._id,
        type: "categories",
        limit: 999,
        page: 1,
      });

      setCategories(categories.data as Category[]);
    };
    fetchCategories();

    if (!itemId) {
      MerchantApi.getOrderSettings(current._id).then((settings) => {
        setState({
          ...state,
          price: {
            ...state.price,
            tax: settings.defaults.tax,
          },
        });
      });
      return;
    }

    const fetch = async () => {
      const item = (await RestaurantApi.getDataById(
        current._id,
        "items",
        itemId
      )) as SingleItem;

      setState({
        name: item.name,
        description: item.description,
        modifiers: item.modifiers,
        visible: item.visible,
        tags: item.tags,
        categories: item.categories,
        price: {
          value: item.price.value / 100,
          tax: item.price.tax,
        },
        images: item.images,
      });
    };
    fetch();
  }, []);

  const onSave = async () => {
    try {
      if (!current) return;
      const images = [];
      for (let i = 0; i < state.images.length; ++i) {
        if (i === 0) {
          if (state.images[i]._id && !state.images[i].deleted) {
            images.push(state.images[i]._id);
            continue;
          } else if (state.images[i].new) {
            const { image } = await RestaurantApi.createMerchantImage(
              current._id,
              state.images[0]
            );
            images.push(image._id);
            continue;
          }
        }

        const imageId = state.images[i]._id;
        if (imageId) {
          await RestaurantApi.deleteMerchantImage(current._id, imageId);
        }
      }

      const payload = {
        name: state.name,
        description: state.description,
        visible: state.visible,
        modifiers: state.modifiers.map((c) => c._id),
        categories: state.categories.map((c) => c._id),
        price: {
          tax: state.price.tax,
          value: state.price.value * 100,
        },
        tags: state.tags,
        images,
      };

      if (itemId) {
        await RestaurantApi.updateDataById(
          current._id,
          "items",
          itemId,
          payload
        );
      } else {
        const item = await RestaurantApi.createDataByType(
          current._id,
          "items",
          payload
        );

        navigate(item._id, { replace: true });
      }
      enqueueSnackbar("Item has been updated", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Unable to save the item at this time. Try again later", {
        variant: "error",
      });
    }
  };
  return (
    <UselessPageCarded
      header={{
        title: itemId ? "Manage Item" : "Create Item",
        subtitle: "Compose your restaurant's item",
        left: (
          <Stack justifyContent="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
          </Stack>
        ),
        right: (
          <Stack justifyContent="center">
            <Button
              variant="contained"
              onClick={() => onSave()}
              disabled={
                state.name.trim().length === 0 ||
                state.price.value.toString().trim().length === 0 ||
                state.price.tax.toString().trim().length === 0
              }
            >
              Save
            </Button>
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <BasicInformation
          name={state.name}
          description={state.description}
          onChange={(event) =>
            setState({ ...state, [event.target.id]: event.target.value })
          }
        >
          <Autocomplete
            multiple
            freeSolo
            id="tags-standard"
            options={[]}
            value={state.tags}
            onChange={(event, value) =>
              setState({ ...state, tags: value as string[] })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Tags"
                placeholder="Add tags for this item..."
              />
            )}
          />
          <FormControlLabel
            control={<Switch checked={state.visible} />}
            label="Visible"
            onChange={(event, checked) =>
              setState({ ...state, visible: checked })
            }
          />
        </BasicInformation>
        <ImageSelector
          image={state.images.find((i) => (i._id && !i.deleted) || i.new)}
          onUpdateImage={(image) => {
            const images = state.images.filter((i) => i._id !== undefined);
            for (let i of images) {
              i.deleted = true;
            }
            images.unshift(image);
            setState({ ...state, images });
          }}
          onClearImage={() => {
            const images = state.images.filter((i) => i._id !== undefined);
            for (let i of images) {
              i.deleted = true;
            }

            setState({ ...state, images });
          }}
        />
        <PricingComponent
          price={state.price.value}
          tax={state.price.tax}
          onChange={(event) =>
            setState({
              ...state,
              price: { ...state.price, [event.target.id]: event.target.value },
            })
          }
        />
        <SingleAutoComplete
          title="Categories"
          subtitle="Select all the categories you'd like this item to be included in. If you can't find the category you desire, you have the option to create it on the Categories page. You can also set the sorting preferences on the Categories page."
          options={categories}
          selected={state.categories}
          label="Categories"
          onSelectedUpdate={(value) => {
            setState({ ...state, categories: value as Category[] });
          }}
        />
        <ModifierSelector
          selected={state.modifiers}
          onChange={(_mods) => {
            setState({ ...state, modifiers: _mods });
          }}
        />
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(ManageItemPage);
