import { Button, Stack, Switch, Typography } from "@mui/material";
import PeriodRow from "./PeriodRow";
import {
  generateTimeArrayOnlyTime,
  isValid12HourFormat,
} from "../../../defaults";
import { Day, HourPeriods } from "../../../helpers";

interface Props {
  day: Day;
  onDaysChange(day: Day): void;
}

const DayHours = ({ day, onDaysChange }: Props) => {
  const possibleHours = ["24 Hours", ...generateTimeArrayOnlyTime()];

  const validatePeriods = (day: Day): boolean => {
    if (day.open && day.periods.length > 0) {
      for (let period of day.periods) {
        if (period.open.time === "24 Hours") continue;
        if (
          !isValid12HourFormat(period.open.time) ||
          !isValid12HourFormat(period.close.time)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  return (
    <Stack sx={{ mb: 2 }}>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        alignItems={{
          xs: "center",
          sm: "unset",
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ width: { xs: "100%", sm: "unset" } }}
        >
          <Typography
            sx={{
              width: { xs: "100%", sm: 100 },
              lineHeight: "32px",
              fontWeight: "700",
            }}
          >
            {day.name}
          </Typography>
          <Switch
            checked={day.open}
            onChange={(event, checked) => {
              day.open = checked;
              onDaysChange(day);
            }}
          />
        </Stack>
        {day.open && (
          <Stack
            direction="column"
            spacing={1}
            sx={{ width: { xs: "100%", sm: "unset" } }}
          >
            {day.periods.map((period, index) => (
              <PeriodRow
                key={`${day.day}-period-${index}`}
                possibleHours={possibleHours}
                period={period}
                onChange={(value) => {
                  period = value;
                  onDaysChange(day);
                }}
                onRemove={() => {
                  day.periods.splice(index, 1);
                  if (day.periods.length === 0) {
                    day.open = false;
                  }
                  onDaysChange(day);
                }}
              />
            ))}
          </Stack>
        )}
        {day.open && validatePeriods(day) && (
          <Stack>
            <Button
              variant="text"
              sx={{ width: 128 }}
              onClick={() => {
                day.periods.push({
                  close: {
                    day: day.day,
                    time: "",
                  },
                  open: {
                    day: day.day,
                    time: "",
                  },
                });
                onDaysChange(day);
              }}
            >
              Add Hours
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DayHours;
