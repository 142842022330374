import {
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  BasicListingInfo,
  Menu as MenuInterface,
  RestaurantResponse,
} from "../../../../../helpers";
import RestaurantApi from "../../../../../services/api/RestaurantApi";
import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import UselessDefaultMotion from "../../../../../components/useless/UselessDefaultMotion";
import UselessEmptyHolder from "../../../../../components/useless/UselessEmptyHolder";
import ConfirmationDialog from "../../../../../components/admin/dialogs/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { cloneDeep } from "lodash";

interface Props {
  current?: BasicListingInfo;
}

interface MenuListItemProps {
  menu: MenuInterface;
  onEdit(): void;
  onDelete(): void;
}

const MenuListItem = ({ menu, onEdit, onDelete }: MenuListItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <UselessDefaultMotion type="item">
      <ListItem key={menu._id} divider>
        <ListItemText
          primary={menu.name}
          secondary={menu.description || "No description provided"}
        />
        <ListItemSecondaryAction>
          <Stack direction="row" spacing={1}>
            {menu.default && <Chip label="Default" color="primary" />}
            <Chip label={`${menu.categories.length} Categories`} />
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
              aria-controls={open ? "options-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreHoriz />
            </IconButton>
          </Stack>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-menu-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            onDelete();
            handleClose();
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete </Typography>
        </MenuItem>
      </Menu>
    </UselessDefaultMotion>
  );
};

const MenusPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const [sp, setSP] = useSearchParams();
  const [page, setPage] = useState(Number(sp.get("page")));
  const [limit, setLimit] = useState(Number(sp.get("limit")));

  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    selected?: MenuInterface;
  }>({
    open: false,
  });

  const [menus, setMenus] = useState<RestaurantResponse>();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSP(
      {
        page: newPage.toString(),
        limit: limit.toString(),
      },
      { replace: true }
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
    setSP(
      {
        page: "0",
        limit: event.target.value,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    async function fetch() {
      if (!current) return;
      const response = await RestaurantApi.getData({
        listingId: current._id,
        type: "menus",
        limit,
        page: page + 1,
      });

      setMenus(response);
    }

    fetch();
  }, [page, limit]);

  return (
    <Stack>
      <List>
        {menus?.data.map((menu) => (
          <MenuListItem
            key={menu._id}
            menu={menu as MenuInterface}
            onEdit={() => {
              navigate(`../../manage/menu/${menu._id}`);
            }}
            onDelete={() =>
              setDeleteDialog({
                open: true,
                selected: menu as MenuInterface,
              })
            }
          />
        ))}
        {menus?.data.length === 0 && (
          <UselessEmptyHolder
            parentContainerStyles={{ pl: 2, pr: 2, pb: 3, pt: 3 }}
            icon="RestaurantMenu"
            text="No Menu Found"
            description="Introduce a fresh menu selection to elevate your customers' experience, enabling them to easily place their food orders"
          />
        )}
      </List>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={menus?.metadata.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmationDialog
        open={deleteDialog.open}
        title={`Delete ${deleteDialog.selected?.name}`}
        description={`Are you sure you wish to delete ${deleteDialog.selected?.name}?`}
        handleClose={() =>
          setDeleteDialog({ open: false, selected: undefined })
        }
        handleSubmit={async () => {
          if (!current || !deleteDialog.selected) return;
          try {
            await RestaurantApi.deleteDataById(
              current._id,
              "menus",
              deleteDialog.selected._id
            );
            enqueueSnackbar({
              message: `${deleteDialog.selected.name} has been deleted.`,
              variant: "success",
            });

            const copy = cloneDeep(menus);
            if (copy) {
              copy.data = copy.data.filter(
                (c) => c._id !== deleteDialog.selected?._id
              );
              copy.metadata = {
                ...copy.metadata,
                count: copy.metadata.count - 1,
              };
              setMenus(copy);
            }

            setDeleteDialog({ ...deleteDialog, open: false });
          } catch (error) {
            enqueueSnackbar({
              message: `Something went wrong.`,
              variant: "error",
            });
          } finally {
            setTimeout(() => {
              setDeleteDialog({ open: false, selected: undefined });
            }, 100);
          }
        }}
        action={{
          acceptLabel: "Delete",
          rejectLabel: "Cancel",
        }}
      />
    </Stack>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(MenusPage);
