import { Stack, SxProps, Typography } from "@mui/material";

interface Props {
  name: string;
  value: string;
  subtext?: string;
  notes?: string;
  containerStyle?: SxProps;
  nameStyle?: SxProps;
  valueStyle?: SxProps;
}

const OrderLineItem = ({
  containerStyle,
  name,
  nameStyle,
  subtext,
  notes,
  value,
  valueStyle,
}: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      sx={{ ...containerStyle }}
    >
      <Stack spacing={0.5}>
        <Typography variant="body1" sx={{ ...nameStyle }} flex={1}>
          {name}
        </Typography>
        {subtext && (
          <Typography variant="caption" color="text.secondary" flex={1}>
            {subtext}
          </Typography>
        )}
        {notes && (
          <Typography variant="caption" color="text.secondary" flex={1}>
            Notes: {notes}
          </Typography>
        )}
      </Stack>
      <Typography
        variant="body1"
        sx={{ minWidth: 72, textAlign: "end", ...valueStyle }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default OrderLineItem;
