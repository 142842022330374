import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MouseEventHandler, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import {
  BasicListingInfo,
  RestaurantDataTypes,
  RestaurantDataTypesIndividual,
  RestaurantOptions,
} from "../../../helpers";
import { RootState } from "../../../redux/store";
import RestaurantApi from "../../../services/api/RestaurantApi";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";

interface SortableItemProps {
  value: RestaurantDataTypesIndividual;
  onDelete(value: RestaurantDataTypesIndividual): void;
}

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const SortableItem = SortableElement<SortableItemProps>(
  ({ value, onDelete }: SortableItemProps) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    return (
      <Chip
        key={value._id}
        label={value.name}
        sx={{ ml: 0.25, mr: 0.25, mb: 0.5 }}
        onMouseDown={onMouseDown}
        onDelete={() => onDelete(value)}
      />
    );
  }
);

interface SortableListProps {
  items: RestaurantDataTypes;
  onDelete(value: RestaurantDataTypesIndividual): void;
}

const SortableList = SortableContainer<SortableListProps>(
  ({ items, onDelete }: SortableListProps) => (
    <Box>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value._id}`}
          index={index}
          value={value}
          onDelete={onDelete}
        />
      ))}
    </Box>
  )
);

interface Props {
  current?: BasicListingInfo;
  title: string;
  subtitle: string;
  type: RestaurantOptions;
  selected: RestaurantDataTypes;
  onSelectedUpdate(value: RestaurantDataTypes): void;
}

const SortableAutoComplete = ({
  current,
  title,
  subtitle,
  type,
  selected,
  onSelectedUpdate,
}: Props) => {
  const [options, setOptions] = useState<RestaurantDataTypes>([]);

  const fetch = async (text?: string) => {
    if (!current) return;
    const data = await RestaurantApi.getData({
      limit: 100,
      listingId: current._id,
      page: 1,
      type,
      text,
    });

    setOptions(data.data as RestaurantDataTypes);
  };

  useEffect(() => {
    fetch();
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const arr = [...selected];
    const updated = arrayMove(arr, oldIndex, newIndex);
    onSelectedUpdate(updated as RestaurantDataTypes);
  };

  return (
    <Stack spacing={1}>
      <UselessSectionHeader title={title} subtitle={subtitle} />
      <UselessDefaultMotion type="item">
        <Autocomplete
          multiple
          id="option-selector"
          options={options}
          disableCloseOnSelect
          value={selected}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <Stack>
                <Typography variant="inherit">{option.name}</Typography>
              </Stack>
            </li>
          )}
          renderTags={(value) => (
            <SortableList
              items={value}
              axis="xy"
              onSortEnd={onSortEnd}
              distance={4}
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              onDelete={(_option) =>
                onSelectedUpdate(selected.filter((c) => c._id !== _option._id))
              }
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select..."
              // onChange={handleSearchDelayed}
            />
          )}
          onChange={(event, value) => {
            onSelectedUpdate(value as RestaurantDataTypes);
          }}
        />
      </UselessDefaultMotion>
    </Stack>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(SortableAutoComplete);
