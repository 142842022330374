import {
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Claim, Report } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import { MoreHoriz } from "@mui/icons-material";
import { useState } from "react";

interface Props {
  report: Report;
}

const ReportsListItem = ({ report }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <UselessDefaultMotion type="item">
      <ListItem>
        <ListItemText>
          <Typography variant="inherit" sx={{ fontWeight: "700" }}>
            {report.listing.name}
          </Typography>
          <Stack>
            <Typography variant="caption">
              Request By: {report.user.name}
            </Typography>
            <Typography variant="caption">
              Email: {report.user.email}
            </Typography>
            <Typography variant="caption">
              Phone: {report.listing.phone}
            </Typography>
          </Stack>
        </ListItemText>
        <ListItemSecondaryAction>
          <Chip
            sx={{ textTransform: "capitalize" }}
            label={report.status}
            color={report.status === "pending" ? "warning" : "success"}
          />
          <IconButton onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        id="reports-option-menu"
        aria-labelledby="reports-option-button"
        anchorEl={anchorEl}
        open={showMenu}
        onClose={handleClose}
      >
        {report.status !== "approved" && (
          <MenuItem
            dense
            onClick={() => {
              handleClose();
            }}
          >
            Approve Claim
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            handleClose();
          }}
        >
          {report.status === "pending" ? "Reject Claim" : "Revoke Claim"}
        </MenuItem>
        <Divider />
        <MenuItem
          dense
          onClick={() => {
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </UselessDefaultMotion>
  );
};

export default ReportsListItem;
