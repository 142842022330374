import { ChevronLeft } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicInformation from "../../../../../components/admin/restaurants/BasicInformation";
import SortableAutocomplete from "../../../../../components/admin/restaurants/SortableAutoComplete";
import UselessPageCarded from "../../../../../components/useless/UselessPageCarded";
import { BasicListingInfo, Category, SingleMenu } from "../../../../../helpers";
import RestaurantApi from "../../../../../services/api/RestaurantApi";
import { connect } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { enqueueSnackbar } from "notistack";

interface StateProps {
  name: string;
  description: string;
  categories: Category[];
  default: boolean;
}

interface Props {
  current?: BasicListingInfo;
}

const ManageMenuPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const { menuId } = useParams();

  const [state, setState] = useState<StateProps>({
    name: "",
    description: "",
    categories: [],
    default: true,
  });

  useEffect(() => {
    if (!current || !menuId) return;
    const fetch = async () => {
      const menu = (await RestaurantApi.getDataById(
        current._id,
        "menus",
        menuId
      )) as SingleMenu;

      setState({
        name: menu.name,
        description: menu.description,
        categories: menu.categories,
        default: menu.default,
      });
    };
    fetch();
  }, []);

  const onSave = async () => {
    try {
      if (!current) return;

      if (menuId) {
        await RestaurantApi.updateDataById(current._id, "menus", menuId, {
          name: state.name,
          description: state.description,
          default: state.default,
          categories: state.categories.map((c) => c._id),
        });
      } else {
        const menu = await RestaurantApi.createDataByType(
          current._id,
          "menus",
          {
            name: state.name,
            description: state.description,
            default: state.default,
            categories: state.categories.map((c) => c._id),
          }
        );

        navigate(menu._id, { replace: true });
      }
      enqueueSnackbar("Menu has been updated", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Unable to save the menu at this time. Try again later", {
        variant: "error",
      });
    }
  };
  return (
    <UselessPageCarded
      header={{
        title: menuId ? "Manage Menu" : "Create Menu",
        subtitle: "Compose your restaurant's menu",
        left: (
          <Stack justifyContent="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
          </Stack>
        ),
        right: (
          <Stack justifyContent="center">
            <Button
              variant="contained"
              onClick={() => onSave()}
              disabled={state.name.trim().length === 0}
            >
              Save
            </Button>
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <BasicInformation
          name={state.name}
          description={state.description}
          onChange={(event) =>
            setState({ ...state, [event.target.id]: event.target.value })
          }
        >
          <FormControlLabel
            control={<Switch checked={state.default} />}
            label="Default Menu"
            onChange={(event, checked) =>
              setState({ ...state, default: checked })
            }
          />
        </BasicInformation>
        <SortableAutocomplete
          title="Categories"
          subtitle="Pick and arrange your categories"
          type="categories"
          selected={state.categories}
          onSelectedUpdate={(value) =>
            setState({ ...state, categories: value as Category[] })
          }
        />
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(ManageMenuPage);
