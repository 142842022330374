import { Button, Stack } from "@mui/material";
import isEqual from "lodash/isEqual";
import {
  BasicListingInfo,
  Listing,
  ReduxSelectedListing,
  Session,
} from "../../../helpers";
import { Dispatch } from "redux";
import { RootState } from "../../../redux/store";
import {
  resetSelected,
  setSelectedListing,
} from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";
import ListingsApi from "../../../services/api/ListingsApi";
import { enqueueSnackbar } from "notistack";

interface Props {
  session?: Session;
  current?: BasicListingInfo;
  selected: ReduxSelectedListing;
  resetSelected(): void;
  setSelectedListing(listing: Listing): void;
}

const InfoPageActions = ({
  current,
  selected,
  session,
  resetSelected,
  setSelectedListing,
}: Props) => {
  const update = async () => {
    if (!selected.data || !session) return;

    const {
      kind,
      name,
      description,
      contact,
      location: { formatted_address },
      payments,
      category,
      owner,
      classes,
      details,
      halal,
      hours,
      denomination,
      images,
      services,
    } = selected.data;

    const request: any = {
      name,
      description,
      contact,
      location: formatted_address,
      kind,
    };

    if (kind === "Foods") {
      request.details = details;
      request.hours = hours.periods.map((p) => ({
        open: p.open,
        close: p.close,
      }));
      request.halal = {
        description: halal.description,
        offerings: halal.offerings,
        authentication: halal.authentication,
      };

      request.payments = payments;
    }

    if (kind === "Markets") {
      request.hours = hours.periods.map((p) => ({
        open: p.open,
        close: p.close,
      }));
      request.halal = {
        description: halal.description,
        offerings: halal.offerings,
        authentication: halal.authentication,
      };

      request.payments = payments;
    }

    if (kind === "Businesses") {
      request.hours = hours.periods.map((p) => ({
        open: p.open,
        close: p.close,
      }));
      request.owner = owner;
      request.category = category;
      request.payments = payments;
    }

    if (kind === "Mosques") {
      request.denomination = denomination;
      request.services = services;
    }

    if (kind === "Schools") {
      request.denomination = denomination;
      request.classes = classes;
    }

    for (let i = 0; i < images.length; ++i) {
      const img = images[i];
      if (img.new || !img._id) {
        await ListingsApi.addImageToListing(
          session._id,
          selected.data._id,
          img,
          i === 0
        );
      }

      if (img.deleted && img._id) {
        await ListingsApi.deleteImageFromListingById(
          session._id,
          selected.data._id,
          img._id
        );
      }
    }

    const update = await ListingsApi.updateListingById(
      session._id,
      selected.data._id,
      request
    );

    enqueueSnackbar({
      message: "Listing has been updated",
      variant: "success",
    });
    setSelectedListing({
      ...selected.data,
      ...update,
    });
  };

  return (
    <Stack>
      <Stack
        spacing={1}
        direction={{
          xs: "column",
          sm: "row",
        }}
      >
        <Button
          disabled={isEqual(selected.data, selected.unmodified)}
          onClick={() => resetSelected()}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          disabled={isEqual(selected.data, selected.unmodified)}
          onClick={() => update()}
        >
          Update
        </Button>
      </Stack>
    </Stack>
  );
};

const mapStateToProps = ({
  listings: { current, selected },
  session: { data },
}: RootState) => ({
  current,
  selected,
  session: data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetSelected: () => dispatch(resetSelected()),
  setSelectedListing: (listing: Listing) =>
    dispatch(setSelectedListing(listing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPageActions);
