import { combineReducers } from "redux";
import SessionReducer from "./SessionReducer";
import ListingsReducer from "./ListingsReducer";
import AppReducer from "./AppReducer";
import GlobalReducer from "./GlobalReducer";

export const rootReducer = combineReducers({
  app: AppReducer,
  global: GlobalReducer,
  session: SessionReducer,
  listings: ListingsReducer,
});
