import { styled } from "@mui/material";
import { WindowSize } from "../../../utils/WindowResize";
import { DEFAULT_DRAWER_OVERLAY_TRIGGER, DRAWER_WIDTH } from "../../../configs";

interface Props {
  open: boolean;
  size: WindowSize;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<Props>(({ theme, open, size }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft:
    size.width >= DEFAULT_DRAWER_OVERLAY_TRIGGER ? `-${DRAWER_WIDTH}px` : "0px",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default Main;
