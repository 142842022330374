import { HalalMetadata } from "../helpers";

export const Halal: HalalMetadata = {
  authentications: [
    {
      _id: "5ca2be47703cf3deb0501d5f",
      name: "Reported halal by our users but unverified",
    },
    {
      _id: "5ca2be47703cf3deb0501d61",
      name: "Staff has verbally confirmed halal status",
    },
    {
      _id: "5ca2be47703cf3deb0501d63",
      name: "Halal sign is visible on premises",
    },
    {
      _id: "5ca2be47703cf3deb0501d65",
      name: "Halal certificate is visible on premises",
    },
    {
      _id: "5ca2be47703cf3deb0501d67",
      name: "Reported to have a halal supplier",
    },
    {
      _id: "5ca2be47703cf3deb0501d69",
      name: "Authorized halal by islamic organization",
    },
    {
      _id: "5ca2be47703cf3deb0501d6b",
      name: "Authorized halal by government agency",
    },
  ],
  offerings: [
    {
      _id: "5ca2be51703cf3deb0501d6f",
      name: "Partial halal menu",
    },
    {
      _id: "5ca2be51703cf3deb0501d71",
      name: "Full halal menu",
    },
  ],
};

export const Facility = {
  details: [
    {
      _id: "5ca2bdfd703cf3deb0501d28",
      name: "Reservations",
      icon: "fas fa-book-open",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d2a",
      name: "Takeout",
      icon: "fas fa-drumstick-bite",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d2c",
      name: "Delivery",
      icon: "fas fa-truck",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d2e",
      name: "Shisha/Hookah",
      icon: "fas fa-bong",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d30",
      name: "Parking",
      icon: "fas fa-parking",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d32",
      name: "Child friendly",
      icon: "fas fa-child",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d34",
      name: "Restrooms",
      icon: "fas fa-restroom",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d36",
      name: "Private Party",
      icon: "fas fa-gifts",
    },
    {
      _id: "5ca2bdfd703cf3deb0501d38",
      name: "Alcohol",
      icon: "fas fa-cocktail",
    },
    {
      _id: "5ca9856d18609f22d25d4f6d",
      name: "Wheelchair accessible",
      icon: "fas fa-wheelchair",
    },
  ],
  categories: [
    {
      _id: "5ca2bdf4703cf3deb0501cf2",
      name: "Arts, crafts, and collectibles",
    },
    { _id: "5ca2bdf4703cf3deb0501cf4", name: "Baby" },
    {
      _id: "5ca2bdf4703cf3deb0501cf6",
      name: "Beauty and fragrances",
    },
    {
      _id: "5ca2bdf4703cf3deb0501cf8",
      name: "Books and magazines",
    },
    {
      _id: "5ca2bdf4703cf3deb0501cfa",
      name: "Business to business",
    },
    {
      _id: "5ca2bdf4703cf3deb0501cfc",
      name: "Clothing, accessories, and shoes",
    },
    {
      _id: "5ca2bdf4703cf3deb0501cfe",
      name: "Computers, accessories, and services",
    },
    { _id: "5ca2bdf4703cf3deb0501d00", name: "Education" },
    {
      _id: "5ca2bdf4703cf3deb0501d02",
      name: "Electronics and telecom",
    },
    {
      _id: "5ca2bdf4703cf3deb0501d04",
      name: "Entertainment and media",
    },
    {
      _id: "5ca2bdf4703cf3deb0501d06",
      name: "Financial services and products",
    },
    {
      _id: "5ca2bdf4703cf3deb0501d08",
      name: "Food retail and service",
    },
    {
      _id: "5ca2bdf4703cf3deb0501d0a",
      name: "Gifts and flowers",
    },
    { _id: "5ca2bdf4703cf3deb0501d0c", name: "Government" },
    {
      _id: "5ca2bdf4703cf3deb0501d0e",
      name: "Health and personal care",
    },
    {
      _id: "5ca2bdf4703cf3deb0501d10",
      name: "Home and garden",
    },
    { _id: "5ca2bdf4703cf3deb0501d13", name: "Nonprofit" },
    {
      _id: "5ca2bdf5703cf3deb0501d15",
      name: "Pets and animals",
    },
    {
      _id: "5ca2bdf5703cf3deb0501d17",
      name: "Religion and spirituality (for profit)",
    },
    {
      _id: "5ca2bdf5703cf3deb0501d19",
      name: "Retail (not elsewhere classified)",
    },
    {
      _id: "5ca2bdf5703cf3deb0501d1b",
      name: "Services - other",
    },
    {
      _id: "5ca2bdf5703cf3deb0501d1d",
      name: "Sports and outdoors",
    },
    {
      _id: "5ca2bdf5703cf3deb0501d1f",
      name: "Toys and hobbies",
    },
    { _id: "5ca2bdf5703cf3deb0501d21", name: "Travel" },
    {
      _id: "5ca2bdf5703cf3deb0501d23",
      name: "Vehicle sales",
    },
    {
      _id: "5ca2bdf5703cf3deb0501d25",
      name: "Vehicle service and accessories",
    },
  ],
  mosques: {
    services: [
      {
        _id: "5ca2be06703cf3deb0501d40",
        name: "Marital",
        icon: "fas fa-ring",
        facility: "mosques",
      },
      {
        _id: "5ca2be06703cf3deb0501d42",
        name: "Arabic Classes",
        icon: "fas fa-chalkboard-teacher",
        facility: "mosques",
      },
      {
        _id: "5ca2be06703cf3deb0501d44",
        name: "Counseling",
        icon: "fas fa-user-alt",
        facility: "mosques",
      },
      {
        _id: "5ca2be07703cf3deb0501d46",
        name: "Sunday School",
        icon: "fas fa-school",
        facility: "mosques",
      },
      {
        _id: "5ca2be07703cf3deb0501d48",
        name: "Youth Programs",
        icon: "fas fa-futbol",
        facility: "mosques",
      },
      {
        _id: "5ca2be07703cf3deb0501d4a",
        name: "Quran Classes",
        icon: "fas fa-quran",
        facility: "mosques",
      },
      {
        _id: "5ca2be07703cf3deb0501d4c",
        name: "Funeral Services",
        icon: "fas fa-mosque",
        facility: "mosques",
      },
    ],
    denominations: [
      {
        _id: "5ca2be65703cf3deb0501d95",
        name: "Sunni (Traditional)",
      },
      {
        _id: "5ca2be65703cf3deb0501d97",
        name: "Sunni (Salafi)",
      },
      {
        _id: "5ca2be65703cf3deb0501d99",
        name: "Shia (Jafari)",
      },
      {
        _id: "5ca2be65703cf3deb0501d9b",
        name: "Shia (Zaydi)",
      },
      {
        _id: "5ca2be65703cf3deb0501d9d",
        name: "Shia (Ismaili)",
      },
      {
        _id: "5ca2be65703cf3deb0501d9f",
        name: "Shia (Bohra Ismaili)",
      },
      { _id: "5ca2be65703cf3deb0501da1", name: "Sufi" },
      {
        _id: "5ca2be65703cf3deb0501da3",
        name: "Non - Denomination",
      },
    ],
  },
  schools: {
    grades: [
      {
        _id: "5ca2be6e703cf3deb0501da7",
        name: "Preschool",
        order: 0,
      },
      {
        _id: "5ca2be6f703cf3deb0501da9",
        name: "Kindergarten",
        order: 1,
      },
      {
        _id: "5ca2be6f703cf3deb0501dab",
        name: "First grade",
        order: 2,
      },
      {
        _id: "5ca2be6f703cf3deb0501dad",
        name: "Second grade",
        order: 3,
      },
      {
        _id: "5ca2be6f703cf3deb0501daf",
        name: "Third grade",
        order: 4,
      },
      {
        _id: "5ca2be6f703cf3deb0501db1",
        name: "Fourth grade",
        order: 5,
      },
      {
        _id: "5ca2be6f703cf3deb0501db3",
        name: "Fifth grade",
        order: 6,
      },
      {
        _id: "5ca2be6f703cf3deb0501db5",
        name: "Sixth grade",
        order: 7,
      },
      {
        _id: "5ca2be6f703cf3deb0501db7",
        name: "Seventh grade",
        order: 8,
      },
      {
        _id: "5ca2be6f703cf3deb0501db9",
        name: "Eighth grade",
        order: 9,
      },
      {
        _id: "5ca2be6f703cf3deb0501dbb",
        name: "Ninth grade",
        order: 10,
      },
      {
        _id: "5ca2be6f703cf3deb0501dbd",
        name: "Tenth grade",
        order: 11,
      },
      {
        _id: "5ca2be6f703cf3deb0501dbf",
        name: "Eleventh grade",
        order: 12,
      },
      {
        _id: "5ca2be6f703cf3deb0501dc1",
        name: "Twelvth grade",
        order: 13,
      },
      {
        _id: "5ca2be6f703cf3deb0501dc3",
        name: "Post secondary education",
        order: 14,
      },
      {
        _id: "5ca2be6f703cf3deb0501dc5",
        name: "Tertiary education",
        order: 15,
      },
    ],
  },
};

export const Payments = [
  {
    _id: "5ca2be5b703cf3deb0501d76",
    name: "Cash",
    icon: "far fa-money-bill-alt",
  },
  {
    _id: "5ca2be5b703cf3deb0501d78",
    name: "Visa",
    icon: "fab fa-cc-visa",
  },
  {
    _id: "5ca2be5b703cf3deb0501d7a",
    name: "Mastercard",
    icon: "fab fa-cc-mastercard",
  },
  {
    _id: "5ca2be5b703cf3deb0501d7c",
    name: "American Express",
    icon: "fab fa-cc-amex",
  },
  {
    _id: "5ca2be5b703cf3deb0501d7e",
    name: "Discover",
    icon: "fab fa-cc-discover",
  },
  {
    _id: "5ca2be5b703cf3deb0501d80",
    name: "Debit Card",
    icon: "far fa-credit-card",
  },
  {
    _id: "5ca2be5b703cf3deb0501d82",
    name: "Paypal",
    icon: "fab fa-paypal",
  },
  {
    _id: "5ca2be5b703cf3deb0501d84",
    name: "Check",
    icon: "fas fa-money-check",
  },
  {
    _id: "5ca2be5b703cf3deb0501d86",
    name: "Google Pay",
    icon: "fab fa-google",
  },
  {
    _id: "5ca2be5b703cf3deb0501d88",
    name: "Apple Pay",
    icon: "fab fa-apple-pay",
  },
];

export const Types = [
  {
    name: "Foods",
    icon: "fas fa-utensils",
    singular: "Food",
    description: "Places to eat",
    visible: { mobile: true },
  },
  {
    name: "Markets",
    icon: "fas fa-shopping-cart",
    singular: "Market",
    description: "Places to shop",
    visible: { mobile: true },
  },
  {
    name: "Businesses",
    icon: "fas fa-building",
    singular: "Business",
    description: "Muslim owned businesses",
    visible: { mobile: false },
  },
  {
    name: "Mosques",
    icon: "fas fa-mosque",
    singular: "Mosque",
    description: "Places to pray",
    visible: { mobile: true },
  },
  {
    name: "Schools",
    icon: "fas fa-school",
    singular: "School",
    description: "Islamic schools",
    visible: { mobile: true },
  },
];

export const Reports = [
  {
    _id: "5cc37a06a349434133e5dfe8",
    name: "Permanently closed",
  },
  {
    _id: "5cc37a06a349434133e5dfea",
    name: "Incorrect halal status",
  },
  {
    _id: "5cc37a06a349434133e5dfec",
    name: "Inconsistent data",
  },
  {
    _id: "5cc37a06a349434133e5dfee",
    name: "Missing Information",
  },
  { _id: "5cc37a06a349434133e5dff0", name: "Other" },
];

export const Advertisements = [
  {
    _id: "5cca3799a349434133e6927d",
    name: "7 days",
    description: "Feature your listing for 7 days",
    price: 4.99,
    days: 7,
  },
  {
    _id: "5cca3799a349434133e6927f",
    name: "15 days",
    description: "Feature your listing for 15 days",
    price: 9.98,
    days: 15,
  },
  {
    _id: "5cca3799a349434133e69281",
    name: "31 days",
    description: "Feature your listing for 31 days",
    price: 14.97,
    days: 31,
  },
];
