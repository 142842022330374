import { Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  subtitle?: string;
}
const NavGroup = ({ title, subtitle }: Props) => {
  return (
    <Stack sx={{ pl: 0.5, pr: 0.5 }}>
      <Typography
        variant="caption"
        sx={(theme) => ({
          fontWeight: "700",
          textTransform: "uppercase",
          color: theme.palette.primary.light,
        })}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          sx={{ color: "#5b5b5b", fontWeight: "600" }}
          variant="caption"
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};

export default NavGroup;
