import { Stack, TextField } from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import { ChangeEvent, ReactNode } from "react";

interface Props {
  name: string;
  description: string;
  children?: ReactNode;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const BasicInformation = ({ name, description, onChange, children }: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Basic Information"
        subtitle="This information is public"
      />
      <UselessDefaultMotion type="item">
        <Stack spacing={2}>
          <TextField
            id="name"
            label="Name"
            fullWidth
            required
            variant="standard"
            value={name}
            onChange={onChange}
          />
          <TextField
            id="description"
            label="Description"
            fullWidth
            variant="standard"
            multiline
            minRows={3}
            value={description}
            onChange={onChange}
          />
          {children}
        </Stack>
      </UselessDefaultMotion>
    </Stack>
  );
};

export default BasicInformation;
