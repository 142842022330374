import { Theme, createTheme } from "@mui/material";
import DialogTransition from "../components/admin/core/DialogTransition";

const font = "'Mulish', sans-serif";

export const lightPaletteText = {
  primary: "rgb(17, 24, 39)",
  secondary: "rgb(107, 114, 128)",
  disabled: "rgb(149, 156, 169)",
};

export const darkPaletteText = {
  primary: "rgb(255,255,255)",
  secondary: "rgb(148, 163, 184)",
  disabled: "rgb(156, 163, 175)",
};

export const lightTheme: Theme = createTheme({
  shape: {
    borderRadius: 16,
  },
  palette: {
    mode: "light",
    divider: "#e2e8f0",
    text: lightPaletteText,
    common: {
      black: "rgb(17, 24, 39)",
      white: "rgb(255, 255, 255)",
    },
    primary: {
      light: "#096d2d",
      main: "#096d2d",
      dark: "#81da8c",
      contrastText: darkPaletteText.primary,
    },
    secondary: {
      light: "#516351",
      main: "#516351",
      dark: "#b8ccb5",
      contrastText: darkPaletteText.primary,
    },
    background: {
      paper: "#FFFFFF",
      default: "#f0f7f7",
    },
    error: {
      light: "#ffcdd2",
      main: "#f44336",
      dark: "#b71c1c",
    },
  },
  typography: {
    fontFamily: font,
  },
  components: {
    MuiDialog: {
      defaultProps: {
        scroll: "body",
        TransitionComponent: DialogTransition,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
        disableGutters: true,
      },
    },
  },
});
