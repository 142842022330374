import { configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import { rootReducer } from "./reducers";

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["session", "listings", "app"],
  blacklist: [],
};
const logger = createLogger({
  collapsed: true,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export { persistor };

export default store;
