import { CssBaseline, LinearProgress, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/Router";
import { lightTheme } from "./configs";
import { SnackbarProvider } from "notistack";
import { connect } from "react-redux";
import { RootState } from "./redux/store";

interface Props {
  topbarLoading: boolean;
}

function App({ topbarLoading }: Props) {
  return (
    <ThemeProvider theme={lightTheme}>
      <LinearProgress
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          visibility: topbarLoading ? "visible" : "hidden",
        }}
      />
      <CssBaseline />
      <BrowserRouter>
        <Router />
        <SnackbarProvider
          style={{ borderRadius: 24 }}
          maxSnack={5}
          autoHideDuration={2000}
          disableWindowBlurListener={true}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ global: { topbarLoading } }: RootState) => ({
  topbarLoading,
});
export default connect(mapStateToProps)(App);
