import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { useEffect, useState } from "react";
import {
  BasicListingInfo,
  Modifier,
  RestaurantResponse,
} from "../../../helpers";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import RestaurantApi from "../../../services/api/RestaurantApi";
import { Close, DragHandle as DragHandleIcon } from "@mui/icons-material";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";

interface Props {
  current?: BasicListingInfo;
  selected: Modifier[];
  onChange(modifiers: Modifier[]): void;
}

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

interface SortableItemProps {
  modifier: Modifier;
  onDelete(): void;
}

const SortableItem = SortableElement<SortableItemProps>(
  ({ modifier, onDelete }: SortableItemProps) => (
    <ListItem disableGutters divider style={{ listStyle: "none" }}>
      <DragHandle />
      <ListItemText>
        <Typography variant="inherit">{modifier.name}</Typography>
        <Stack
          direction="row"
          display="flex"
          mt={0.5}
          spacing={0.5}
          useFlexGap
          flexWrap="wrap"
        >
          {modifier.options.map((mo) => (
            <Chip size="small" label={mo.name} key={mo._id} />
          ))}
        </Stack>
      </ListItemText>
      <ListItemSecondaryAction className="no-drag">
        <IconButton onClick={() => onDelete()}>
          <Close />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
);

interface SortableListProps {
  modifiers: Modifier[];
  onDelete(modifier: Modifier): void;
}

const SortableList = SortableContainer<SortableListProps>(
  ({ modifiers, onDelete }: SortableListProps) => {
    return (
      <List>
        {modifiers.map((modifier, index) => (
          <SortableItem
            key={`item-${modifier._id}`}
            index={index}
            modifier={modifier}
            onDelete={() => onDelete(modifier)}
          />
        ))}
      </List>
    );
  }
);

const ModifierSelector = ({ current, selected, onChange }: Props) => {
  const [options, setOptions] = useState<Modifier[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const arr = arrayMove(selected, oldIndex, newIndex);
    onChange(arr);
  };

  const onDelete = (modifier: Modifier) => {
    const arr = selected.filter((s) => s._id !== modifier._id);
    onChange(arr);
  };

  useEffect(() => {
    if (!current) return;
    const fetch = async () => {
      const modifiers = await RestaurantApi.getData({
        listingId: current._id,
        type: "modifiers",
        page: 1,
        limit: 999,
      });
      if (modifiers.data.length > 0) {
        setOptions(modifiers.data as Modifier[]);
      }
    };

    fetch();
  }, []);

  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Modifiers"
        subtitle="Enhance your item but providing modifiers"
      />
      <UselessDefaultMotion type="item">
        <Autocomplete
          id="modifier-options"
          options={options}
          renderInput={(params) => <TextField {...params} label="Modifiers" />}
          disablePortal
          disableCloseOnSelect
          clearOnBlur={true}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(modifier) =>
            selected.findIndex((s) => s._id === modifier._id) !== -1
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          value={null}
          renderOption={(props, option) => (
            <ListItem {...props} key={option._id}>
              <ListItemText>
                <Typography variant="inherit">{option.name}</Typography>
                <Stack
                  direction="row"
                  display="flex"
                  mt={0.5}
                  spacing={0.5}
                  useFlexGap
                  flexWrap="wrap"
                >
                  {option.options.map((mo) => (
                    <Chip size="small" label={mo.name} key={mo._id} />
                  ))}
                </Stack>
              </ListItemText>
            </ListItem>
          )}
          onChange={(event, value) => {
            event.preventDefault();
            if (event?.type === "click") setInputValue("");
            if (value) {
              onChange([...selected, value]);
            }
          }}
        />
        <SortableList
          modifiers={selected}
          onSortEnd={onSortEnd}
          onDelete={onDelete}
          useDragHandle
        />
        {selected.length === 0 && (
          <UselessEmptyHolder
            icon="List"
            text="No modifiers selected"
            description="Choose from Available Modifiers for This Item"
          />
        )}
      </UselessDefaultMotion>
    </Stack>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(ModifierSelector);
