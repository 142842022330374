import { Box, Paper, Stack, SxProps, Typography } from "@mui/material";
import PerfectScrollBar from "../admin/core/PerfectScrollBar";
import { motion } from "framer-motion";
import UselessDefaultMotion from "./UselessDefaultMotion";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  right?: React.ReactNode;
  left?: React.ReactNode;
  style?: SxProps;
}

const PageHeader = ({
  title,
  subtitle,
  children,
  right,
  left,
  style,
}: PageHeaderProps) => {
  return (
    <Box
      sx={{
        pr: 5,
        pl: 5,
        pt: 4,
        pb: 4,
        bgcolor: "#fff",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "rgb(226, 232, 240)",
        ...style,
      }}
    >
      <UselessDefaultMotion type="container">
        <Stack
          justifyContent="space-between"
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            {left}
            <Stack direction="column">
              <UselessDefaultMotion type="item">
                <Typography
                  sx={{ fontWeight: "800", fontSize: 32, lineHeight: 1.15 }}
                >
                  {title}
                </Typography>
              </UselessDefaultMotion>
              <UselessDefaultMotion type="item">
                {subtitle && (
                  <Typography
                    sx={{ fontWeight: "700", fontSize: 14, wordSpacing: -1 }}
                    color="text.secondary"
                  >
                    {subtitle}
                  </Typography>
                )}
              </UselessDefaultMotion>
            </Stack>
          </Stack>
          {right}
        </Stack>
      </UselessDefaultMotion>
      {children}
    </Box>
  );
};

interface UselessPageCardedProps {
  children: React.ReactNode;
  header: PageHeaderProps;
  containerStyles?: SxProps;
}

const UselessPageCarded = (props: UselessPageCardedProps) => {
  const { header, children, containerStyles } = props;
  return (
    <PerfectScrollBar>
      <PageHeader {...header} />
      <UselessDefaultMotion type="container">
        <Box sx={{ pt: 3, pb: 3, ...containerStyles }}>{children}</Box>
      </UselessDefaultMotion>
    </PerfectScrollBar>
  );
};

export default UselessPageCarded;
