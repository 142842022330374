import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  days as DefaultDays,
  convertTextTo12HourFormat,
  isValid12HourFormat,
} from "../../../defaults";
import { Day, Hours, Period } from "../../../helpers";
import useWindowSize from "../../../utils/WindowResize";
import DayHours from "./DayHours";
// @ts-ignore
import ListingHour from "listing-hours";
import moment from "moment";

interface Props {
  title?: string;
  open: boolean;
  hours: Hours;
  onClose(): void;
  onUpdate(hours: Hours): void;
}

const HoursDialog = (props: Props) => {
  const [days, setDays] = useState<Day[]>(DefaultDays);
  const isMobile = useWindowSize();

  const validatePeriods = (): boolean => {
    for (let day of days) {
      if (day.open && day.periods.length > 0) {
        const check = day.periods.filter((day) => {
          if (day.open.time === "24 Hours") return;
          return (
            !isValid12HourFormat(day.open.time) ||
            !isValid12HourFormat(day.close.time)
          );
        });
        if (check.length > 0) {
          return false;
        }
      }
    }

    return true;
  };

  useEffect(() => {
    if (!props.open) return;
    const periods = props.hours.periods;
    for (let day of days) {
      const filtered = periods.filter((p) => p.open.day === day.day);
      if (filtered.length !== 0) {
        day.periods = filtered.map((f) => {
          let openTime = convertTextTo12HourFormat(f.open.time);
          let closeTime = convertTextTo12HourFormat(f.close.time);

          if (openTime === closeTime) {
            openTime = "24 Hours";
            closeTime = "";
          }
          return {
            open: {
              ...f.open,
              time: openTime,
            },
            close: {
              ...f.close,
              time: closeTime,
            },
          };
        });
        day.open = true;
      }
    }
    setDays(days);
  }, [props.open]);

  return (
    <Dialog
      onClose={() => props.onClose()}
      open={props.open}
      maxWidth="md"
      fullWidth={isMobile.width < 600}
    >
      <DialogTitle sx={{ fontWeight: "700" }}>
        {props.title || "Set Hours"}
      </DialogTitle>
      <DialogContent>
        {days.map((day, index) => (
          <DayHours
            key={day.day}
            day={day}
            onDaysChange={(_day) => {
              if (index !== -1) {
                const periods = _day.periods;
                if (periods.length === 0 && _day.open) {
                  periods.push({
                    close: {
                      day: day.day,
                      time: "",
                    },
                    open: {
                      day: day.day,
                      time: "",
                    },
                  });
                }
                days[index] = {
                  ..._day,
                  open: _day.open,
                  periods: [...periods],
                };
                setDays([...days]);
              }
            }}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Button
          disabled={!validatePeriods()}
          onClick={() => {
            const periods: Period[] = [];
            for (let day of days) {
              if (!day.open) continue;
              let ip: Period[] = [];
              for (let period of day.periods) {
                const _period = {
                  open: { ...period.open },
                  close: { ...period.close },
                };

                if (_period.open.time === "24 Hours") {
                  _period.open.time = "0000";
                  _period.close.time = "0000";
                }
                const o = moment(_period.open.time, "hh:mm A");
                const c = moment(_period.close.time, "hh:mm A");

                if (o < c) {
                  ip.push({
                    open: {
                      time: o.format("HHmm"),
                      day: day.day,
                    },
                    close: {
                      time: c.format("HHmm"),
                      day: day.day,
                    },
                  });
                } else if (o > c) {
                  ip.push({
                    open: {
                      time: o.format("HHmm"),
                      day: day.day,
                    },
                    close: {
                      time: c.format("HHmm"),
                      day: day.day === 6 ? 0 : day.day + 1,
                    },
                  });
                } else if (o.isSame(c)) {
                  ip = [];
                  ip.push({
                    open: {
                      time: "0000",
                      day: day.day,
                    },
                    close: {
                      time: "0000",
                      day: day.day === 6 ? 0 : day.day + 1,
                    },
                  });
                  break;
                }
              }
              periods.push(...ip);
            }
            const lh = new ListingHour(periods);
            const hours = {
              periods,
              text: lh.getHumanReadable(),
            };
            props.onUpdate(hours);
            props.onClose();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HoursDialog;
