import { Cancel, ChevronLeft } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InformationLine from "../../../components/admin/core/InformationLine";
import ConfirmationDialog from "../../../components/admin/dialogs/ConfirmationDialog";
import OrderDetails from "../../../components/admin/orders/OrderDetails";
import OrderTracking from "../../../components/admin/orders/OrderTracking";
import OrderUpdateTracking from "../../../components/admin/orders/OrderUpdateTracking";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import UselessSectionHeader from "../../../components/useless/UselessSectionHeader";
import { BasicListingInfo } from "../../../helpers";
import { OrderStatus, SingleOrder } from "../../../helpers/Orders";
import { RootState } from "../../../redux/store";
import OrderApi from "../../../services/api/OrderApi";

interface Props {
  current?: BasicListingInfo;
}

const OrderDetailsPage = ({ current }: Props) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState<SingleOrder>();
  const [confirmCancel, setConfirmCancel] = useState({
    open: false,
    loading: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      if (!current || !orderId) return;
      const order = await OrderApi.getOrdersById(current._id, orderId);
      order.nextTracking = order.tracking.find((t) => t.status === 0);
      setOrder(order);
    }

    fetch();
  }, []);

  const updateStatus = async (status: OrderStatus) => {
    if (!current || !order) return;
    setLoading(true);

    try {
      const update = await OrderApi.updateOrderStatus(
        current._id,
        order?._id,
        status
      );

      update.nextTracking = update.tracking.find((t) => t.status === 0);
      setOrder(update);
    } catch (e) {
      enqueueSnackbar({
        message: "Unable to update order status. Please try again later.",
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const cancelOrder = async () => {
    if (!current || !orderId) return;
    try {
      setConfirmCancel({
        ...confirmCancel,
        loading: true,
      });
      const order = await OrderApi.cancelOrder(current._id, orderId);
      setOrder(order);
      enqueueSnackbar({
        message:
          "Your request to cancel the order has been successfully processed",
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar({
        message:
          "Unable to cancel order. Contact support for assistance if needed.",
        variant: "error",
      });
    } finally {
      setConfirmCancel({
        open: false,
        loading: false,
      });
    }
  };

  const navigate = useNavigate();
  return (
    <UselessPageCarded
      header={{
        title: "Order Details",
        subtitle: "Information Regarding the Placed Order",
        left: (
          <Stack justifyContent="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
          </Stack>
        ),
        right: order?.currentTracking.status === 1 && (
          <Stack
            onClick={() =>
              setConfirmCancel({
                open: true,
                loading: false,
              })
            }
          >
            <Button startIcon={<Cancel />}>Cancel Order</Button>
          </Stack>
        ),
      }}
      containerStyles={{ p: 0 }}
    >
      <Stack
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        sx={{ height: "100%" }}
      >
        <Grid container>
          <Grid item xs={12} sm={6} p={2}>
            <Stack spacing={2} divider={<Divider />} sx={{ width: "100%" }}>
              <UselessDefaultMotion type="item">
                <UselessSectionHeader
                  title="Customer"
                  subtitle="Essential Contact Information"
                />
                <InformationLine title="Name" value={order?.user.name ?? ""} />
                <InformationLine
                  title="Phone"
                  value={order?.user.phone ?? ""}
                />
              </UselessDefaultMotion>
              <UselessDefaultMotion type="item">
                <UselessSectionHeader
                  title="Payment"
                  subtitle="Essential Payment Information"
                />
                <InformationLine
                  title="Card"
                  value={`${order?.payment.card.brand} ending in ${order?.payment.card.last4}`}
                />
                <InformationLine
                  title="Expiration"
                  value={`${order?.payment.card.expMonth}/${order?.payment.card.expYear}`}
                />
                <InformationLine
                  title="Status"
                  value={order?.payment.status ?? ""}
                  valueProps={{
                    textTransform: "capitalize",
                  }}
                />
                <InformationLine
                  title="Amount"
                  value={`$${(order?.charges.total ?? 0) / 100}`}
                />
              </UselessDefaultMotion>
              <OrderTracking trackings={order?.tracking} />
              {order?.nextTracking &&
                order.nextTracking.management === "restaurant" && (
                  <OrderUpdateTracking
                    trackings={order?.tracking}
                    loading={loading}
                    currentTracking={order.currentTracking}
                    nextTracking={order.nextTracking}
                    onStatusUpdate={updateStatus}
                  />
                )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Stack spacing={2} divider={<Divider />}>
                <OrderDetails
                  cart={order?.cart}
                  charges={order?.charges}
                  receiptUrl={order?.receiptUrl}
                />
              </Stack>
              <UselessDefaultMotion type="item">
                <Stack alignItems={{ xs: "center", sm: "flex-end" }}>
                  <Typography variant="caption" color="text.secondary">
                    Placed on: {moment(order?.createdAt).format("llll")}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Reference: {order?._id}
                  </Typography>
                </Stack>
              </UselessDefaultMotion>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <ConfirmationDialog
        title="Cancel Order"
        description="Are you certain you want to proceed with canceling this order? This action is irreversible."
        action={{
          rejectLabel: "Dismiss",
          acceptLabel: "Cancel Order",
        }}
        handleClose={() =>
          setConfirmCancel({
            open: false,
            loading: false,
          })
        }
        handleSubmit={cancelOrder}
        loading={confirmCancel.loading}
        open={confirmCancel.open}
      />
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(OrderDetailsPage);
