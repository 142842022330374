import { Stack, SxProps, Typography } from "@mui/material";
import * as MuiIcons from "@mui/icons-material";

interface Props {
  parentContainerStyles?: SxProps;
  icon: string;
  iconStyles?: SxProps;
  text: string;
  textStyles?: SxProps;
  description?: string;
  descriptionStyles?: SxProps;
}

const UselessEmptyHolder = (props: Props) => {
  const IconComponent = MuiIcons[props.icon as keyof typeof MuiIcons];
  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...props.parentContainerStyles,
      }}
      spacing={1}
    >
      <IconComponent color="disabled" sx={{ ...props.iconStyles }} />
      <Stack alignItems="center" justifyContent="center">
        <Typography color="GrayText" sx={{ ...props.textStyles }}>
          {props.text}
        </Typography>
        {props.description && (
          <Typography
            variant="caption"
            color="GrayText"
            sx={{ ...props.descriptionStyles }}
          >
            {props.description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default UselessEmptyHolder;
