import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import UselessDefaultMotion from "./UselessDefaultMotion";
import { ReactNode } from "react";

interface Props {
  title: string;
  subtitle: string;
  action?: ReactNode;
}

const UselessSectionHeader = ({ title, subtitle, action }: Props) => {
  return (
    <UselessDefaultMotion type="item">
      <Stack direction={{ xs: "column", sm: "row" }} spacing={1} mb={1}>
        <Stack flex={1}>
          <Typography fontWeight={700} sx={{ fontSize: 20 }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: 12, color: "GrayText" }}>
            {subtitle}
          </Typography>
        </Stack>
        {action && <Stack>{action}</Stack>}
      </Stack>
    </UselessDefaultMotion>
  );
};

export default UselessSectionHeader;
