import { Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "../pages/AdminPage";
import AuthPage from "../pages/AuthPage";
import MerchantCompletePage from "../pages/MerchantCompletePage";
import PrivacyPage from "../pages/PrivacyPage";
import TermsPage from "../pages/TermsPage";
import NoOrganizationPage from "../pages/admin/NoOrganizationPage";
import Error404Page from "../pages/admin/general/Error404Page";
import Error500Page from "../pages/admin/general/Error500Page";
import GlobalClaimsPage from "../pages/admin/global/GlobalClaimsPage";
import GlobalListingsPage from "../pages/admin/global/GlobalListingsPage";
import ManageUserPage from "../pages/admin/global/GlobalManageUserPage";
import GlobalPage from "../pages/admin/global/GlobalPage";
import GlobalReportsPage from "../pages/admin/global/GlobalReportsPage";
import GlobalUsersPage from "../pages/admin/global/GlobalUsersPage";
import ConfigurationsPage from "../pages/admin/local/ConfigurationsPage";
import InformationPage from "../pages/admin/local/InformationPage";
import LocalPage from "../pages/admin/local/LocalPage";
import MerchantsDashboardPage from "../pages/admin/local/MerchantsDashboardPage";
import OrderDetailsPage from "../pages/admin/local/OrderDetailsPage";
import OrderSettingsPage from "../pages/admin/local/OrderSettingsPage";
import OrdersPage from "../pages/admin/local/OrdersPage";
import RestaurantManagementPage from "../pages/admin/local/RestaurantManagementPage";
import ManageCategoryPage from "../pages/admin/local/restaurant-manager/manage/ManageCategoryPage";
import ManageItemPage from "../pages/admin/local/restaurant-manager/manage/ManageItemPage";
import ManageMenuPage from "../pages/admin/local/restaurant-manager/manage/ManageMenuPage";
import ManageModifierPage from "../pages/admin/local/restaurant-manager/manage/ManageModifierPage";
import CategoriesPage from "../pages/admin/local/restaurant-manager/views/CategoriesPage";
import ItemsPage from "../pages/admin/local/restaurant-manager/views/ItemsPage";
import MenusPage from "../pages/admin/local/restaurant-manager/views/MenusPage";
import ModifiersPage from "../pages/admin/local/restaurant-manager/views/ModifiersPage";
import MerchantPage from "../pages/admin/merchant/MerchantPage";
import MerchantSignUpPage from "../pages/admin/merchant/MerchantSignUpPage";
import LoginPage from "../pages/auth/LoginPage";
import RegisterPage from "../pages/auth/RegisterPage";
import SignoutPage from "../pages/auth/SignoutPage";
import HealthPage from "../pages/HealthPage";

const Router = () => {
  return (
    <Routes>
      <Route path="health" element={<HealthPage />} />
      <Route path="/" element={<Navigate to="/auth/login" />} />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="terms" element={<TermsPage />} />
      <Route path="auth" element={<AuthPage />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="admin" element={<AdminPage />}>
        <Route path="local" element={<LocalPage />}>
          <Route path=":listingId">
            <Route path="info" element={<InformationPage />} />
            <Route path="restaurant-manager">
              <Route path="view" element={<RestaurantManagementPage />}>
                <Route path="menus" element={<MenusPage />} />
                <Route path="categories" element={<CategoriesPage />} />
                <Route path="items" element={<ItemsPage />} />
                <Route path="modifiers" element={<ModifiersPage />} />
              </Route>
              <Route path="manage">
                <Route path="menu/:menuId?" element={<ManageMenuPage />} />
                <Route
                  path="category/:categoryId?"
                  element={<ManageCategoryPage />}
                />
                <Route path="item/:itemId?" element={<ManageItemPage />} />
                <Route
                  path="modifier/:modifierId?"
                  element={<ManageModifierPage />}
                />
              </Route>
              <Route
                path=""
                element={<Navigate to="view/menus?page=0&limit=25" replace />}
              />
            </Route>
            <Route path="orders">
              <Route path=":orderId" element={<OrderDetailsPage />} />
              <Route path="" element={<OrdersPage />} />
            </Route>
            <Route path="order-settings" element={<OrderSettingsPage />} />
            <Route path="configurations" element={<ConfigurationsPage />} />
            <Route path="merchant" element={<MerchantPage />}>
              <Route path="sign-up" element={<MerchantSignUpPage />} />
              <Route path="dashboard" element={<MerchantsDashboardPage />} />
            </Route>
            <Route path="" element={<Navigate to="info" />} />
          </Route>
        </Route>

        <Route path="global" element={<GlobalPage />}>
          <Route path="listings" element={<GlobalListingsPage />} />
          <Route path="users">
            <Route path="manage">
              <Route path=":userId" element={<ManageUserPage />} />
              <Route path="" element={<ManageUserPage />} />
            </Route>
            <Route path="" element={<GlobalUsersPage />} />
          </Route>
          <Route path="claims" element={<GlobalClaimsPage />} />
          <Route path="reports" element={<GlobalReportsPage />} />
        </Route>
        <Route path="" element={<NoOrganizationPage />} />
        <Route path="404" element={<Error404Page />} />
        <Route path="500" element={<Error500Page />} />
        <Route path="*" element={<Navigate to="404" replace={true} />} />
      </Route>
      <Route path="merchant">
        <Route path="complete" element={<MerchantCompletePage />} />
      </Route>
      <Route path="signout" element={<SignoutPage />} />
    </Routes>
  );
};

export default Router;
