import { Box, Typography } from "@mui/material";

const HealthPage = () => {
  return (
    <Box>
      <Typography>NM Admin</Typography>
      <Typography>Status: Up</Typography>
    </Box>
  );
};

export default HealthPage;
