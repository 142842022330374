import { createSlice } from "@reduxjs/toolkit";
import { NavItem } from "../../components/admin/drawer/Routes";

interface AppSliceProps {
    drawerIsOpen: boolean;
    drawerRoutes: NavItem[];
    showNewOrganizationDialog: boolean;
}

export const appReducer = createSlice({
    name: "app",
    initialState: {
        drawerIsOpen: true,
        drawerRoutes: [],
        showNewOrganizationDialog: false,
    } as AppSliceProps,
    reducers: {
        toggleDrawerIsOpen: (state) => {
            state.drawerIsOpen = !state.drawerIsOpen;
        },
        toggleDrawer: (state, action) => {
            state.drawerIsOpen = action.payload;
        },
        setDrawerRoutes: (state, action) => {
            state.drawerRoutes = action.payload;
        },
        setShowNewOrganizationDialog: (state, action) => {
            state.showNewOrganizationDialog = action.payload;
        },
    },
});

export const {
    toggleDrawerIsOpen,
    toggleDrawer,
    setDrawerRoutes,
    setShowNewOrganizationDialog,
} = appReducer.actions;

export default appReducer.reducer;
