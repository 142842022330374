import { Login, Register, SessionData, SocialLogin } from "../../helpers";
import { Environment } from "../../configs";
import HttpClient from "../HttpService";

class AuthApi extends HttpClient {
  login({ email, password }: Login): Promise<SessionData> {
    return this.axios.post("/v2/users/auth", {
      email,
      password,
      type: "web",
    });
  }

  social(obj: SocialLogin): Promise<SessionData> {
    return this.axios.post("/v2/users/auth/social", obj);
  }

  register({ name, email, password }: Register): Promise<SessionData> {
    return this.axios.post("/v2/users", {
      email,
      password,
      name,
      location: null,
      type: "web",
    });
  }
}

export default new AuthApi();
