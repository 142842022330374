import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Halal } from "../../../defaults/Metadata";
import { RootState } from "../../../redux/store";
import { Dispatch } from "redux";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";

interface Props {
  description: string;
  authentication: string;
  offerings: string;
  updateSelected(key: string, value: string): void;
}

const HalalInformation = ({
  description,
  authentication,
  offerings,
  updateSelected,
}: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Halal Information"
        subtitle="Halal Assurance: Transparency in Dietary Information"
      />
      <TextField
        multiline
        label="Halal Description"
        minRows={3}
        value={description}
        onChange={(event) => {
          updateSelected("halal.description", event.target.value);
        }}
      />
      <Autocomplete
        fullWidth
        options={Halal.authentications}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option) => option._id === authentication}
        value={Halal.authentications.find((f) => f._id === authentication)}
        onChange={(event, value) =>
          updateSelected("halal.authentication", value?._id || "")
        }
        renderInput={(params) => (
          <TextField {...params} label="Authentication" fullWidth required />
        )}
      />
      <Autocomplete
        fullWidth
        options={Halal.offerings}
        isOptionEqualToValue={(option) => option._id === offerings}
        value={Halal.offerings.find((f) => f._id === offerings)}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) =>
          updateSelected("halal.offerings", value?._id || "")
        }
        renderInput={(params) => (
          <TextField {...params} label="Offerings" fullWidth required />
        )}
      />
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  description: data?.halal.description || "",
  authentication: data?.halal.authentication || "",
  offerings: data?.halal.offerings || "",
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelected: (key: string, value: string) =>
    dispatch(updateSelected({ key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(HalalInformation);
