import {
  Autocomplete,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Stack,
  Switch,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import UselessSectionHeader from "../../../components/useless/UselessSectionHeader";
import { ChangeEvent, useEffect, useState } from "react";
import {
  ChevronLeft,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { GlobalUserPayload, GroupedListings, UserInfo } from "../../../helpers";
import UsersApi from "../../../services/api/UsersApi";
import { useNavigate, useParams } from "react-router-dom";
import LocationApi from "../../../services/api/LocationApi";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import UselessEmptyHolder from "../../../components/useless/UselessEmptyHolder";
import { enqueueSnackbar } from "notistack";

const GlobalManageUserPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [locations, setLocations] = useState<string[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [state, setState] = useState<GlobalUserPayload>({
    name: "",
    email: "",
    superadmin: false,
    address: "",
    owner: [],
    password: "",
  });

  useEffect(() => {
    const fetch = async () => {
      if (!userId) return;
      const _userInfo = await UsersApi.getUserById(userId);
      setUserInfo(_userInfo);
      setState({
        name: _userInfo.name,
        email: _userInfo.email,
        address: _userInfo.location?.formatted_address ?? "",
        superadmin: _userInfo.superadmin,
        owner: _userInfo.owner,
      });
    };

    fetch();
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.id]: event.target.value,
    });
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length === 0) {
      setLocations([]);

      return;
    }
    const locations = await LocationApi.getPossibleLocations(
      event.target.value
    );
    setLocations(locations);
  };

  const handleSearchDelayed = debounce(handleSearch, 500);

  const onUpdate = async () => {
    if (!userId) return;
    const payload: any = {
      name: state.name,
      email: state.email,
      address: state.address,
      superadmin: state.superadmin,
    };
    if (state.password && state.password.trim().length > 0) {
      payload.password = state.password;
    }

    try {
      await UsersApi.updateUserById(userId, payload);
      enqueueSnackbar({
        message: "User updated successfully",
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to update user",
        variant: "error",
      });
    }
  };

  return (
    <UselessPageCarded
      header={{
        title: "Manage User",
        subtitle: "Update the user information",
        left: (
          <Stack justifyContent="center">
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeft />
            </IconButton>
          </Stack>
        ),
        right: (
          <Stack justifyContent="center">
            <Button variant="contained" onClick={() => onUpdate()}>
              Update
            </Button>
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <UselessDefaultMotion type="item">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <UselessSectionHeader
              title="Basic Information"
              subtitle="This information is public"
            />
            <TextField
              label="Name"
              id="name"
              onChange={onChange}
              value={state.name}
            />
            <TextField
              label="Email"
              id="email"
              type="email"
              value={state.email}
              onChange={onChange}
            />
            <TextField
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              onChange={onChange}
              autoComplete="new-password"
              value={state.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Autocomplete
              fullWidth
              options={locations}
              value={state.address}
              disableClearable
              freeSolo
              onInputChange={(event, value) =>
                setState({
                  ...state,
                  address: value,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address"
                  fullWidth
                  onChange={handleSearchDelayed}
                />
              )}
            />
            <FormControlLabel
              control={
                <Switch
                  name="superadmin"
                  onChange={(event, checked) =>
                    setState({ ...state, superadmin: checked })
                  }
                  checked={state.superadmin}
                />
              }
              label="Super Admin"
            />
          </Stack>
          <Stack>
            <UselessSectionHeader
              title="Listings"
              subtitle="Manage listing management access"
            />
            <List dense>
              {state.owner?.map((owner: GroupedListings) => (
                <>
                  <ListSubheader
                    disableGutters
                    sx={{ backgroundColor: "inherit" }}
                  >
                    {owner.name}
                  </ListSubheader>
                  {owner.listings.map((listing) => (
                    <ListItem disableGutters key={listing.name}>
                      <ListItemText
                        primary={listing.name}
                        secondary={listing.location}
                      />
                      {/* <ListItemSecondaryAction>
                        <IconButton size="small">
                          <Close fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction> */}
                    </ListItem>
                  ))}
                  <Divider />
                </>
              ))}
            </List>
            {state.owner?.length === 0 && (
              <UselessEmptyHolder
                icon="ErrorOutline"
                text="No ownership found"
                description="This user does not manage any listings."
              />
            )}
          </Stack>
        </Stack>
      </UselessDefaultMotion>
    </UselessPageCarded>
  );
};

export default GlobalManageUserPage;
