import { useState } from "react";
import { DefaultUpdateTypes, Defaults } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import SelectDialog from "../dialogs/SelectDialog";
import DefaultSettingsLineItem from "./DefaultSettingsLineItem";
import FormDialog from "../dialogs/FormDialog";
import PercentageFormatTextField from "../core/PercentageFormatTextField";
import NumberFormatTextField from "../core/NumberFormatTextField";

interface Props {
  defaults: Defaults;
  onUpdate(type: DefaultUpdateTypes, value: string | number): void;
}

interface FormDialogProps {
  open: boolean;
  defaultValue: string;
  title: string;
  description: string;
  type: "tax" | "prep";
}

const DefaultSettings = ({ defaults, onUpdate }: Props) => {
  const [automaticOpen, setAutomaticOpen] = useState(false);
  const [formDialog, setFormDialog] = useState<FormDialogProps>({
    open: false,
    defaultValue: "",
    title: "",
    description: "",
    type: "tax",
  });

  const autocomplete =
    defaults.autocomplete === 0
      ? "Disabled"
      : `Every ${defaults.autocomplete / 60} Hours`;

  const tax = `${defaults.tax}%`;

  const prep = `${defaults.prep} Minutes`;

  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Default Settings"
        subtitle="Configure Tax, Move to Completed Option, and Set Preparation Time"
      />
      <DefaultSettingsLineItem
        primary="Enable Automatic Transition to Completed Status"
        secondary={autocomplete}
        onEdit={() => setAutomaticOpen(true)}
      />
      <DefaultSettingsLineItem
        primary="Define Default Tax Percentage"
        secondary={tax}
        onEdit={() => {
          setFormDialog({
            defaultValue: defaults.tax.toString(),
            title: "Default Tax Percentage",
            description:
              "Define the Default Tax Percentage Applied to Online Orders. Items without specified tax rates will be set at this percentage by default",
            open: true,
            type: "tax",
          });
        }}
      />
      <DefaultSettingsLineItem
        primary="Default Order Preparation Time (minutes)"
        secondary={prep}
        onEdit={() => {
          setFormDialog({
            defaultValue: defaults.prep.toString(),
            title: "Default Preparation Time",
            description:
              "Define Default Preparation Time. This field will automatically populate your tablet with the default value upon order receipt",
            open: true,
            type: "prep",
          });
        }}
      />
      <SelectDialog
        open={automaticOpen}
        handleClose={(value: number) => {
          if (value) {
            onUpdate("autocomplete", value);
          }
          setAutomaticOpen(false);
        }}
        title="Automatic Order Transition"
        description="Enabling this setting will automatically mark your accepted orders as complete after the designated time."
        defaultValue={defaults.autocomplete}
        selectLabel="Hours"
        options={[
          { text: "Disabled", value: 0 },
          { text: "2 Hours", value: 60 * 2 },
          { text: "4 Hours", value: 60 * 4 },
          { text: "8 Hours", value: 60 * 8 },
          { text: "12 Hours", value: 60 * 12 },
        ]}
        action={{
          acceptLabel: "Submit",
          rejectLabel: "Cancel",
        }}
      />
      <FormDialog
        handleClose={(value) => {
          if (value) onUpdate(formDialog.type, value);
          setFormDialog({ ...formDialog, open: false });
        }}
        title={formDialog.title}
        description={formDialog.description}
        textField={{
          label: formDialog.type === "tax" ? "Tax" : "Time (Minutes)",
          InputProps: {
            inputComponent:
              formDialog.type === "tax"
                ? PercentageFormatTextField
                : (NumberFormatTextField as any),
          },
        }}
        action={{ acceptLabel: "Submit", rejectLabel: "Cancel" }}
        defaultValue={formDialog.defaultValue}
        open={formDialog.open}
      />
    </UselessDefaultMotion>
  );
};

export default DefaultSettings;
