import { Box, Button, Stack, Typography } from "@mui/material";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Image } from "../../../helpers";
import { useState } from "react";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";
import { resizeFile } from "../../../utils/Core";
import { AddAPhoto, HideImage } from "@mui/icons-material";

interface Props {
  image?: Image;
  onUpdateImage(image: Image): void;
  onClearImage(): void;
}

const ImageSelector = ({ image, onUpdateImage, onClearImage }: Props) => {
  const handleFileUpload = async (event: any) => {
    const files = event.target.files;
    if (files.length > 0) {
      const f = await resizeFile(files[0]);
      const i: Image = {
        source: f,
        height: 0,
        width: 0,
        size: 4 * Math.ceil((f.length / 3) * 0.5624896334383812),
        new: true,
        deleted: false,
      };

      onUpdateImage(i);
    }
  };
  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Image"
        subtitle="Upload an image for this item"
        action={
          <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
            {image && (
              <Button
                variant="outlined"
                component="label"
                startIcon={<HideImage />}
                onClick={() => onClearImage()}
              >
                Clear Image
              </Button>
            )}
            {!image && (
              <Button
                variant="contained"
                component="label"
                startIcon={<AddAPhoto />}
              >
                <input
                  hidden
                  accept="image/jpeg"
                  type="file"
                  onChange={handleFileUpload}
                />
                Attach Image
              </Button>
            )}
          </Stack>
        }
      />
      {image && (
        <Box
          component="img"
          sx={{
            height: 150,
            width: "100%",
            objectFit: "cover",
            borderRadius: 1,
          }}
          src={image.source}
        />
      )}
      {!image && (
        <UselessEmptyHolder
          parentContainerStyles={{
            minHeight: 150,
            width: "100%",
            borderRadius: 1,
          }}
          icon="Image"
          text="Visual of dish unavailable"
          description="Elevate the dining experience with appetizing dish images for a tantalizing preview"
        />
      )}
      {/* {isHovering && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              height: "100$",
              width: "100%",
              zIndex: 1,
              backgroundColor: "rgba(255,255,255,.3)",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button variant="contained">Replace Image</Button>
          </Box>
        )} */}
      {/* </Box> */}
    </UselessDefaultMotion>
  );
};

export default ImageSelector;
