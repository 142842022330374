import {
  FormControl,
  FormControlLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";

interface Props {
  ccfeescovered: boolean;
  onChangeFeeCovered(checked: boolean): void;
}

const MerchantSettings = ({ ccfeescovered, onChangeFeeCovered }: Props) => {
  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Merchant Settings"
        subtitle="Tailored Configuration Settings Exclusively for Merchants"
      />
      <ListItem dense>
        <ListItemText
          primary="Credit Card Fees"
          secondary="Party Responsible for Credit Card Fees"
        />
        <ListItemSecondaryAction>
          <FormControlLabel
            control={
              <Switch
                checked={ccfeescovered}
                onChange={(event, checked) => onChangeFeeCovered(checked)}
              />
            }
            label={
              <Typography variant="body2" fontWeight="700">
                {ccfeescovered ? "Platform" : "Business"}
              </Typography>
            }
            labelPlacement="start"
          />
        </ListItemSecondaryAction>
      </ListItem>
    </UselessDefaultMotion>
  );
};

export default MerchantSettings;
