import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Payments } from "../../../defaults/Metadata";
import { RootState } from "../../../redux/store";
import { Dispatch } from "redux";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { connect } from "react-redux";

interface Props {
  payments: string[];
  updateSelected(value: string[]): void;
}

const PaymentMethods = ({ payments, updateSelected }: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Payment Methods"
        subtitle="Convenient Transactions: Your Preferred Payment Methods"
      />
      <Autocomplete
        fullWidth
        options={Payments.sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        })}
        getOptionLabel={(option) => option.name}
        value={Payments.filter((f) => payments.indexOf(f._id) !== -1)}
        disableCloseOnSelect
        multiple
        onChange={(event, value) => updateSelected(value.map((v) => v._id))}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  payments: data?.payments || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelected: (value: string[]) =>
    dispatch(updateSelected({ key: "payments", value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
