import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Fee, FeeType } from "../../../helpers";
import PercentageFormatTextField from "../core/PercentageFormatTextField";
import PriceFormatTextField from "../core/PriceFormatTextField";

interface Props {
  open: boolean;
  handleClose(fee?: Fee): void;
  fee?: Fee;
}

const defaultValue: Fee = {
  destination: "",
  fixed: true,
  order: "pickup",
  type: "customer",
  value: 0,
};

const MerchantFeeDialog = ({ open, handleClose, fee }: Props) => {
  const [value, setValue] = useState<Fee>(defaultValue);

  useEffect(() => {
    if (open && fee)
      setValue({
        ...fee,
        value: fee.fixed ? fee.value / 100 : fee.value,
      });
    else if (open) setValue(defaultValue);
  }, [open]);

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{fee ? "Manage" : "Create"} Fee</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Who will be responsible for covering this fee?
            </Typography>
            <Select
              labelId="fee-type-select-label"
              id="fee-type-select"
              value={value.type}
              label="Type"
              onChange={(event) =>
                setValue({ ...value, type: event.target.value as FeeType })
              }
            >
              <MenuItem value="customer">Customer</MenuItem>
              <MenuItem value="business">Business</MenuItem>
            </Select>
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              A Fixed Fee is a predetermined amount that is added to the order
              total. On the other hand, a Variable Fee is calculated based on
              the order subtotal. Which type of fee would you like to apply to
              your orders?
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={value.fixed}
                  onChange={(event, checked) =>
                    setValue({ ...value, fixed: checked })
                  }
                />
              }
              label={value.fixed ? "Fixed" : "Variable"}
            />
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              What is the fee {value.fixed ? "amount" : "percentage"}?
            </Typography>
            <TextField
              value={value.value}
              onChange={(event) =>
                setValue({ ...value, value: +event.target.value })
              }
              InputProps={{
                inputComponent: value.fixed
                  ? (PriceFormatTextField as any)
                  : (PercentageFormatTextField as any),
              }}
            />
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              The 'Destination' refers to a Stripe Connect ID, indicating the
              recipient of this fee. Whose Stripe Connect ID should be set as
              the 'Destination' for this fee?
            </Typography>
            <TextField
              value={value.destination}
              onChange={(event) =>
                setValue({ ...value, destination: event.target.value })
              }
              placeholder="Leave this field blank if there is no recipient for the fee"
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() =>
            handleClose({
              ...value,
              value: value.fixed ? value.value * 100 : value.value,
            })
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MerchantFeeDialog;
