import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { SelectOption } from "../../../helpers";
import { useState } from "react";

interface Props {
  open: boolean;
  handleClose(value?: string | number): void;
  title: string;
  description?: string;
  options: SelectOption[];
  defaultValue: string | number;
  selectLabel: string;
  action: {
    acceptLabel: string;
    rejectLabel: string;
  };
}

const SelectDialog = ({
  open,
  title,
  description,
  selectLabel,
  defaultValue,
  options,
  action,
  handleClose,
}: Props) => {
  const [value, setValue] = useState<string | number>(defaultValue);
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {description && <DialogContentText>{description}</DialogContentText>}
          <FormControl fullWidth>
            <InputLabel id="dialog-select-label">{selectLabel}</InputLabel>
            <Select
              labelId="dialog-select-label"
              id="dialog-select"
              value={value}
              label={selectLabel}
              onChange={(event) => setValue(event.target.value)}
            >
              {options.map((option) => (
                <MenuItem key={option.text} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>{action.rejectLabel}</Button>
        <Button onClick={() => handleClose(value)}>{action.acceptLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectDialog;
