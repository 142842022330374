import { Environment } from "../../configs";
import {
  ConfigurationTypes,
  Configurations,
  Hours,
  Period,
} from "../../helpers";
import {
  MerchantCompleteRequest,
  MerchantDashboardInfo,
  MerchantDetails,
} from "../../helpers/Merchants";
import {
  DefaultUpdatePayload,
  DefaultUpdateTypes,
  Notification,
  OrderSettings,
} from "../../helpers/Settings";
import HttpClient from "../HttpService";

interface GetOrders {
  listingId: string;
  page: number;
  limit: number;
}

class ConfigurationApi extends HttpClient {
  constructor() {
    super();
    this.axios.defaults.baseURL = Environment.merchants.baseUrl;
  }

  getMerchantDetailsByListingId(listingId: string): Promise<MerchantDetails> {
    return this.axios.get(`/v1/merchant/listings/${listingId}/details`);
  }

  getMerchantInfoByListingId(
    listingId: string
  ): Promise<MerchantDashboardInfo> {
    return this.axios.get(`/v2/merchant/listings/${listingId}/info`);
  }

  getOrderSettings(listingId: string): Promise<OrderSettings> {
    return this.axios.get(`/v1/manage/listings/${listingId}/merchant/settings`);
  }

  initializeMerchantSignup(
    listingId: string,
    userId: string
  ): Promise<{ state: string }> {
    return this.axios.post(
      `${Environment.merchants.baseUrl}/v2/merchant/listings/${listingId}/init`,
      { userId }
    );
  }

  finalizeMerchantSignup(listingId: string, obj: MerchantCompleteRequest) {
    return this.axios.post(
      `${Environment.merchants.baseUrl}/v2/merchant/listings/${listingId}/details`,
      obj
    );
  }

  createPayout(listingId: string) {
    return this.axios.post(
      `${Environment.merchants.baseUrl}/v2/merchant/listings/${listingId}/payouts`
    );
  }

  getOrderConfigurations(listingId: string): Promise<Configurations> {
    return this.axios.get(
      `/v2/manage/listings/${listingId}/merchant/configurations`
    );
  }

  updateOrderSettingsHours(
    listingId: string,
    hours: Period[]
  ): Promise<{ hours: Hours }> {
    return this.axios.put(
      `/v1/manage/listings/${listingId}/merchant/settings/hours`,
      { hours }
    );
  }

  updateOrderSettingsNotifications(
    listingId: string,
    notifications: Notification[]
  ): Promise<Notification[]> {
    return this.axios.put(
      `/v1/manage/listings/${listingId}/merchant/settings/notifications`,
      { notifications }
    );
  }

  updateOrderSettingsDefaults(
    listingId: string,
    type: DefaultUpdateTypes,
    payload: DefaultUpdatePayload
  ): Promise<{ hours: Hours }> {
    return this.axios.patch(
      `/v1/manage/listings/${listingId}/merchant/settings/defaults/${type}`,
      payload
    );
  }

  updateConfigurations(
    listingId: string,
    type: ConfigurationTypes,
    payload: any
  ): Promise<any> {
    return this.axios.patch(
      `/v2/manage/listings/${listingId}/merchant/configurations/${type}`,
      payload
    );
  }
}

export default new ConfigurationApi();
