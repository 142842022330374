import { IconButton, ListItem, ListItemText } from "@mui/material";
import { Fee, FeeType } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Add, EditNotifications } from "@mui/icons-material";
import MerchantFeeDialog from "./MerchantFeeDialog";
import { useMemo, useState } from "react";
import { cloneDeep, groupBy } from "lodash";
import MerchantFeeGrouped from "./MerchantFeeGrouped";

interface Props {
  fees: Fee[];
  onUpdate(fees: Fee[]): void;
}

interface DialogState {
  open: boolean;
  fee?: Fee;
}

const MerchantFees = ({ fees, onUpdate }: Props) => {
  const [dialog, setDialog] = useState<DialogState>({
    open: false,
    fee: undefined,
  });

  const groupedFees = useMemo(() => {
    const _fees = fees.map((f, i) => ({ ...f, originalIndex: i }));
    const grouped = groupBy(_fees, (f) => f.type);
    return grouped;
  }, [fees]);

  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Merchant Fees"
        subtitle="Fee Structure: Charges Applicable to Both Merchant and Customer"
        action={
          <IconButton onClick={() => setDialog({ fee: undefined, open: true })}>
            <Add />
          </IconButton>
        }
      />
      {Object.keys(groupedFees).map((group) => (
        <MerchantFeeGrouped
          key={group}
          type={group as FeeType}
          fees={groupedFees[group]}
          onEdit={(fee) => {
            setDialog({
              open: true,
              fee,
            });
          }}
          onDelete={(fee) => {
            const _fees = cloneDeep(fees).filter(
              (f, index) => fee.originalIndex !== index
            );
            onUpdate(_fees);
          }}
        />
      ))}
      {fees.length === 0 && (
        <UselessEmptyHolder
          parentContainerStyles={{ mt: 2 }}
          icon="AttachMoney"
          text="No Fee Schedule Located"
          description="Fees are essential for revenue generation, making them a necessary component."
        />
      )}
      <MerchantFeeDialog
        open={dialog.open}
        fee={dialog.fee}
        handleClose={(fee) => {
          const _fees = cloneDeep(fees);

          if (fee) {
            if (fee.originalIndex !== undefined && fee.originalIndex !== -1) {
              const { originalIndex, ..._fee } = fee;
              _fees[fee.originalIndex] = _fee;
            } else {
              _fees.push(fee);
            }
            onUpdate(_fees);
          }
          setDialog({ open: false, fee: undefined });
        }}
      />
    </UselessDefaultMotion>
  );
};

export default MerchantFees;
