import { Box, Card, CardContent, Typography } from "@mui/material";
import UselessDefaultMotion from "../../components/useless/UselessDefaultMotion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SignoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/auth/login");
    }, 2000);
  }, []);

  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <UselessDefaultMotion type="container">
        <Card
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            p: 6,
          }}
        >
          <UselessDefaultMotion type="item">
            <Box
              component="img"
              sx={{ width: 48, height: 48 }}
              src={require("../../assets/images/logo.png")}
            />
          </UselessDefaultMotion>
          <UselessDefaultMotion type="item">
            <Typography sx={{ fontWeight: 900, fontSize: 32, mt: 2 }}>
              You have signed out!
            </Typography>
          </UselessDefaultMotion>
        </Card>
      </UselessDefaultMotion>
    </Box>
  );
};

export default SignoutPage;
