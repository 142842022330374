import { Card, CardContent, Skeleton, Stack, Typography } from "@mui/material";
import UselessDefaultMotion from "./UselessDefaultMotion";

interface Props {
  topText: string;
  centerTopText: string;
  centerBottomText: string;
  centerTextColor?: string;
  bottomText: string;
  bottomTextBold: string;
  loading?: boolean;
}

const UselessInfoBox = (props: Props) => {
  return (
    <UselessDefaultMotion type="item">
      <Card sx={{ m: 0.5 }}>
        {props.loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: 48,
              pt: 1.5,
              pl: 2,
              pr: 2,
              fontWeight: "700",
              fontSize: 18,
            }}
          />
        ) : (
          <Typography
            sx={{
              height: 48,
              pt: 1.5,
              pl: 2,
              pr: 2,
              fontWeight: "700",
              fontSize: 18,
            }}
            variant="body1"
            color="text.secondary"
          >
            {props.topText}
          </Typography>
        )}
        {props.loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              minHeight: 96,
            }}
          />
        ) : (
          <Stack
            sx={{
              minHeight: 96,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 42,
                textAlign: "center",
                fontWeight: "800",
                lineHeight: "52px",
                textTransform: "capitalize",
              }}
              color={props.centerTextColor}
            >
              {props.centerTopText}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                textAlign: "center",
                fontWeight: "700",
                lineHeight: "24px",
              }}
              color={props.centerTextColor}
            >
              {props.centerBottomText}
            </Typography>
          </Stack>
        )}
        {props.loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: 56,
              pt: 1.5,
              pb: 2.5,
            }}
          />
        ) : (
          <Typography
            variant="body2"
            sx={{
              mt: 1.5,
              mb: 2.5,
              fontSize: 16,
              textAlign: "center",
              color: "text.secondary",
            }}
          >
            {props.bottomText}
            {props.bottomTextBold && <strong> {props.bottomTextBold}</strong>}
          </Typography>
        )}
      </Card>
    </UselessDefaultMotion>
  );
};

export default UselessInfoBox;
