import {
  GroupedBasicListings,
  Image,
  Listing,
  Results,
  SearchListing,
} from "../../helpers";
import HttpClient from "../HttpService";

// export interface ListingsAPIUsersLookup {
//   count: number;
//   letter: string;
//   data: OrgUserRole[];
// }

class ListingsAPI extends HttpClient {
  // create({
  //   userId,
  //   ...data
  // }: CreateOrganization): Promise<BasicListingInfo> {
  //   return this.axios.post(`/v1/users/${userId}/organizations`, data);
  // }
  switch(userId: string, listingId: string): Promise<void> {
    return this.axios.patch(`/v1/admin/${userId}/listings/${listingId}/switch`);
  }
  // users(orgId: string): Promise<ListingsAPIUsersLookup[]> {
  //   return this.axios.get(`/v1/organizations/${orgId}/users`);
  // }

  getListingById(listingId: string): Promise<Listing> {
    return this.axios.get(`/v1/admin/listings/${listingId}`);
  }

  updateListingById(
    userId: string,
    listingId: string,
    payload: any
  ): Promise<Listing> {
    return this.axios.put(`/v1/admin/${userId}/listings/${listingId}`, payload);
  }

  deleteImageFromListingById(
    userId: string,
    listingId: string,
    imageId: string
  ) {
    return this.axios.delete(
      `/v1/admin/${userId}/listings/${listingId}/images/${imageId}`
    );
  }

  addImageToListing(
    userId: string,
    listingId: string,
    image: Image,
    cover: boolean
  ): Promise<{ success: boolean; uploaded: string }> {
    return this.axios.patch(
      `/v1/admin/${userId}/listings/${listingId}/images`,
      {
        size: image.size,
        src: image.source.substring("data:image/jpeg;base64,".length),
        cover,
      }
    );
  }

  searchListing(
    payload: SearchListing = {
      limit: 25,
      sortBy: "name",
      sortDirection: 1,
      text: "",
      page: 1,
    }
  ): Promise<Results> {
    const { text, ...remainder } = payload;
    const params: any = remainder;
    if (text.length > 0) params.text = text;
    return this.axios.get(`/v1/admin/listings/search/pagination`, {
      params,
    });
  }

  searchListingByText(text: string): Promise<GroupedBasicListings[]> {
    return this.axios.get(`/v2/admin/listings/search/${text}`);
  }

  initiateClaim(userId: string, listingId: string) {
    return this.axios.post(
      `/v1/users/${userId}/listings/${listingId}/ownership`
    );
  }
}

export default new ListingsAPI();
