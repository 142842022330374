import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import NumberFormatTextField from "../core/NumberFormatTextField";
import styled from "@emotion/styled";
import { useState } from "react";

interface Props {
  single: boolean;
  min: number;
  max: number;
  minSelected: boolean;
  maxSelected: boolean;
  optionsLength: number;
  onChange(
    control: "min" | "max" | "single" | "minSelected" | "maxSelected",
    value: string | boolean
  ): void;
}

const ModifierRules = ({
  single,
  min,
  max,
  minSelected,
  maxSelected,
  optionsLength,
  onChange,
}: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Rules"
        subtitle="Establish Guidelines for this Modifier. Leaving all options unchecked will allow selection of all available choices"
      />
      <UselessDefaultMotion type="item">
        <Stack spacing={2}>
          <List>
            <ListItem dense>
              <Switch
                checked={single}
                disabled={minSelected || maxSelected}
                onChange={(event, checked) => {
                  onChange("single", checked);
                }}
              />
              <ListItemText
                sx={{ pr: { xs: 1, sm: 2 }, pl: { xs: 1 } }}
                primary="Do you want to allow customers to choose only one option?"
                secondary="Enabling this restricts the modifier to a single choice and can't be used with minimum/maximum selections."
              />
            </ListItem>
            <Divider />
            <ListItem dense>
              <Switch
                checked={minSelected}
                disabled={single}
                onChange={(event, checked) => {
                  onChange("minSelected", checked);
                }}
              />
              <ListItemText
                sx={{ pr: { xs: 1, sm: 2 }, pl: { xs: 1 } }}
                primary="What is the minimum number of selections required?"
                secondary="Enabling this prompts a minimum option selection. Ensure this minimum is below any set maximum and below number of options."
              />

              <ListItemSecondaryAction
                sx={{ visibility: minSelected ? "visible" : "hidden" }}
              >
                <TextField
                  sx={{ width: 48 }}
                  value={min}
                  disabled={single}
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  onChange={(event) => onChange("min", event.target.value)}
                  id="value"
                  placeholder="0"
                  error={
                    (minSelected && maxSelected && min > max) ||
                    min > optionsLength
                  }
                  InputProps={{
                    inputComponent: NumberFormatTextField as any,
                  }}
                  variant="standard"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense>
              <Switch
                checked={maxSelected}
                disabled={single}
                onChange={(event, checked) => {
                  onChange("maxSelected", checked);
                }}
              />
              <ListItemText
                sx={{ pr: { xs: 1, sm: 2 }, pl: { xs: 1 } }}
                primary="What is the maximum number of selections required?"
                secondary="Enabling this allows selections up to the specified number, which must exceed any applicable minimum or number of options."
              />
              <ListItemSecondaryAction
                sx={{ visibility: maxSelected ? "visible" : "hidden" }}
              >
                <TextField
                  sx={{ width: 48 }}
                  value={max}
                  disabled={single}
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  onChange={(event) => onChange("max", event.target.value)}
                  id="value"
                  placeholder="0"
                  error={
                    (maxSelected && minSelected && max < min) ||
                    max > optionsLength
                  }
                  InputProps={{
                    inputComponent: NumberFormatTextField as any,
                  }}
                  variant="standard"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Stack>
      </UselessDefaultMotion>
    </Stack>
  );
};

export default ModifierRules;
