import Moment from "moment";
import { Day } from "../helpers";

export const days: Day[] = [
  {
    name: "Sunday",
    day: 0,
    open: false,
    periods: [],
  },
  {
    name: "Monday",
    day: 1,
    open: false,
    periods: [],
  },
  {
    name: "Tuesday",
    day: 2,
    open: false,
    periods: [],
  },
  {
    name: "Wednesday",
    day: 3,
    open: false,
    periods: [],
  },
  {
    name: "Thursday",
    day: 4,
    open: false,
    periods: [],
  },
  {
    name: "Friday",
    day: 5,
    open: false,
    periods: [],
  },
  {
    name: "Saturday",
    day: 6,
    open: false,
    periods: [],
  },
];

export function generateTimeArrayOnlyTime() {
  const timeArray = [];

  for (let hours = 0; hours <= 23; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const timeMoment = Moment({ hours, minutes });
      const timeText = timeMoment.format("hh:mm A");

      timeArray.push(timeText);
    }
  }

  return timeArray;
}

export function splitHoursTextToArray(text: string[]) {
  const arr = [];
  for (let t of text) {
    const split = t.split(": ");
    const day = split[0];
    const hoursSplit = split[1].split(",");
    arr.push({
      day,
      hours: hoursSplit.map((hs) => hs.trim()),
    });
  }

  return arr;
}

export function convertTextTo12HourFormat(text: string) {
  const moment = Moment(text, "hhmm");
  return moment.format("hh:mm A");
}

export function isValid12HourFormat(text: string) {
  const pattern = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM|am|pm)$/;
  return pattern.test(text);
}
