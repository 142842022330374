import { Divider, Stack } from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import NoMerchant from "../../../components/admin/merchant/NoMerchant";

const MerchantSignUpPage = () => {
  return (
    <UselessPageCarded
      header={{
        title: "Merchant Sign Up",
        subtitle:
          "Register with NM to access a straightforward and efficient online ordering process",
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <NoMerchant />
      </Stack>
    </UselessPageCarded>
  );
};

export default MerchantSignUpPage;
