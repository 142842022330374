import { Box, Typography } from "@mui/material";

const OrContinueWith = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 4,
      }}
      className="flex items-center mt-32"
    >
      <Box
        sx={{
          display: "flex",
          flex: "auto",
          borderTop: 1,
          borderTopColor: "rgb(226, 232, 240)",
        }}
      />
      <Typography ml={1} mr={1} color="text.secondary">
        Or continue with
      </Typography>
      <Box
        sx={{
          display: "flex",
          flex: "auto",
          borderTop: 1,
          borderTopColor: "rgb(226, 232, 240)",
        }}
      />
    </Box>
  );
};

export default OrContinueWith;
