import {
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import { MoreHoriz, Refresh } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Order, OrderResponse } from "../../../helpers/Orders";
import OrderApi from "../../../services/api/OrderApi";
import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import { BasicListingInfo } from "../../../helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import UselessEmptyHolder from "../../../components/useless/UselessEmptyHolder";
import moment from "moment";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import OrderRow from "../../../components/admin/orders/OrderRow";

interface Props {
  current?: BasicListingInfo;
}

const OrdersPage = ({ current }: Props) => {
  const navigate = useNavigate();
  const [sp, setSP] = useSearchParams();

  const [page, setPage] = useState(sp.get("page") ? Number(sp.get("page")) : 0);
  const [limit, setLimit] = useState(
    sp.get("limit") ? Number(sp.get("limit")) : 10
  );
  const [orders, setOrders] = useState<OrderResponse>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSP(
      {
        page: newPage.toString(),
        limit: limit.toString(),
      },
      { replace: true }
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
    setSP(
      {
        page: "0",
        limit: event.target.value,
      },
      { replace: true }
    );
  };

  async function fetch() {
    if (!current) return;
    const _orders = await OrderApi.getOrdersData({
      listingId: current._id,
      limit,
      page: page + 1,
    });
    setOrders(_orders);
  }

  useEffect(() => {
    fetch();
  }, [limit, page]);

  return (
    <UselessPageCarded
      header={{
        title: "Orders",
        subtitle: "Comprehensive Overview of Your Orders",
        right: (
          <Stack justifyContent={"center"}>
            <IconButton onClick={fetch}>
              <Refresh />
            </IconButton>
          </Stack>
        ),
      }}
      containerStyles={{ pt: 0, pb: 0 }}
    >
      <UselessDefaultMotion type="item">
        <List disablePadding>
          {orders?.data.map((order) => (
            <OrderRow
              order={order}
              key={order._id}
              onClick={() => navigate(order._id)}
            />
          ))}
        </List>
        {orders?.data.length === 0 && (
          <UselessEmptyHolder
            parentContainerStyles={{ p: 4 }}
            icon="List"
            text="No orders found"
            description="Let's kickstart the process: Set up your account, craft a menu, and let's start receiving those orders!"
          />
        )}
      </UselessDefaultMotion>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={orders?.metadata.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapStateToProps)(OrdersPage);
