import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { id: string; value: string } }) => void;
  id: string;
}

const NumberFormatTextField = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumberFormatTextField(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              id: props.id,
              value: values.value,
            },
          });
        }}
        decimalScale={0}
        allowNegative={false}
        valueIsNumericString
      />
    );
  }
);
export default NumberFormatTextField;
