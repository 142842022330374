import { Check } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

interface Props {
  text: string;
  caption: string;
}

const NoMerchantLineItem = ({ text, caption }: Props) => {
  return (
    <Stack direction="row" spacing={1}>
      <Check
        sx={{
          backgroundColor: "#635bff",
          color: "#fff",
          borderRadius: 1,
          ml: 2,
          mt: 1,
        }}
        fontSize="small"
      />
      <Stack>
        <Typography variant="h6" fontWeight="700">
          {text}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {caption}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoMerchantLineItem;
