import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { ChangeEvent } from "react";
import { Facility } from "../../../defaults/Metadata";
import { Classes, Kinds } from "../../../helpers";
import { RootState } from "../../../redux/store";
import { DispatchProp, connect } from "react-redux";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { Dispatch } from "redux";

interface Props {
  kind: Kinds;
  name: string;
  description: string;
  denomination: string;
  category: string;
  classes: Classes;
  owner: string;
  updateSelected(key: string, value: string): void;
}

const BasicInformation = ({
  name,
  description,
  kind,
  denomination,
  owner,
  category,
  classes,
  updateSelected,
}: Props) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateSelected(event.target.id, event.target.value);
  };

  const onAutoCompleteChange = (key: string, value: string) => {
    updateSelected(key, value);
  };

  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Basic Information"
        subtitle="This information is public"
      />
      <UselessDefaultMotion type="item">
        <Stack spacing={2}>
          <TextField
            id="name"
            label="Name"
            fullWidth
            variant="standard"
            required
            value={name}
            onChange={onChange}
          />
          <TextField
            id="description"
            label="Description"
            fullWidth
            variant="standard"
            multiline
            minRows={3}
            value={description}
            onChange={onChange}
          />
          {["Schools", "Mosques"].indexOf(kind) !== -1 && (
            <Autocomplete
              fullWidth
              options={Facility.mosques.denominations.sort((a, b) => {
                if (a.name < b.name) return -1;
                return 1;
              })}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option) => option._id === denomination}
              value={Facility.mosques.denominations.find(
                (f) => f._id === denomination
              )}
              onChange={(event, value) =>
                onAutoCompleteChange("denomination", value?._id || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Religious Denomination"
                  fullWidth
                />
              )}
            />
          )}
          {["Businesses"].indexOf(kind) !== -1 && (
            <>
              <TextField
                id="owner"
                label="Owner's Name"
                fullWidth
                variant="standard"
                value={owner}
                onChange={onChange}
              />
              <Autocomplete
                fullWidth
                options={Facility.categories.sort((a, b) => {
                  if (a.name < b.name) return -1;
                  return 1;
                })}
                onChange={(event, value) =>
                  onAutoCompleteChange("category", value?._id || "")
                }
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) => option._id === category}
                value={Facility.categories.find((f) => f._id === category)}
                renderInput={(params) => (
                  <TextField {...params} label="Category" fullWidth />
                )}
              />
            </>
          )}
          {["Schools"].indexOf(kind) !== -1 && (
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <Autocomplete
                fullWidth
                options={Facility.schools.grades.sort((a, b) => {
                  if (a.order < b.order) return -1;
                  return 1;
                })}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) => option._id === classes.lowest}
                value={Facility.schools.grades.find(
                  (f) => f._id === classes.lowest
                )}
                onChange={(event, value) =>
                  onAutoCompleteChange("classes.lowest", value?._id || "")
                }
                renderInput={(params) => (
                  <TextField {...params} label="Lowest Grade Level" fullWidth />
                )}
              />
              <Autocomplete
                fullWidth
                options={Facility.schools.grades.sort((a, b) => {
                  if (a.order < b.order) return -1;
                  return 1;
                })}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) =>
                  option._id === classes.highest
                }
                value={Facility.schools.grades.find(
                  (f) => f._id === classes.highest
                )}
                onChange={(event, value) =>
                  onAutoCompleteChange("classes.highest", value?._id || "")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Highest Grade Level"
                    fullWidth
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
      </UselessDefaultMotion>
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  name: data?.name || "",
  description: data?.description || "",
  kind: data?.kind || "Foods",
  denomination: data?.denomination || "",
  category: data?.category || "",
  classes: data?.classes || {
    highest: "",
    lowest: "",
  },
  owner: data?.owner || "",
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelected: (key: string, value: string) =>
    dispatch(updateSelected({ key, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformation);
