import { Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
interface LinkTabProps {
  label?: string;
  href?: string;
}

const LinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        navigate(props.href ?? "", { replace: true });
        event.preventDefault();
      }}
      {...props}
    />
  );
};

export default LinkTab;
