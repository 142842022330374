import { MoreHoriz } from "@mui/icons-material";
import {
  Chip,
  IconButton,
  ListItemButton,
  ListItemButtonProps,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Order } from "../../../helpers/Orders";
import moment from "moment";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";

interface OrderRowProps {
  order: Order;
  listItemButtonProps?: ListItemButtonProps;
  onClick(): void;
}

const OrderRow = ({ order, onClick, listItemButtonProps }: OrderRowProps) => {
  return (
    <UselessDefaultMotion type="item">
      <ListItemButton
        dense
        onClick={onClick}
        sx={{ pt: 1, pb: 1 }}
        divider
        {...listItemButtonProps}
      >
        <ListItemText>
          <Typography variant="inherit" sx={{ fontWeight: "700" }}>
            {order.user.name}
          </Typography>
          <Stack>
            <Typography variant="inherit">
              ${(order.amount / 100).toFixed(2)}
            </Typography>
            <Typography variant="caption">
              Placed on: {moment(order.createdAt).format("lll")}
            </Typography>
            <Typography variant="caption">Ref: {order._id}</Typography>
          </Stack>
        </ListItemText>
        <ListItemSecondaryAction>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Chip label={order.tracking.text} />
            {/* <IconButton>
            <MoreHoriz />
          </IconButton> */}
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>
    </UselessDefaultMotion>
  );
};

export default OrderRow;
