import { Outlet, useNavigate } from "react-router-dom";
import { MerchantDetails } from "../../../helpers/Merchants";
import { useEffect, useState } from "react";
import { BasicListingInfo } from "../../../helpers";
import MerchantApi from "../../../services/api/MerchantApi";
import { RootState } from "../../../redux/store";
import { connect } from "react-redux";

interface Props {
  current?: BasicListingInfo;
}

const MerchantPage = ({ current }: Props) => {
  const [details, setDetails] = useState<MerchantDetails>();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      if (!current) return;
      try {
        const _details: any = await MerchantApi.getMerchantDetailsByListingId(
          current._id
        );
        if (_details.isBoom) throw new Error("No listing id found");
        setDetails(_details);
        navigate("dashboard", { replace: true });
      } catch (e) {
        navigate("sign-up", { replace: true });
      }
    }

    fetch();
  }, []);

  return <Outlet />;
};

const mapsStateToProps = ({ listings: { current } }: RootState) => ({
  current,
});

export default connect(mapsStateToProps)(MerchantPage);
