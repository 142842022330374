import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import React, { ChangeEvent, useEffect, useState } from "react";
import ListingsApi from "../../../services/api/ListingsApi";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  BasicListingInfo,
  Listing,
  Results,
  SearchListing,
  Session,
} from "../../../helpers";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
  Search,
} from "@mui/icons-material";
import moment from "moment";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import { Dispatch } from "redux";
import { setCurrentListing } from "../../../redux/reducers/ListingsReducer";

interface RowProps {
  listing: Listing;
  onLogin(): void;
}

const ListingRow = ({ listing, onLogin }: RowProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const options = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <ListItem dense>
        <ListItemAvatar>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </ListItemAvatar>
        <ListItemText
          primary={listing.name}
          secondary={listing.location.formatted_address || "Not provided"}
        />
        <ListItemSecondaryAction>
          <Stack spacing={1} direction="row">
            <Chip sx={{ textTransform: "capitalize" }} label={listing.kind} />
            <Chip sx={{ textTransform: "capitalize" }} label={listing.status} />

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
            >
              <MoreHoriz />
            </IconButton>
          </Stack>
        </ListItemSecondaryAction>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          open={options}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "options-button",
          }}
        >
          <MenuItem
            onClick={() => {
              onLogin();
              handleClose();
            }}
          >
            Login
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>Put In Review</MenuItem>
          <MenuItem onClick={handleClose}>Delete</MenuItem>
        </Menu>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack spacing={1} m={1}>
          <Typography variant="h6" gutterBottom component="div">
            Basic Information
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <ListItem dense>
                <ListItemText
                  primary="Description"
                  secondary={listing.description || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "normal",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Phone"
                  secondary={listing.contact.phone}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Website"
                  secondary={listing.contact.website || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Email"
                  secondary={listing.contact.email || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="WhatsApp"
                  secondary={listing.contact.whatsapp || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Instagram"
                  secondary={listing.contact.instagram || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Twitter"
                  secondary={listing.contact.twitter || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Facebook"
                  secondary={listing.contact.facebook || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="YouTube"
                  secondary={listing.contact.youtube || "Not provided"}
                  secondaryTypographyProps={{
                    sx: {
                      wordWrap: "break-word",
                    },
                  }}
                />
              </ListItem>
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom component="div">
            Metadata
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Updated"
                  secondary={moment(listing.updatedAt).format("llll")}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ListItem dense>
                <ListItemText
                  primary="Created"
                  secondary={moment(listing.createdAt).format("llll")}
                />
              </ListItem>
            </Grid>
          </Grid>
        </Stack>
      </Collapse>
    </React.Fragment>
  );
};

interface Props {
  onLogin(listing: BasicListingInfo): void;
}

const GlobalListingsPage = ({ onLogin }: Props) => {
  const [limit, setLimit] = useState(50);
  const [text, setText] = useState("");
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("name-asc");

  const [listings, setListings] = useState<Results>();

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSearchDelayed = debounce(handleSearch, 500);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetch = async () => {
      const sortSplit = sort.split("-");

      const listings = await ListingsApi.searchListing({
        limit,
        sortBy: sortSplit[0],
        sortDirection: sortSplit[1] === "asc" ? 1 : -1,
        text,
        page: page + 1,
      });

      setListings(listings);
    };

    fetch();
  }, [limit, text, page, sort]);

  return (
    <UselessPageCarded
      header={{
        title: "Listings",
        subtitle: "Manage Global Listing",
        right: (
          <Stack>
            <TextField
              onChange={handleSearchDelayed}
              placeholder="Type name..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              label="Search"
            />
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <Card elevation={0} sx={{ backgroundColor: "transparent" }}>
        <CardHeader
          title="Manage Listings"
          subheader="All listings for Neighborhood Muslim"
          action={
            <Stack m={1}>
              <FormControl fullWidth>
                <Select
                  labelId="sort-label"
                  id="sort-select"
                  label="Sort"
                  value={sort}
                  variant="standard"
                  onChange={(event) => {
                    setSort(event.target.value as string);
                  }}
                >
                  <MenuItem value="name-asc">Name Asc</MenuItem>
                  <MenuItem value="name-desc">Name Desc</MenuItem>
                  <MenuItem value="createdAt-asc">Created Asc</MenuItem>
                  <MenuItem value="createdAt-desc">Created Desc</MenuItem>
                  <MenuItem value="status-asc">Status Asc</MenuItem>
                  <MenuItem value="status-desc">Status Desc</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          }
        />
        <CardContent>
          <UselessDefaultMotion type="item">
            <List>
              {listings?.results.map((listing: Listing) => (
                <ListingRow
                  key={listing._id}
                  listing={listing}
                  onLogin={() => {
                    onLogin({
                      _id: listing._id,
                      kind: listing.kind,
                      location: listing.location.formatted_address,
                      name: listing.name,
                    });
                  }}
                />
              ))}
            </List>
          </UselessDefaultMotion>
        </CardContent>
      </Card>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={listings?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </UselessPageCarded>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (listing: BasicListingInfo) => dispatch(setCurrentListing(listing)),
});

export default connect(null, mapDispatchToProps)(GlobalListingsPage);
