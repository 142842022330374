import { IconButton, Stack } from "@mui/material";
import { Notification } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Edit, EditNotifications } from "@mui/icons-material";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";
import InformationLine from "../core/InformationLine";
import NotificationLineItem from "./NotificationLineItem";
import { useState } from "react";
import NotificationDialogs from "../dialogs/NotificationsDialog";
import NotificationsDialog from "../dialogs/NotificationsDialog";

interface Props {
  notifications: Notification[];
  onUpdate(notifications: Notification[]): void;
}

const OrderNotifications = ({ notifications, onUpdate }: Props) => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Notifications"
        subtitle="Configure Order Notification Preferences"
        action={
          <IconButton onClick={() => setNotificationsOpen(true)}>
            <EditNotifications />
          </IconButton>
        }
      />
      {notifications.map((notification, index) => (
        <NotificationLineItem
          key={`notification-${index}`}
          notification={notification}
        />
      ))}
      {notifications.length === 0 && (
        <UselessEmptyHolder
          icon="NotificationsOff"
          text="Notifications Not Found"
          description="Configure Notification Preferences for Orders Placed Outside Your Tablet"
        />
      )}
      <NotificationsDialog
        open={notificationsOpen}
        notifications={notifications}
        handleClose={(_notifications) => {
          if (_notifications) onUpdate(_notifications);
          setNotificationsOpen(false);
        }}
      />
    </UselessDefaultMotion>
  );
};

export default OrderNotifications;
