import { Button, Divider, Stack } from "@mui/material";
import BasicInformation from "../../../components/admin/information/BasicInformation";
import LocationContact from "../../../components/admin/information/LocationContact";
import ListingHours from "../../../components/admin/information/ListingHours";
import HalalInformation from "../../../components/admin/information/HalalInformation";
import FacilityDetails from "../../../components/admin/information/FacilityDetails";
import PaymentMethods from "../../../components/admin/information/PaymentMethods";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";
import Photos from "../../../components/admin/information/Photos";
import { useEffect, useState } from "react";
import {
  BasicListingInfo,
  Contact,
  Hours,
  Image,
  Kinds,
  Listing,
  ReduxSelectedListing,
} from "../../../helpers";
import ListingsApi from "../../../services/api/ListingsApi";
import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import FacilityServices from "../../../components/admin/information/FacilityServices";
import isEqual from "lodash/isEqual";
import { Dispatch } from "redux";
import {
  resetSelected,
  updateSelected,
} from "../../../redux/reducers/ListingsReducer";
import InfoPageActions from "../../../components/admin/information/InfoPageActions";

interface Props {
  current?: BasicListingInfo;
  selected: ReduxSelectedListing;
  resetSelected(): void;
}

const InformationPage = ({ current, selected, resetSelected }: Props) => {
  if (!selected.data) return <></>;

  return (
    <UselessPageCarded
      header={{
        title: "Information",
        subtitle: "Relevant details about your listing",
        right: <InfoPageActions />,
      }}
      containerStyles={{ p: 2 }}
    >
      <Stack spacing={2} divider={<Divider />}>
        <BasicInformation />
        <Photos />
        <LocationContact />
        {["Foods", "Markets", "Businesses"].indexOf(selected.data.kind) !==
          -1 && <ListingHours />}
        {["Foods", "Markets"].indexOf(selected.data.kind) !== -1 && (
          <HalalInformation />
        )}
        {["Foods"].indexOf(selected.data.kind) !== -1 && <FacilityDetails />}
        {["Mosques"].indexOf(selected.data.kind) !== -1 && <FacilityServices />}
        {["Foods", "Markets"].indexOf(selected.data.kind) !== -1 && (
          <PaymentMethods />
        )}
      </Stack>
    </UselessPageCarded>
  );
};

const mapStateToProps = ({ listings: { current, selected } }: RootState) => ({
  current,
  selected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetSelected: () => dispatch(resetSelected()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationPage);
