import { UserInfo, UserResults } from "../../helpers";
import HttpClient from "../HttpService";

class UsersApi extends HttpClient {
  searchGlobalUsers({
    limit,
    page,
    text,
  }: {
    limit: number;
    text?: string;
    page: number;
  }): Promise<UserResults> {
    const params: any = {
      limit,
      page,
    };
    if (text) {
      params.text = text;
    }
    return this.axios.get(`/v1/admin/users/search`, { params });
  }

  getUserById(userId: string): Promise<UserInfo> {
    return this.axios.get(`/v1/admin/users/${userId}`);
  }

  updateUserById(userId: string, payload: any) {
    return this.axios.put(`/v1/admin/users/${userId}`, payload);
  }
}

export default new UsersApi();
