import { Edit } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";

interface Props {
  primary: string;
  secondary: string;
  onEdit(): void;
}
const DefaultSettingsLineItem = ({ primary, secondary, onEdit }: Props) => {
  return (
    <ListItem disableGutters dense>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
        <IconButton onClick={onEdit}>
          <Edit fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default DefaultSettingsLineItem;
