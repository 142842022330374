import { Button, IconButton } from "@mui/material";
import { splitHoursTextToArray } from "../../../defaults";
import { Hours } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import OnlineOrderingHoursListItem from "./OnlineOrderingHoursListItem";
import { AccessTime, Edit, Update } from "@mui/icons-material";
import HoursDialog from "../hours/HoursDialog";
import { useState } from "react";

interface Props {
  hours: Hours;
  onUpdate(hours: Hours): void;
}

const OrderingHours = ({ hours, onUpdate }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <UselessDefaultMotion type="item">
      <UselessSectionHeader
        title="Business Hours for Online Ordering"
        subtitle="These hours are separate from your opening and closing times"
        action={
          <IconButton onClick={() => setOpen(true)}>
            <Update fontSize="small" />
          </IconButton>
        }
      />
      {splitHoursTextToArray(hours.text).map((row, index) => (
        <OnlineOrderingHoursListItem
          key={`${row.day}-${index}`}
          day={row.day}
          hours={row.hours}
        />
      ))}
      <HoursDialog
        hours={hours}
        open={open}
        onClose={() => setOpen(false)}
        onUpdate={(_hours) => {
          setOpen(false);
          onUpdate(_hours);
        }}
      />
    </UselessDefaultMotion>
  );
};

export default OrderingHours;
