import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import { ModifierOption } from "../../../helpers";
import UselessEmptyHolder from "../../useless/UselessEmptyHolder";
import { Add, Close, DragHandle as DragHandleIcon } from "@mui/icons-material";
import PriceFormatTextField from "../core/PriceFormatTextField";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <IconButton disableRipple>
    <DragHandleIcon />
  </IconButton>
));

interface SortableItemProps {
  option: ModifierOption;
  onOptionValueChange(property: "name" | "price", value: string): void;
  onDelete(): void;
}

const SortableItem = SortableElement<SortableItemProps>(
  ({ option, onDelete, onOptionValueChange }: SortableItemProps) => (
    <ListItem disableGutters divider style={{ listStyle: "none" }}>
      <TextField
        sx={{ width: "100%" }}
        placeholder="Name"
        value={option.name}
        InputProps={{ disableUnderline: true }}
        onChange={(event) => onOptionValueChange("name", event.target.value)}
      />
      <TextField
        sx={{ width: "100%" }}
        placeholder="$0.00"
        value={option.price}
        InputProps={{
          disableUnderline: true,
          inputComponent: PriceFormatTextField as any,
        }}
        onChange={(event) => onOptionValueChange("price", event.target.value)}
      />
      <DragHandle />
      <IconButton size="small" onClick={onDelete}>
        <Close fontSize="small" />
      </IconButton>
    </ListItem>
  )
);

interface SortableOptionsListProps {
  options: ModifierOption[];
  onDelete(option: ModifierOption): void;
  onOptionValueChange(
    index: number,
    property: "name" | "price",
    value: string
  ): void;
}

const SortableOptions = SortableContainer<SortableOptionsListProps>(
  ({ options, onDelete, onOptionValueChange }: SortableOptionsListProps) => {
    return (
      <List>
        {options.map((option, index) => (
          <SortableItem
            index={index}
            key={`option-${index}`}
            option={option}
            onDelete={() => onDelete(option)}
            onOptionValueChange={(property, value) =>
              onOptionValueChange(index, property, value)
            }
          />
        ))}
      </List>
    );
  }
);

interface Props {
  options: ModifierOption[];
  onSorted(options: ModifierOption[]): void;
  onAddOption(): void;
  onDelete(option: ModifierOption): void;
  onOptionValueChange(
    index: number,
    property: "name" | "price",
    value: string
  ): void;
}

const ModifierOptions = ({
  options,
  onSorted,
  onAddOption,
  onDelete,
  onOptionValueChange,
}: Props) => {
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const arr = arrayMove(options, oldIndex, newIndex);
    onSorted(arr);
  };

  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Options"
        subtitle="List all available options for this modifier with prices"
        action={
          <Stack>
            <Button variant="contained" onClick={onAddOption}>
              Add Option
            </Button>
          </Stack>
        }
      />
      <UselessDefaultMotion type="item">
        <SortableOptions
          options={options}
          onDelete={onDelete}
          onSortEnd={onSortEnd}
          useDragHandle
          onOptionValueChange={onOptionValueChange}
        />
        {options.length === 0 && (
          <UselessEmptyHolder
            icon="List"
            text="No Available Options"
            description="Include options for this modifier, along with their respective prices"
          />
        )}
      </UselessDefaultMotion>
    </Stack>
  );
};

export default ModifierOptions;
