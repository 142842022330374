import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TablePagination,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreHoriz,
  PersonAdd,
  Search,
} from "@mui/icons-material";
import { UserResults, UserInfo } from "../../../helpers";
import UsersApi from "../../../services/api/UsersApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import UselessDefaultMotion from "../../../components/useless/UselessDefaultMotion";

interface RowProps {
  user: UserInfo;
  onEdit(): void;
}

const UserRow = ({ user, onEdit }: RowProps) => {
  const city = user.location?.address_components?.city;
  const state = user.location?.address_components?.state;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let location = "Unknown Location";
  if (city && state) {
    location = `${city.long_name}, ${state.short_name}`;
  }

  return (
    <React.Fragment>
      <ListItem dense>
        <ListItemText
          primary={user.name}
          secondary={
            <Stack>
              <Typography variant="inherit">
                {user.email || "Not provided"}
              </Typography>
              <Typography variant="inherit">{location}</Typography>
            </Stack>
          }
        />
        <ListItemSecondaryAction>
          <Stack spacing={1} direction="row">
            <Chip label={moment(user.updatedAt).format("lll")} />

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
              aria-controls={open ? "user-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreHoriz />
            </IconButton>
          </Stack>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Reset Password</MenuItem> */}
        <Divider />
        <MenuItem onClick={handleClose}>Delete User</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const GlobalUsersPage = () => {
  const [limit, setLimit] = useState(25);
  const [text, setText] = useState("");
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState<UserResults>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const response = await UsersApi.searchGlobalUsers({
        limit,
        text,
        page: page + 1,
      });

      setUsers(response);
    };

    fetch();
  }, [limit, text, page]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSearchDelayed = debounce(handleSearch, 500);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <UselessPageCarded
      header={{
        title: "Users",
        subtitle: "Manage Global Users",
        right: (
          <Stack>
            <TextField
              onChange={handleSearchDelayed}
              placeholder="Type search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              label="Search"
            />
          </Stack>
        ),
      }}
      containerStyles={{ p: 2 }}
    >
      <Card elevation={0} sx={{ backgroundColor: "transparent" }}>
        <CardHeader
          title="Manage Users"
          subheader="All users for Neighborhood Muslim"
          action={
            <Button
              startIcon={<PersonAdd />}
              variant="contained"
              onClick={() => navigate("manage")}
            >
              Add User
            </Button>
          }
        />
        <CardContent>
          <UselessDefaultMotion type="item">
            <List>
              {users?.results.map((user) => (
                <UserRow
                  key={user._id}
                  user={user}
                  onEdit={() => navigate(`manage/${user._id}`)}
                />
              ))}
            </List>
          </UselessDefaultMotion>
        </CardContent>
      </Card>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </UselessPageCarded>
  );
};

export default GlobalUsersPage;
