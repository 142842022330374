import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { Facility } from "../../../defaults/Metadata";
import { RootState } from "../../../redux/store";
import { updateSelected } from "../../../redux/reducers/ListingsReducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";

interface Props {
  details: string[];
  updateSelected(value: string[]): void;
}

const FacilityDetails = ({ details, updateSelected }: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader
        title="Facility Details"
        subtitle="Select all that applies"
      />

      <Autocomplete
        fullWidth
        options={Facility.details.sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        })}
        getOptionLabel={(option) => option.name}
        value={Facility.details.filter((f) => details.indexOf(f._id) !== -1)}
        disableCloseOnSelect
        multiple
        onChange={(event, value) => updateSelected(value.map((v) => v._id))}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </Stack>
  );
};

const mapStateToProps = ({
  listings: {
    selected: { data },
  },
}: RootState) => ({
  details: data?.details || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSelected: (value: string[]) =>
    dispatch(updateSelected({ key: "details", value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityDetails);
