import {
  Autocomplete,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";
import { RestaurantDataTypes } from "../../../helpers";
import UselessDefaultMotion from "../../useless/UselessDefaultMotion";
import UselessSectionHeader from "../../useless/UselessSectionHeader";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

interface Props {
  title: string;
  subtitle: string;
  label: string;
  options: RestaurantDataTypes;
  selected: RestaurantDataTypes;
  onSelectedUpdate(value: RestaurantDataTypes): void;
}

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const SingleAutoComplete = ({
  title,
  subtitle,
  options,
  selected,
  label,
  onSelectedUpdate,
}: Props) => {
  return (
    <Stack spacing={1}>
      <UselessSectionHeader title={title} subtitle={subtitle} />
      <UselessDefaultMotion type="item">
        <Stack spacing={2}>
          <Autocomplete
            multiple
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={selected}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Stack>
                  <Typography variant="inherit">{option.name}</Typography>
                </Stack>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={label}
                placeholder="Select all that apply..."
              />
            )}
            onChange={(event, value) => {
              onSelectedUpdate(value as RestaurantDataTypes);
            }}
          />
        </Stack>
      </UselessDefaultMotion>
    </Stack>
  );
};

export default SingleAutoComplete;
