import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import UselessPageCarded from "../../../components/useless/UselessPageCarded";
import LinkTab from "../../../components/admin/core/LinkTab";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { DefaultRestaurantTabOptions } from "../../../defaults";

const RestaurantManagementPage = () => {
  const navigate = useNavigate();

  const currentTab = () => {
    let path = window.location.pathname;
    if (path.indexOf("/categories") !== -1) return 1;
    else if (path.indexOf("/items") !== -1) return 2;
    else if (path.indexOf("/modifiers") !== -1) return 3;

    return 0;
  };

  const [selectedTab, setSelectedTab] = useState(currentTab());

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <UselessPageCarded
      header={{
        title: "Restaurant Management",
        subtitle: "Manage all of the details for your restaurant",
        right: (
          <Stack justifyContent={"center"}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(DefaultRestaurantTabOptions[selectedTab].actionHref);
              }}
            >
              {DefaultRestaurantTabOptions[selectedTab].actionLabel}
            </Button>
          </Stack>
        ),
      }}
      containerStyles={{ pr: 2, pl: 2, pb: 0, pt: 0 }}
    >
      <Box sx={{ backgroundColor: "white", mr: -2, ml: -2 }}>
        <Tabs
          aria-label="nav tabs restaurant-manager"
          value={selectedTab}
          onChange={handleChange}
        >
          {DefaultRestaurantTabOptions.map((link) => (
            <LinkTab
              key={link.label + "-" + link.id}
              label={link.label}
              href={link.href}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </UselessPageCarded>
  );
};

export default RestaurantManagementPage;
