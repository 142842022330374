import {
  Chip,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Notification } from "../../../helpers";
import parsePhoneNumber from "libphonenumber-js";

interface Props {
  notification: Notification;
}

const NotificationLineItem = ({ notification }: Props) => {
  const value =
    notification.type === "email"
      ? notification.value
      : parsePhoneNumber(notification.value, "US")?.formatInternational();

  return (
    <ListItem dense>
      <ListItemText
        primary={notification.type}
        primaryTypographyProps={{ textTransform: "uppercase" }}
        secondary={value}
      />
      <ListItemSecondaryAction>
        <Chip label={notification.offline ? "Offline Only" : "Always On"} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default NotificationLineItem;
