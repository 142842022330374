import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import AuthHeader from "../../components/auth/AuthHeader";
import {
  BasicListingInfo,
  GroupedListings,
  Session,
  SessionData,
  SocialLogin,
} from "../../helpers";
import { setSessionData } from "../../redux/reducers/SessionReducer";
import AuthApi from "../../services/api/AuthApi";
import { connect } from "react-redux";
import OrContinueWith from "../../components/auth/OrContinueWith";
import { Action, Dispatch } from "redux";
import {
  setCurrentListing,
  setListings,
} from "../../redux/reducers/ListingsReducer";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";
import GoogleLogo from "../../assets/images/google.png";
import FacebookLogo from "../../assets/images/facebook.png";

interface Props {
  setSession: (session: Session) => Action;
  setListings: (listings: GroupedListings[]) => Action;
  setCurrentListing: (listing: BasicListingInfo) => Action;
}

const LoginPage = (props: Props) => {
  const { setSession, setCurrentListing, setListings } = props;
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onAfterSignIn = ({ owner, current, ...user }: SessionData) => {
    setSession(user);
    if (owner) {
      setListings(owner);
    }
    if (current) setCurrentListing(current);
    enqueueSnackbar("User logged In!", { variant: "success" });
    if (current) {
      navigate(`/admin/local/${current._id}`);
    } else {
      navigate("/admin");
    }
  };

  const onSubmit = async () => {
    try {
      const data = await AuthApi.login(form);
      onAfterSignIn(data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Incorrect username or password, Try again later.", {
        variant: "error",
      });
    }
  };

  return (
    <Stack
      sx={{
        height: "100%",
        ml: "auto",
        justifyContent: "center",
        width: {
          xs: "100%",
          lg: "60%",
        },
      }}
    >
      <AuthHeader
        title="Sign In"
        subtitle="Don't have an account?"
        link="/auth/register"
        linkText="Sign up"
      />
      <Stack direction="column" spacing={2} mt={2}>
        <TextField
          fullWidth
          name="email"
          label="Email"
          variant="outlined"
          value={form.email}
          onChange={onChange}
          sx={{ mt: 3, width: "100%" }}
        />
        <TextField
          fullWidth
          name="password"
          label="Password"
          variant="outlined"
          value={form.password}
          type="password"
          onChange={onChange}
          sx={{ mt: 3, width: "100%" }}
        />
        <Button
          variant="contained"
          size="large"
          color="secondary"
          fullWidth
          onClick={onSubmit}
          sx={{ borderRadius: 4 }}
        >
          Sign In
        </Button>
      </Stack>
      <OrContinueWith />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2 }}
        spacing={1}
      >
        <LoginSocialGoogle
          isOnlyGetToken
          client_id={process.env.REACT_APP_GG_APP_ID || ""}
          typeResponse="idToken"
          onResolve={async ({ provider, data }: IResolveParams) => {
            if (data?.credential) {
              const obj: SocialLogin = {
                provider: "google",
                token: data.credential,
                type: "web",
              };

              const login = await AuthApi.social(obj);
              onAfterSignIn(login);
            }
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <Button
            startIcon={
              <Box src={GoogleLogo} component="img" sx={{ width: 32 }} />
            }
            variant="outlined"
          >
            Login With Google
          </Button>
        </LoginSocialGoogle>
        {/* <LoginSocialFacebook
          isOnlyGetToken
          response_type="token"
          appId={process.env.REACT_APP_FB_APP_ID || ""}
          onResolve={({ provider, data }: IResolveParams) => {
            console.log(provider, data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <IconButton>
            <Box src={FacebookLogo} component="img" sx={{ width: 32 }} />
          </IconButton>
        </LoginSocialFacebook> */}
      </Stack>
    </Stack>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSession: (session: Session) => dispatch(setSessionData(session)),
  setListings: (listings: GroupedListings[]) => dispatch(setListings(listings)),
  setCurrentListing: (listing: BasicListingInfo) =>
    dispatch(setCurrentListing(listing)),
});

export default connect(null, mapDispatchToProps)(LoginPage);
