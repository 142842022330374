import { CssBaseline, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import AdminAppBar from "../components/admin/appbar/AdminAppBar";
import Main from "../components/admin/core/Main";
import AdminDrawer from "../components/admin/drawer/AdminDrawer";
import { BasicListingInfo, Session } from "../helpers";
import { RootState } from "../redux/store";
import useWindowSize from "../utils/WindowResize";

interface Props {
  open: boolean;
  session?: Session;
  current?: BasicListingInfo;
}

const AdminPage = ({ open, session, current }: Props) => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (session === undefined) {
      enqueueSnackbar("Your session has expired.", { variant: "error" });
      navigate("/signout", { replace: true });
    }
  }, [session]);

  return (
    <Stack sx={{ flexDirection: "row", display: "flex" }}>
      <CssBaseline />
      <AdminDrawer />
      <Main open={open} size={size}>
        <AdminAppBar />
        <Outlet />
      </Main>
    </Stack>
  );
};

const mapStateToProps = ({
  app: { drawerIsOpen },
  session: { data },
  listings: { current },
}: RootState) => ({
  open: drawerIsOpen,
  session: data,
  current,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
